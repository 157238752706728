import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Decimal, RepliconAiIcon } from '~/modules/common/components';
import ProgressItemLoading from './ProgressItemLoading';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  columnItems: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    display: 'inline-block',
    position: 'relative'
  },
  titleText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.subtitle1.fontFamily,
    color: theme.palette.text.secondary
  },
  titleIcon: {
    position: 'absolute',
    right: '100%',
    top: 0,
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(-0.25)
  },
  titleIconBackground: {
    backgroundColor: theme.palette.repliconAi.iconBackground,
    width: '1.5em',
    height: '1.5em'
  },
  keyValue: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  variation: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(0.8),
    paddingLeft: theme.spacing(0.8),
    borderRadius: theme.spacing(2)
  },
  variationNoRisk: {
    backgroundColor: theme.palette.projectRisk.score.noRisk,
    color: theme.palette.text.white
  },
  variationLittleRisk: {
    backgroundColor: theme.palette.projectRisk.score.littleRisk,
    color: theme.palette.text.secondary
  },
  variationSomeRisk: {
    backgroundColor: theme.palette.projectRisk.score.someRisk,
    color: theme.palette.text.secondary
  },
  variationHighRisk: {
    backgroundColor: theme.palette.projectRisk.score.highRisk,
    color: theme.palette.text.white
  },
  notAvailable: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled
  },
  titleTooltipMessage: {
    width: '247px'
  }
}));

const getRiskClass = (riskValue, classes) => {
  if (riskValue < 20) {
    return classes.variationNoRisk;
  }
  if (riskValue < 40) {
    return classes.variationLittleRisk;
  }
  if (riskValue < 60) {
    return classes.variationSomeRisk;
  }

  return classes.variationHighRisk;
};

const TitleTooltipMessage = props => {
  return (
    <div {...props}>
      <div>
        <FormattedMessage id="kpiCharts.projectRiskScoreInfo" />
      </div>
      <div>
        <FormattedMessage id="kpiCharts.projectRiskScoreInfoMore" />
      </div>
    </div>
  );
};

export const ProgressItemScore = ({
  title,
  value,
  isLoading = false,
  error
}) => {
  const classes = useStyles();

  return (
    <div className={classes.columnItems}>
      <div className={classes.title}>
        <Typography className={classes.titleText}>
          <FormattedMessage id={title} />
        </Typography>
        <div className={classes.titleIcon}>
          <RepliconAiIcon className={classes.titleIconBackground} />
        </div>
      </div>
      {isLoading || error ? (
        <div className={classNames(classes.keyValue, classes.notAvailable)}>
          {isLoading && <ProgressItemLoading />}
        </div>
      ) : value !== null ? (
        <span
          className={classNames(
            classes.rowItems,
            classes.keyValue,
            classes.variation,
            getRiskClass(value, classes)
          )}
        >
          <Tooltip
            title={
              <TitleTooltipMessage className={classes.titleTooltipMessage} />
            }
            PopperProps={{
              modifiers: {
                offset: { enabled: true, offset: '0, 48' }
              }
            }}
          >
            <span
              className={classNames(
                classes.variation,
                getRiskClass(value, classes)
              )}
            >
              <Decimal value={value} fixedDecimalScale={false} />
            </span>
          </Tooltip>
        </span>
      ) : (
        <div className={classNames(classes.keyValue, classes.notAvailable)}>
          <FormattedMessage id="dashboardProgressChart.na" />
        </div>
      )}
    </div>
  );
};

ProgressItemScore.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

export default ProgressItemScore;
