import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import DateField from '~/modules/common/components/DateField';
import EarnedRevenueDropdown from '~/modules/projects/project/EarnedRevenue/EarnedRevenueDropdown';
import { SearchableProjectDropdown } from '~/modules/common/components/ProjectDropdown/SearchableProjectDropdown';
import { useCodeAndNameRequired } from '~/modules/common/hooks/useCodeAndNameRequired';
import { useMeContext } from '~/modules/me/useMeContext';
import { ClientDropdownForProject } from '~/modules/projects/components/Common/ClientDropdownForProject';
import { ProgramDropdownForProject } from '~/modules/projects/components/Common/ProgramDropdownForProject';
import { RateCardCopyDropdown } from '~/modules/projects/project/common/components';
import { useProjectTemplateSettings } from '~/modules/common/hooks/project/useProjectTemplateSettings';
import { SearchableProjectManagerDropdown } from '~/modules/projects/project/common/components/SearchableProjectManagerDropdown';
import { useProjectManagerCanEditOnlyMyProjects } from '~/modules/projects/useProjectManagerCanEditOnlyMyProjects';
import { ProjectLinks } from '~/modules/projects/project/ProjectLinks';
import { PortfoliosDropdown } from '../PortfoliosDropDown';
import { COPY_PROJECT_FLOW } from './enums';
import { useFieldHandlers } from './hooks';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  legend: {
    padding: theme.spacing(0, 0, 1, 1)
  },
  checkBoxContainer: {
    display: 'flex',
    padding: theme.spacing(3, 0, 2, 0),
    flexDirection: 'column',
    marginTop: -theme.spacing(3)
  },
  error: {
    paddingBottom: theme.spacing(2)
  },
  billPlan: {
    display: 'flex'
  }
}));

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const CopyProjectForm = ({
  values,
  errors,
  screenFlow,
  canCopyTasks,
  handleChange,
  canEditClient,
  setFieldValue,
  canEditProgram,
  canEditPortfolio,
  projectTemplateSetting
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const {
    name,
    code,
    client,
    startDate,
    endDate,
    earnedRevenueScript,
    program,
    portfolio,
    projectManager,
    isCopyTasksChecked,
    isCopyResourceRequestsChecked,
    isCopyBillPlanChecked,
    canCopyBillPlan,
    rateCardCopyOption,
    project
  } = values;

  const me = useMeContext();
  const {
    featureFlags: { isPsaPrpPsaPpmMergerEnabled, isPsaPrpProjectLinkEnabled }
  } = me;

  const { hasBilling, hasPortfolio } = useProjectTemplateSettings({
    projectTemplateSetting,
    isPsaPrpPsaPpmMergerEnabled
  });

  const { isCodeRequired, isNameRequired } = useCodeAndNameRequired({
    codeNameAndUniqueness: me.projectNameAndCodeUniqueness
  });

  const requiredClientError = useMemo(() => errors.client, [errors.client]);

  const [isBillPlanEnabled, setIsBillPlanEnabled] = useState(hasBilling);
  const [isPortfolioEnabled, setIsPortfolioEnabled] = useState(hasPortfolio);

  const canViewBillPlan = isPsaPrpPsaPpmMergerEnabled
    ? isBillPlanEnabled && canCopyBillPlan
    : canCopyBillPlan;
  const canViewPortfolioDropdown = isPsaPrpPsaPpmMergerEnabled
    ? isPortfolioEnabled && canEditPortfolio
    : canEditPortfolio;

  const {
    handleStartDateChange,
    handleEndDateChange,
    handleProgramChange,
    handleEarnedRevenueScriptChange,
    handleClientChange,
    handleProjectManagerChange,
    handleProjectChange,
    handleCopyTasksChecked,
    handleCopyRateCardChange,
    handleCopyResourceRequestsChecked,
    handleCopyBillPlanChecked,
    handlePortfolioChange
  } = useFieldHandlers({
    setFieldValue,
    endDate,
    startDate,
    isPortfolioEnabled,
    setIsBillPlanEnabled,
    setIsPortfolioEnabled,
    isPsaPrpPsaPpmMergerEnabled
  });

  const copyTasksCheckBox = useMemo(
    () => (
      <Checkbox
        checked={!!isCopyTasksChecked}
        name="isCopyTasksChecked"
        onChange={handleCopyTasksChecked}
        value="primary"
      />
    ),
    [handleCopyTasksChecked, isCopyTasksChecked]
  );

  const copyResourceRequestsCheckBox = useMemo(
    () => (
      <Checkbox
        checked={!!isCopyResourceRequestsChecked}
        name="isCopyResourceRequestsChecked"
        onChange={handleCopyResourceRequestsChecked}
        value="primary"
      />
    ),
    [handleCopyResourceRequestsChecked, isCopyResourceRequestsChecked]
  );

  const copyBillPlanCheckBox = useMemo(
    () => (
      <Checkbox
        checked={!!isCopyBillPlanChecked}
        name="isCopyBillPlanChecked"
        onChange={handleCopyBillPlanChecked}
        value="primary"
      />
    ),
    [handleCopyBillPlanChecked, isCopyBillPlanChecked]
  );

  const unhandledError = errors.unhandled;
  const canEditOnlyMyProjects = useProjectManagerCanEditOnlyMyProjects();

  return (
    <>
      {unhandledError && (
        <Typography
          component="div"
          variant="caption"
          color="error"
          className={classes.error}
        >
          {unhandledError}
        </Typography>
      )}
      <div
        className={classes.checkBoxContainer}
        data-qe-id="SearchableProjectDropdown"
      >
        {screenFlow === COPY_PROJECT_FLOW.ADD_PROJECT && (
          <SearchableProjectDropdown
            includePermissions
            onChange={handleProjectChange}
            value={project}
            label={formatMessage({
              id: 'duplicateProject.projectToDuplicate'
            })}
            name="project"
          />
        )}
        <FormControlLabel
          control={copyResourceRequestsCheckBox}
          label={formatMessage({
            id: 'duplicateProject.copyResourceRequests'
          })}
          labelPlacement="end"
        />
        {canCopyTasks && (
          <FormControlLabel
            control={copyTasksCheckBox}
            label={formatMessage({ id: 'duplicateProject.copyTasks' })}
            labelPlacement="end"
          />
        )}

        {canViewBillPlan && (
          <div className={classes.billPlan}>
            <FormControlLabel
              control={copyBillPlanCheckBox}
              label={formatMessage({ id: 'duplicateProject.copyBillPlan' })}
              labelPlacement="end"
            />
            {isCopyBillPlanChecked && (
              <RateCardCopyDropdown
                fullWidth={false}
                noneOption={false}
                variant="filled"
                margin="dense"
                value={rateCardCopyOption}
                onChange={handleCopyRateCardChange}
                hiddenLabel
              />
            )}
          </div>
        )}
      </div>
      <Grid container spacing={2}>
        <Divider className={classes.divider} />
        <FormLabel component="legend" className={classes.legend}>
          {formatMessage({ id: 'duplicateProject.toThisProject' })}
        </FormLabel>
        <Grid container item xs={6} />
        <Grid item xs={6}>
          <TextField
            name="name"
            variant="filled"
            data-qe-id="AddProjectName"
            label={formatMessage({ id: 'duplicateProject.name' })}
            inputProps={getAriaLabel(
              formatMessage({ id: 'duplicateProject.name' })
            )}
            value={name}
            onChange={handleChange}
            autoFocus
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name}
            required={isNameRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="code"
            variant="filled"
            data-qe-id="AddProjectCode"
            label={formatMessage({ id: 'duplicateProject.code' })}
            inputProps={getAriaLabel(
              formatMessage({ id: 'duplicateProject.code' })
            )}
            value={code}
            onChange={handleChange}
            fullWidth
            error={Boolean(errors.code)}
            helperText={errors.code}
            required={isCodeRequired}
          />
        </Grid>
        <Grid item xs={6}>
          <DateField
            variant="filled"
            label={formatMessage({ id: 'duplicateProject.startDate' })}
            ariaLabel={formatMessage({ id: 'duplicateProject.startDate' })}
            name="startDate"
            value={startDate}
            onChange={handleStartDateChange}
            editable
          />
        </Grid>
        <Grid item xs={6}>
          <DateField
            variant="filled"
            label={formatMessage({ id: 'duplicateProject.endDate' })}
            ariaLabel={formatMessage({ id: 'duplicateProject.endDate' })}
            name="endDate"
            value={endDate}
            onChange={handleEndDateChange}
            editable
          />
        </Grid>
        {me.featureFlags?.isPsaEarnedRevenueEnabled && (
          <Grid item xs={12}>
            <EarnedRevenueDropdown
              name="earnedRevenueScript"
              onChange={handleEarnedRevenueScriptChange}
              value={earnedRevenueScript}
            />
          </Grid>
        )}
        {canViewPortfolioDropdown && (
          <Grid item xs={12}>
            <PortfoliosDropdown
              value={portfolio}
              onChange={handlePortfolioChange}
            />
          </Grid>
        )}
        {canEditClient && (
          <Grid item xs={12}>
            <ClientDropdownForProject
              variant="filled"
              dataQeId="AddProjectClientDropdown"
              value={client}
              onChange={handleClientChange}
              editable={canEditClient}
              name="client"
              showError={Boolean(requiredClientError)}
              errorMessage={requiredClientError}
            />
          </Grid>
        )}
        {canEditProgram && (
          <Grid item xs={12}>
            <ProgramDropdownForProject
              variant="filled"
              dataQeId="AddProjectProgramDropdown"
              name="program"
              onChange={handleProgramChange}
              value={program}
              editable={canEditProgram}
            />
          </Grid>
        )}
        {screenFlow === COPY_PROJECT_FLOW.ADD_PROJECT && (
          <Grid item xs={12}>
            <SearchableProjectManagerDropdown
              dataQeId="CopyProjectProjectManagerDropdown"
              value={projectManager}
              name="projectManager"
              onChange={handleProjectManagerChange}
              disabled={canEditOnlyMyProjects}
            />
          </Grid>
        )}
        {isPsaPrpProjectLinkEnabled && (
          <Grid item xs={12}>
            <ProjectLinks
              projectLinks={values.projectLinks}
              setFieldValue={setFieldValue}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

CopyProjectForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  project: PropTypes.object,
  screenFlow: PropTypes.string,
  canCopyTasks: PropTypes.bool,
  canEditClient: PropTypes.bool,
  handleChange: PropTypes.func,
  canEditProgram: PropTypes.bool,
  canEditPortfolio: PropTypes.bool,
  canCopyBillPlan: PropTypes.bool,
  setFieldValue: PropTypes.func,
  projectTemplateSetting: PropTypes.object
};

export default CopyProjectForm;
