import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Formik } from 'formik';
import { ChangeResourceDialogTitle } from './ChangeResourceDialogTitle';
import { useFormState } from './hooks';
import { ChangeResourceDialogBody } from './ChangeResourceDialogBody';

export const ChangeResourceDialog = ({
  existingResourceUser,
  onClose,
  open,
  projectId
}) => {
  const { initialValues } = useFormState();

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <ChangeResourceDialogTitle onClose={onClose} />
      <Formik initialValues={initialValues}>
        <ChangeResourceDialogBody
          existingResourceUser={existingResourceUser}
          projectId={projectId}
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  );
};

ChangeResourceDialog.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ChangeResourceDialog;
