import { Grid, Typography, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircleSharp';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  ProjectTeamMemberDropdown,
  RoleDropdown
} from '~/modules/tasks/components';
import EstimatedHoursEditor from './EstimatedHoursEditor';
import useTaskResourceEstimateChangeHandlers from './useTaskResourceEstimateChangeHandlers';

const useStyles = makeStyles(theme => ({
  cell: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  errorCell: {
    border: `1px solid ${theme.palette.error.main}`
  },
  removeIconCell: {
    color: theme.palette.text.secondary
  },
  estimatedHours: {
    fontWeight: theme.typography.fontWeightBold
  },
  estimated: {
    height: '100%'
  },
  enterDates: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
    height: '100%'
  },
  removeIcon: {
    color: theme.palette.text.secondary
  },
  container: {
    marginBottom: 0,
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
}));
const PAGE_SIZE = 200;

export const TaskResourceEstimateRow = ({
  resourceEstimate,
  onResourceEstimateChange,
  projectSlug,
  projectId,
  projectCurrency,
  selectedResources,
  error,
  styleProps: { gridItemStyle, removeColumnStyle },
  canViewCost
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const rolesQueryVariablesStorageKey = 'ACTIVE-ROLES-QUERY-VARIABLES';

  const {
    onRemove,
    onEstimatedHoursChange,
    onRoleChange,
    onResourceChange
  } = useTaskResourceEstimateChangeHandlers({
    onResourceEstimateChange,
    resourceEstimate,
    rolesQueryVariablesStorageKey
  });

  const onAddRoleDialogCancelButtonClick = useCallback(() => {
    onRoleChange(null);
  }, [onRoleChange]);

  return (
    <Grid key={resourceEstimate.rowId} container className={classes.container}>
      <Grid
        item
        style={gridItemStyle}
        className={error ? classes.errorCell : classes.cell}
      >
        <RoleDropdown
          value={resourceEstimate.role || null}
          onChange={onRoleChange}
          variant="standard"
          placeholder={formatMessage({
            id: 'taskResourceAssignments.selectARole'
          })}
          projectSlug={projectSlug}
          targetCurrencyId={projectCurrency?.id}
          pageSize={PAGE_SIZE}
          queryVariablesStorageKey={rolesQueryVariablesStorageKey}
          onAddRoleDialogCancelButtonClick={onAddRoleDialogCancelButtonClick}
        />
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Grid>
      <Grid item style={gridItemStyle} className={classes.cell}>
        <ProjectTeamMemberDropdown
          projectId={projectId}
          assignedRole={resourceEstimate.role}
          onResourceChange={onResourceChange}
          projectSlug={projectSlug}
          value={resourceEstimate.resource}
          selectedResources={selectedResources}
          placeholder={formatMessage({
            id: 'taskResourceAssignments.selectAResource'
          })}
          includeUserRate={canViewCost}
        />
      </Grid>
      <Grid item style={gridItemStyle} className={classes.cell}>
        <div className={classes.estimated}>
          <EstimatedHoursEditor
            onEstimatedHoursChange={onEstimatedHoursChange}
            resourceEstimate={resourceEstimate}
            canViewCost={canViewCost}
          />
        </div>
      </Grid>
      <Grid item className={classes.removeIconCell} style={removeColumnStyle}>
        <IconButton
          className={classes.removeIcon}
          aria-label={formatMessage({
            id: 'button.delete'
          })}
          onClick={onRemove}
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

TaskResourceEstimateRow.propTypes = {
  error: PropTypes.string,
  resourceEstimate: PropTypes.object.isRequired,
  onResourceEstimateChange: PropTypes.func,
  projectId: PropTypes.string,
  projectSlug: PropTypes.string,
  projectCurrency: PropTypes.object,
  selectedResources: PropTypes.array,
  styleProps: PropTypes.object,
  canViewCost: PropTypes.bool
};

export default TaskResourceEstimateRow;
