import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { useResourcingDragIndicators } from '~/modules/resourcing/hooks';
import { getAvailabilityPeriodsFromDates } from '../../../common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import {
  isoStringToObject,
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '../../../common/dates/convert';
import { hasOverlap, truncateRuleBoundaries } from '../util';
import { getScheduleRulesFromAllocationPeriods } from '../../../common/hooks/resourcing/scheduleUtil.merge';
import { getDragIndicatorsInChart } from './useResourceAllocationDragIndicators';

export const calculateExtendedTaskAllocationScheduleRules = ({
  startDate,
  endDate,
  taskAllocation: {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules
  },
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  isLeftExpanded,
  canExtendTaskAllocationBeyondProjectAllocation
}) => {
  const {
    availabilityPeriods: extendedAllocationPeriods
  } = getAvailabilityPeriodsFromDates({
    startDate: isoStringToObject(
      isLeftExpanded
        ? startDate.toISO()
        : mapIsoStringtoUtcObject(allocationEndDate)
            .plus({ days: 1 })
            .toISO()
    ),
    endDate: isoStringToObject(
      isLeftExpanded
        ? mapIsoStringtoUtcObject(allocationStartDate)
            .minus({ days: 1 })
            .toISO()
        : endDate.toISO()
    ),
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules:
      userTaskAllocationsSummaryScheduleRules || []
  });

  const extendedAllocationScheduleRules = getScheduleRulesFromAllocationPeriods(
    extendedAllocationPeriods
  ).filter(
    rule =>
      canExtendTaskAllocationBeyondProjectAllocation || rule.do.setHours > 0
  );

  return isLeftExpanded
    ? [...extendedAllocationScheduleRules, ...scheduleRules]
    : [...scheduleRules, ...extendedAllocationScheduleRules];
};

export const calculateCollapsedTaskAllocationScheduleRules = ({
  startDate,
  endDate,
  scheduleRules
}) => {
  const start = mapRepliconDateToUtcObject(startDate);
  const end = mapRepliconDateToUtcObject(endDate);

  return flow(
    filter(hasOverlap({ start, end })),

    map(truncateRuleBoundaries({ start, end }))
  )(scheduleRules);
};

export const getCollapsedPeriodDetails = ({
  dragStartDate,
  dragEndDate,
  allocationStartDateInUtc,
  allocationEndDateInUtc
}) => {
  const isLeftCollapsed = dragStartDate > allocationStartDateInUtc;

  return {
    periodStartDate: isLeftCollapsed
      ? allocationStartDateInUtc
      : dragEndDate.plus({ days: 1 }),
    periodEndDate: isLeftCollapsed
      ? dragStartDate.minus({ days: 1 })
      : allocationEndDateInUtc,
    newPeriodTaskAllocatedHours: 0
  };
};

export const getTaskAllocationModificationDetailsOnDrag = ({
  startDate: dragStartDate,
  endDate: dragEndDate,
  taskAllocation,
  resourceAllocationScheduleRules,
  userTaskAllocationsSummaryScheduleRules,
  canExtendTaskAllocationBeyondProjectAllocation
}) => {
  const {
    startDate: allocationStartDate,
    endDate: allocationEndDate,
    scheduleRules
  } = taskAllocation;

  const allocationStartDateInUtc = mapIsoStringtoUtcObject(allocationStartDate);

  const allocationEndDateInUtc = mapIsoStringtoUtcObject(allocationEndDate);

  const isLeftExpanded = dragStartDate < allocationStartDateInUtc;

  const isRightExpanded = dragEndDate > allocationEndDateInUtc;

  const isExpanded = isRightExpanded || isLeftExpanded;

  return {
    taskAllocationScheduleRules: isExpanded
      ? calculateExtendedTaskAllocationScheduleRules({
          startDate: dragStartDate,
          endDate: dragEndDate,
          taskAllocation,
          isLeftExpanded,
          resourceAllocationScheduleRules,
          userTaskAllocationsSummaryScheduleRules,
          useTaskAllocationDragIndicators,
          canExtendTaskAllocationBeyondProjectAllocation
        })
      : calculateCollapsedTaskAllocationScheduleRules({
          startDate: dragStartDate,
          endDate: dragEndDate,
          scheduleRules
        }),
    collapsedPeriodDetails: isExpanded
      ? null
      : getCollapsedPeriodDetails({
          dragStartDate,
          dragEndDate,
          allocationStartDateInUtc,
          allocationEndDateInUtc
        })
  };
};

export const taskAllocationUpdateHandler = onAllocationChange => ({
  entity: taskAllocation,
  estimatedDate
}) => {
  const { startDate, endDate } = estimatedDate;

  onAllocationChange({
    startDate,
    endDate,
    taskAllocation
  });
};

export const useTaskAllocationDragIndicators = ({
  taskAllocation,
  chartStartDate,
  scale,
  onAllocationChange,
  chartDisplayDateRange
}) => {
  const dragIndicatorsInChart = getDragIndicatorsInChart({
    chartDisplayDateRange,
    resourceAllocation: taskAllocation
  });

  const {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dates
  } = useResourcingDragIndicators({
    entity: taskAllocation,
    chartStartDate,
    scale,
    entityUpdateHandler: taskAllocationUpdateHandler(onAllocationChange)
  });

  return {
    gestureBindEvents,
    dragDelta,
    currentResizeDirection,
    dragIndicatorsInChart,
    dates
  };
};

export default useTaskAllocationDragIndicators;
