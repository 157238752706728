import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Card, CardContent, Divider } from '@material-ui/core';
import classNames from 'classnames';
import { ReadOnlyCardContentLoading } from '~/modules/common/components/DetailsPage/Card';
import ReadOnlyCardHeader from './ReadOnlyCardHeader';

export const ReadOnlyCard = ({
  className,
  classes = {},
  title,
  avatar,
  editable,
  onEdit,
  children,
  subheader,
  headerButtons,
  isLoading = false,
  additionalInfo,
  dividerVariant = 'fullWidth',
  ariaLabel,
  ariaAttributes = {}
}) => {
  const cardHeaderClass = useMemo(
    () => ({
      title: classNames(className, classes.title),
      avatar: classes.avatar,
      root: classes.headerRoot,
      content: classes.content,
      action: classes.action
    }),
    [
      className,
      classes.title,
      classes.avatar,
      classes.headerRoot,
      classes.content,
      classes.action
    ]
  );

  const cardContentClass = useMemo(
    () => ({ root: classes && classes.contentRoot }),
    [classes]
  );

  return (
    <Card>
      {title && (
        <>
          <ReadOnlyCardHeader
            classes={cardHeaderClass}
            avatar={avatar}
            title={title}
            onEdit={onEdit}
            editable={editable}
            subheader={subheader}
            headerButtons={headerButtons}
            additionalInfo={additionalInfo}
            ariaLabel={ariaLabel}
            ariaAttributes={ariaAttributes}
          />
          <Divider variant={dividerVariant} />
        </>
      )}
      <CardContent classes={cardContentClass}>
        {isLoading ? (
          <ReadOnlyCardContentLoading />
        ) : (
          React.Children.toArray(children)
            .filter(child => child.type.displayName === 'ReadOnlyContent')
            .map(child => child.props.children)
        )}
      </CardContent>
    </Card>
  );
};

ReadOnlyCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  avatar: PropTypes.node,
  title: PropTypes.node,
  headerButtons: PropTypes.node,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  dividerVariant: PropTypes.string,
  subheader: PropTypes.node,
  additionalInfo: PropTypes.node,
  ariaLabel: PropTypes.string,
  ariaAttributes: PropTypes.object
};

export default ReadOnlyCard;
