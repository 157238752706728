import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { Date } from '~/modules/common/components';
import { useProjectRisk } from '~/modules/projects/project/hooks';
import ProgressItemScore from '~/modules/common/charts/dashboard/ProgressChart/ProgressItemScore';
import ProgressItemTooltip from '~/modules/common/charts/dashboard/ProgressChart/ProgressItemTooltip';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import ProgressItemVariation from './ProgressItemVariation';
import ProgressItemError from './ProgressItemError';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  rowItemsRight: {
    display: 'flex',
    justifyContent: 'end'
  },
  predictedEndDate: {
    alignItems: 'flex-end',
    textAlign: 'end'
  },
  endDate: {
    alignItems: 'flex-start',
    textAlign: 'start'
  },
  delimiter: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: 1.6
  },
  details: {
    fontWeight: theme.typography.fontWeightBold
  },
  variationNeutral: {
    color: theme.palette.text.primary
  },
  variationPredictedAfter: {
    color: theme.palette.error.dark
  },
  variationPredictedBefore: {
    color: theme.palette.success.dark
  },
  variation: {
    color: theme.palette.text.secondary
  },
  variationNegative: {
    color: theme.palette.error.dark
  },
  variationPositive: {
    color: theme.palette.success.dark
  },
  notAvailable: {
    color: theme.palette.text.disabled
  },
  message: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  dayDifference: {
    marginBottom: theme.spacing(-3),
    fontSize: theme.typography.caption.fontSize
  }
}));

export const ProgressProjectRisk = ({ projectId, endDate }) => {
  const intl = useIntl();

  const classes = useStyles();
  const {
    predictedEndDate,
    predictedDiff,
    riskScore,
    loading,
    error
  } = useProjectRisk({
    projectUri: projectId,
    endDate
  });

  const isProjectRiskScoreEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPrpRiskScoreEnabled'
  });

  const isPsaPswatLazyLoadRolledUpCostSummaryEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPswatLazyLoadRolledUpCostSummaryEnabled'
  });

  const predictedEndDateTooltipContent = useMemo(
    () => <Date value={predictedEndDate} variant="inherit" />,
    [predictedEndDate]
  );

  const plannedEndDateTooltipContent = useMemo(
    () => <Date value={endDate} variant="inherit" />,
    [endDate]
  );

  const predictedEndDateClass = classNames(
    classes.details,
    endDate && predictedDiff
      ? predictedDiff < 0
        ? classes.variationPredictedAfter
        : classes.variationPredictedBefore
      : classes.variationNeutral
  );

  return loading ? (
    <>
      {isProjectRiskScoreEnabled ? (
        <ProgressItemScore
          title="kpiCharts.projectRiskScore"
          value={null}
          fixedDecimalScale={false}
          isLoading={loading}
        />
      ) : (
        <ProgressItemVariation
          title="kpiCharts.predictedEndDate"
          value={null}
          fixedDecimalScale={false}
          isLoading={loading}
        />
      )}
      <div className={classes.rowItems}>
        <div className={predictedEndDate}>
          <Skeleton variant="text" />
          <span className={classes.message}>
            <FormattedMessage id="dashboardProgressChart.predicted" />
          </span>
        </div>
        <span className={classNames(classes.delimiter, classes.message)}>
          <FormattedMessage id="dashboardProgressChart.vs" />
        </span>
        <div className={endDate}>
          <Skeleton variant="text" />
          <span className={classes.message}>
            <FormattedMessage id="dashboardProgressChart.planned" />
          </span>
        </div>
      </div>
    </>
  ) : (
    <>
      {isProjectRiskScoreEnabled ? (
        <ProgressItemScore
          title="kpiCharts.projectRiskScore"
          value={riskScore}
          fixedDecimalScale={false}
          isLoading={loading}
          error={
            isPsaPswatLazyLoadRolledUpCostSummaryEnabled ? error : undefined
          }
        />
      ) : (
        <ProgressItemVariation
          title="kpiCharts.predictedEndDate"
          value={predictedDiff}
          fixedDecimalScale={false}
          suffix={intl.formatMessage(
            { id: 'dashboardProgressChart.dayx' },
            {
              count: predictedDiff
            }
          )}
          isLoading={loading}
          error={
            isPsaPswatLazyLoadRolledUpCostSummaryEnabled ? error : undefined
          }
        />
      )}
      <div className={classes.rowItems}>
        {isPsaPswatLazyLoadRolledUpCostSummaryEnabled && error ? (
          <ProgressItemError />
        ) : (
          <>
            <div className={classes.predictedEndDate}>
              {predictedEndDate !== null && !error ? (
                <>
                  <div>
                    <Tooltip title={predictedEndDateTooltipContent}>
                      <span>
                        <Date
                          value={predictedEndDate}
                          className={predictedEndDateClass}
                        />
                      </span>
                    </Tooltip>
                  </div>
                  <span className={classes.message}>
                    <FormattedMessage id="dashboardProgressChart.predicted" />
                  </span>
                  {isProjectRiskScoreEnabled && (
                    <div className={classes.dayDifference}>
                      <span
                        className={classNames(
                          classes.rowItemsRight,
                          classes.keyValue,
                          classes.variation,
                          {
                            [classes.variationPositive]: predictedDiff > 0,
                            [classes.variationNegative]: predictedDiff < 0
                          }
                        )}
                      >
                        <ProgressItemTooltip
                          className={classNames(classes.variation, {
                            [classes.variationPositive]: predictedDiff > 0,
                            [classes.variationNegative]: predictedDiff < 0
                          })}
                          value={Math.abs(predictedDiff)}
                          fixedDecimalScale={false}
                          suffix={`${intl.formatMessage(
                            { id: 'dashboardProgressChart.dayx' },
                            {
                              count: Math.abs(predictedDiff)
                            }
                          )})`}
                          prefix={`(${
                            predictedDiff < 0
                              ? '+'
                              : predictedDiff > 0
                              ? '-'
                              : ''
                          }`}
                        />
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'dashboardProgressChart.noPredictedDateTooltip'
                  })}
                >
                  <span className={classes.notAvailable}>
                    <FormattedMessage id="dashboardProgressChart.noPredictedDate" />
                  </span>
                </Tooltip>
              )}
            </div>
            <span className={classNames(classes.delimiter, classes.message)}>
              <FormattedMessage id="dashboardProgressChart.vs" />
            </span>
            <div className={classes.endDate}>
              {endDate !== null && !error ? (
                <>
                  <div>
                    <Tooltip title={plannedEndDateTooltipContent}>
                      <span>
                        <Date
                          value={endDate}
                          className={classNames(
                            classes.details,
                            classes.variationNeutral
                          )}
                        />
                      </span>
                    </Tooltip>
                  </div>
                  <span className={classes.message}>
                    <FormattedMessage id="dashboardProgressChart.planned" />
                  </span>
                </>
              ) : (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'dashboardProgressChart.noPlannedDateTooltip'
                  })}
                >
                  <span className={classes.notAvailable}>
                    <FormattedMessage id="dashboardProgressChart.noPlannedDate" />
                  </span>
                </Tooltip>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

ProgressProjectRisk.propTypes = {
  projectId: PropTypes.string.isRequired,
  endDate: PropTypes.string
};

export default ProgressProjectRisk;
