import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import { AddSharp as PlusIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { dateToMidnightUTCString } from '~/modules/common/dates/convert';
import { PERIOD_SCALE_ENUM } from '~/modules/resourcing/common/enums';
import { useDialogState } from '~/modules/common/hooks';
import AllocateResourcePopup from '~/modules/resourcing/components/AllocateResourcePopup/AllocateResourcePopup';
import { useAllocateResourcePopupHandler } from '~/modules/resourcing/components/AllocateResourcePopup/hooks/useAllocateResourcePopupHandler';
import { getEffectiveSchedule } from '~/modules/common/dates/schedule';
import ShowSmartFitDialogOrLoading from '~/modules/resourcing/components/ResourceAssignmentDialog/components/ResourceUsersChartPanel/components/ShowSmartFitDialogOrLoading';
import { useQuickAllocationContext, useProjectDetails } from '../../hooks';

const AddProjectButton = ({
  roleSchedules,
  classes,
  createResourceAllocation,
  resourceUser,
  loading,
  popupAnchorEl,
  onPopupMenuClose,
  onPopupMenuClick
}) => {
  const {
    scale,
    chartStartDate,
    setAvailabilityLoading
  } = useQuickAllocationContext();

  const [newDraftResourceAllocation, setNewDraftResourceAllocation] = useState({
    scheduleRules: [],
    project: {
      defaultScheduleRule: {}
    }
  });

  const {
    open: openSmartFitDialogOrLoadingState,
    closeDialog: closeShowSmartFitDialog,
    openDialog: openShowSmartFitDialogOrLoading
  } = useDialogState(false);

  const closeShowSmartFitDialogOrLoading = useCallback(() => {
    closeShowSmartFitDialog();
    setNewDraftResourceAllocation({});
  }, [closeShowSmartFitDialog, setNewDraftResourceAllocation]);

  const { fetchProjectDetails } = useProjectDetails();

  const defaultDates = useMemo(() => {
    const startDate = DateTime.utc();
    const defaultEndDateScale = scale
      ? scale === PERIOD_SCALE_ENUM.DAYS
        ? PERIOD_SCALE_ENUM.WEEKS
        : scale
      : PERIOD_SCALE_ENUM.MONTHS;
    const endDate = startDate
      .plus({ [defaultEndDateScale]: 1 })
      .minus({ days: scale ? 1 : 0 });

    return {
      startDate: dateToMidnightUTCString(startDate),
      endDate: dateToMidnightUTCString(endDate)
    };
  }, [scale]);

  const [totalScheduledHours, setTotalScheduledHours] = useState(0);

  const handleClick = useCallback(
    event => {
      setTotalScheduledHours(0);
      onPopupMenuClick(event);
    },
    [onPopupMenuClick]
  );

  const effectiveSchedule = getEffectiveSchedule(roleSchedules || []);
  const primaryRole = (effectiveSchedule.projectRoles || []).find(
    role => role.isPrimary
  );

  const projectRole = useMemo(
    () =>
      primaryRole && primaryRole.projectRole
        ? {
            id: primaryRole.projectRole.uri,
            displayText: primaryRole.projectRole.name
          }
        : null,
    [primaryRole]
  );

  const [existingResourceAllocation, setExistingResourceAllocation] = useState(
    null
  );

  const {
    initialValues,
    handleAdd: handleDraftAdd,
    validationSchema
  } = useAllocateResourcePopupHandler({
    scale,
    defaultDates,
    setNewDraftResourceAllocation,
    user: resourceUser,
    projectRole,
    totalScheduledHours: 0,
    scheduleRules: []
  });

  const handleAdd = useCallback(
    values => {
      handleDraftAdd(values);
      openShowSmartFitDialogOrLoading();
    },
    [handleDraftAdd, openShowSmartFitDialogOrLoading]
  );

  const createAllocationWithUserAndOverrideRules = useCallback(
    overrideScheduleRules => {
      createResourceAllocation({
        allocation: newDraftResourceAllocation,
        overrideScheduleRules,
        existingResourceAllocation
      });

      closeShowSmartFitDialogOrLoading();
      setAvailabilityLoading();
    },
    [
      closeShowSmartFitDialogOrLoading,
      createResourceAllocation,
      newDraftResourceAllocation,
      setAvailabilityLoading,
      existingResourceAllocation
    ]
  );

  return (
    <>
      <Button
        color="primary"
        className={classes.showMoreButtonText}
        onClick={handleClick}
      >
        <PlusIcon size="small" />
        <FormattedMessage id="quickAllocation.addToProject" />
      </Button>
      {popupAnchorEl && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleAdd}
          validate={validationSchema}
          enableReinitialize={false}
        >
          <AllocateResourcePopup
            anchorEl={popupAnchorEl}
            fetchProjectDetails={fetchProjectDetails}
            handleAdd={handleAdd}
            showAddUser={false}
            showAddProject
            onClose={onPopupMenuClose}
            totalScheduledHours={totalScheduledHours}
            setTotalScheduledHours={setTotalScheduledHours}
            scale={scale}
            isSaving={loading}
            showLoading
            defaultUser={resourceUser}
            existingResourceAllocation={existingResourceAllocation}
            setExistingResourceAllocation={setExistingResourceAllocation}
          />
        </Formik>
      )}
      {openSmartFitDialogOrLoadingState && (
        <ShowSmartFitDialogOrLoading
          scale={scale}
          resourceUser={resourceUser}
          scheduleRules={newDraftResourceAllocation.scheduleRules}
          closeShowSmartFitDialogOrLoading={closeShowSmartFitDialogOrLoading}
          projectDefaultScheduleRule={
            newDraftResourceAllocation.project.defaultScheduleRule
          }
          chartStartDate={chartStartDate}
          createAllocationWithUserAndOverrideRules={
            createAllocationWithUserAndOverrideRules
          }
        />
      )}
    </>
  );
};

AddProjectButton.propTypes = {
  roleSchedules: PropTypes.array,
  classes: PropTypes.object,
  createResourceAllocation: PropTypes.func.isRequired,
  resourceUser: PropTypes.object,
  loading: PropTypes.bool,
  popupAnchorEl: PropTypes.object,
  onPopupMenuClose: PropTypes.func,
  onPopupMenuClick: PropTypes.func
};

export default AddProjectButton;
