import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography, makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const TagsCardEditTitle = ({ avatar, title, ariaAttributes = {} }) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();
  const classes = useStyles();

  const { role = 'heading', ['aria-level']: ariaLevel = 6 } = ariaAttributes;

  return (
    <div className={classes.root}>
      <Icon className={classes.icon}>{avatar}</Icon>
      <Typography
        variant="h6"
        {...(isPsaPrpWcagFixesQ4Enabled && {
          role,
          'aria-level': ariaLevel
        })}
      >
        {title}
      </Typography>
    </div>
  );
};

TagsCardEditTitle.propTypes = {
  avatar: PropTypes.node,
  title: PropTypes.node.isRequired,
  ariaAttributes: PropTypes.number
};

export default TagsCardEditTitle;
