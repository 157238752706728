import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, DialogTitle } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import RoleIcon from '~/modules/common/components/Icons/Objects/RoleIcon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const RolesDialogTitle = () => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();
  const classes = useStyles();

  return (
    <DialogTitle className={classes.root} disableTypography>
      <RoleIcon className={classes.icon} />
      <Typography
        id="RoleDialogTitle"
        data-qe-id="RoleDialogTitle"
        variant="h6"
        {...(isPsaPrpWcagFixesQ4Enabled && {
          role: 'heading',
          'aria-level': 2
        })}
      >
        <FormattedMessage id="roleInfoCard.role" />
      </Typography>
    </DialogTitle>
  );
};

export default RolesDialogTitle;
