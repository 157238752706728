import { makeStyles } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import EditableCard, {
  Edit,
  EditContent,
  EditTitle,
  ReadOnly,
  ReadOnlyContent
} from '~/modules/common/components/EditableCard';
import { useIsBreakpointDown } from '~/modules/common/hooks/useBreakpoint';
import { useOnSubmit } from '~/modules/tags/hooks/useOnSubmit';
import { useTagsEditFormState } from '~/modules/tags/hooks/useTagsEditFormState';
import TagsPropTypes from '~/modules/tags/propTypes';
import TagsCardActions from './TagsCardActions';
import TagsCardContentEditable from './TagsCardContentEditable';
import TagsCardContentReadOnly from './TagsCardContentReadOnly';
import TagsCardEditTitle from './TagsCardEditTitle';
import TagsCardSkeleton from './TagsCardSkeleton';

const useStyles = makeStyles(theme => ({
  card: {},
  readOnlyCard: {},
  readOnlyCardHeader: {},
  noTags: {},
  tagItem: {},
  tagValue: {},
  tagLabel: {},
  tagSkeletonContainer: {},
  tagSkeletonItem: {},
  title: {},
  action: {}
}));

const doNothing = () => {};

export const InnerTagsCard = ({
  classes,
  showReadOnlyAvatar = true,
  tags,
  editable,
  title,
  icon,
  headerDividerVariant,
  ariaAttributes = {}
}) => {
  const isMobile = useIsBreakpointDown('sm');
  const {
    values,
    errors,
    handleSubmit,
    isSubmitting,
    isValid
  } = useFormikContext();

  return (
    <EditableCard
      classes={useMemo(
        () => ({
          card: classes.card,
          title: classes.title
        }),
        [classes.card, classes.title]
      )}
      maxWidth="sm"
      fullScreen={isMobile}
      editable={editable}
      dataQeId="Tags"
      ariaDialogLabelId="Tags"
      ariaLabelKey="tags.tagsCardDialog"
    >
      <ReadOnly
        classes={useMemo(
          () => ({
            headerRoot: classes.readOnlyCardHeader,
            action: classes.action
          }),
          [classes.readOnlyCardHeader, classes.action]
        )}
        avatar={showReadOnlyAvatar && icon}
        title={title}
        dividerVariant={headerDividerVariant}
        ariaAttributes={ariaAttributes}
      >
        <ReadOnlyContent>
          <TagsCardContentReadOnly
            classes={useMemo(
              () => ({
                readOnlyCard: classes.readOnlyCard,
                noTags: classes.noTags,
                tagItem: classes.tagItem,
                tagValue: classes.tagValue,
                tagLabel: classes.tagLabel,
                tagSkeletonContainer: classes.tagSkeletonContainer,
                tagSkeletonItem: classes.tagSkeletonItem
              }),
              [
                classes.readOnlyCard,
                classes.noTags,
                classes.tagItem,
                classes.tagLabel,
                classes.tagSkeletonContainer,
                classes.tagSkeletonItem,
                classes.tagValue
              ]
            )}
            loading={isSubmitting}
            tags={tags}
          />
        </ReadOnlyContent>
      </ReadOnly>
      <Edit
        ActionComponent={TagsCardActions}
        onSave={handleSubmit}
        onCancel={doNothing}
        saveable={isValid}
      >
        <EditTitle>
          <TagsCardEditTitle title={title} avatar={icon} />
        </EditTitle>
        <EditContent>
          <TagsCardContentEditable values={values} errors={errors} />
        </EditContent>
      </Edit>
    </EditableCard>
  );
};

InnerTagsCard.propTypes = {
  classes: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  tags: TagsPropTypes.tags,
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  showReadOnlyAvatar: PropTypes.bool,
  headerDividerVariant: PropTypes.string,
  ariaAttributes: PropTypes.object
};

export const TagsCardForm = ({ objectId, refetchQuery, ...rest }) => {
  const { tags } = rest;
  const intl = useIntl();
  const onSubmit = useOnSubmit({
    tags,
    objectId,
    refetchQuery,
    intl
  });
  const { initialValues, validationSchema } = useTagsEditFormState({
    tags,
    intl
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <InnerTagsCard {...rest} />
    </Formik>
  );
};

TagsCardForm.propTypes = {
  objectId: PropTypes.string.isRequired,
  refetchQuery: PropTypes.object
};

const TagsCard = ({ loading, classes: classesOverride, ...rest }) => {
  const { title, icon, headerDividerVariant } = rest;
  const classes = useStyles({ classes: classesOverride });

  return loading ? (
    <TagsCardSkeleton
      classes={classes}
      title={title}
      icon={icon}
      headerDividerVariant={headerDividerVariant}
    />
  ) : (
    <TagsCardForm classes={classes} {...rest} />
  );
};

TagsCard.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool
};

export default TagsCard;
