import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResourceMatchAlternativeLink from './ResourceMatchAlternativeLink';
import ResourceMatchCancelLink from './ResourceMatchCancelLink';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const ResourceMatchPopoverFooter = ({
  onClose,
  scale,
  displayUnit,
  resourceRequest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ResourceMatchAlternativeLink
        resourceRequest={resourceRequest}
        scale={scale}
        displayUnit={displayUnit}
      />
      <ResourceMatchCancelLink onClose={onClose} />
    </div>
  );
};

ResourceMatchPopoverFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object,
  scale: PropTypes.string,
  displayUnit: PropTypes.string
};

export default ResourceMatchPopoverFooter;
