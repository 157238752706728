/* eslint-disable react/jsx-max-depth */
import { Divider, makeStyles, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Decimal,
  FormReadOnlyField,
  FormSection,
  NoValue
} from '~/modules/common/components';
import { OverviewItemTooltip } from '~/modules/common/charts/dashboard';
import { useMeContext } from '~/modules/me/useMeContext';
import { FormRow } from '~/modules/common/components/FormSection';
import {
  getReactMoneyFormatFromMe,
  getReactNumberFormatFromMe
} from '~/modules/common/numbers';

const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },
  divider: {
    width: '100%',
    margin: theme.spacing(1, 0, 2, 0)
  },
  container: {
    display: 'flex'
  },
  formSection: {
    flexGrow: 1
  },
  sign: ({ isPsaPrp2024q4BugFixesEnabled }) => ({
    fontWeight: theme.typography.fontWeightBold,
    ...(isPsaPrp2024q4BugFixesEnabled
      ? { margin: theme.spacing(1.25, 2.5, 0, 0.5) }
      : {
          marginTop: theme.typography.pxToRem(10),
          paddingLeft: theme.typography.pxToRem(16),
          paddingRight: theme.typography.pxToRem(16)
        })
  }),
  budgetHourGrid: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(15.25)
  },
  budgetCostGrid: {
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(11.5)
  },
  total: {
    fontWeight: theme.typography.fontWeightBold
  },
  amount: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary
  }
}));

const budgetLabel = <FormattedMessage id="projectFinancialsCard.budget" />;
const estimatesLabel = (
  <FormattedMessage id="projectFinancialsCard.estimates" />
);
const budgetHoursLabel = (
  <FormattedMessage id="projectFinancialsCard.budgetHours" />
);
const budgetedCostLabel = (
  <FormattedMessage id="projectFinancialsCard.budgetedCost" />
);
const totalEstimatedContractLabel = (
  <FormattedMessage id="projectFinancialsCard.totalEstimatedContract" />
);
const estimatedHoursLabel = (
  <FormattedMessage id="projectFinancialsCard.estimatedHours" />
);
const estimatedCostLabel = (
  <FormattedMessage id="projectFinancialsCard.estimatedCost" />
);
const costTypeLabel = <FormattedMessage id="projectFinancialsCard.costType" />;
const resourceBudgetedLabel = (
  <FormattedMessage id="projectFinancialsCard.resourceBudgeted" />
);
const expenseBudgetedLabel = (
  <FormattedMessage id="projectFinancialsCard.expenseBudgeted" />
);
const plusSign = <FormattedMessage id="projectFinancialsCard.plusSign" />;
const equalSign = <FormattedMessage id="projectFinancialsCard.equalSign" />;
const costTotal = <FormattedMessage id="projectFinancialsCard.cost" />;

const FinancialsReadOnly = ({ projectPermissions = {} }) => {
  const me = useMeContext();

  const {
    isRolledUpTaskEstimateCalculationMethodEnabled,
    isExpenseProductEnabled,
    featureFlags: {
      isPsaPrpProductPackagingEnabled,
      isPsaPrp2024q4BugFixesEnabled
    }
  } = me;
  const classes = useStyles({ isPsaPrp2024q4BugFixesEnabled });

  const checkForExpenseProduct = isPsaPrpProductPackagingEnabled
    ? isExpenseProductEnabled
    : true;

  const moneySettings = getReactMoneyFormatFromMe(me);
  const numberSettings = getReactNumberFormatFromMe(me);
  const {
    canViewBillingContracts,
    canViewCostTypes,
    canViewProjectCostData
  } = projectPermissions;
  const { values } = useFormikContext();

  const {
    budgetHours,
    totalEstimatedContract,
    budgetedCost,
    estimatedHours,
    estimatedCost,
    costType,
    resourceBudgetedCost,
    expenseBudgetedCost
  } = values;

  const costClasses = useMemo(
    () => ({
      legend: classes.legend
    }),
    [classes.legend]
  );

  const titleField = title => {
    return (
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
    );
  };

  const amountTitleField = title => {
    return (
      <>
        <Typography variant="caption" className={classes.title}>
          {title}
        </Typography>
      </>
    );
  };

  const amountField = ({ cost, dataQeId }) => {
    return (
      <>
        <Typography variant="body2" className={classes.amount}>
          {isRolledUpTaskEstimateCalculationMethodEnabled &&
          cost &&
          cost.amount === null ? (
            <NoValue />
          ) : cost && cost.currency ? (
            <OverviewItemTooltip
              currencySymbol={
                cost?.currency?.displayText || cost?.currency?.symbol
              }
              value={cost.amount}
              moneySettings={moneySettings}
              numberSettings={numberSettings}
              intlKey="cost"
              id="projectFinancialsCard.costDescription"
              dataQeId={dataQeId}
            />
          ) : null}
        </Typography>
      </>
    );
  };

  const signField = sign => {
    return (
      <Typography variant="caption" className={classes.sign}>
        {sign}
      </Typography>
    );
  };

  return (
    <div>
      {checkForExpenseProduct ? (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.margin}>
              {titleField(budgetLabel)}
            </Grid>
            {canViewProjectCostData && (
              <>
                <Grid
                  item
                  className={
                    isPsaPrp2024q4BugFixesEnabled
                      ? classes.budgetCostGrid
                      : classes.margin
                  }
                  data-qe-id="ResourceBudgetCost"
                >
                  {amountTitleField(resourceBudgetedLabel)}
                  {amountField({
                    cost: resourceBudgetedCost,
                    dataQeId: 'ResourceBudgetCostAmount'
                  })}
                </Grid>
                {signField(plusSign)}
                <Grid
                  item
                  className={
                    isPsaPrp2024q4BugFixesEnabled
                      ? classes.budgetCostGrid
                      : classes.margin
                  }
                  data-qe-id="ExpenseBudgetCost"
                >
                  {amountTitleField(expenseBudgetedLabel)}
                  {amountField({
                    cost: expenseBudgetedCost,
                    dataQeId: 'ExpenseBudgetCostAmount'
                  })}
                </Grid>
                {signField(equalSign)}
                <Grid
                  item
                  className={
                    isPsaPrp2024q4BugFixesEnabled
                      ? classes.budgetCostGrid
                      : classes.margin
                  }
                  data-qe-id="BudgetCost"
                >
                  {amountTitleField(costTotal)}
                  {amountField({
                    cost: budgetedCost,
                    dataQeId: 'BudgetCostAmount'
                  })}
                </Grid>
                {isPsaPrp2024q4BugFixesEnabled && (
                  <Grid container spacing={0}>
                    <Grid item xs={2} className={classes.budgetHourGrid}>
                      <Typography variant="caption" className={classes.title}>
                        {budgetHoursLabel}
                      </Typography>
                      {isRolledUpTaskEstimateCalculationMethodEnabled &&
                      budgetHours === null ? (
                        <NoValue />
                      ) : (
                        <span className={classes.total}>
                          <Decimal value={budgetHours} />
                        </span>
                      )}
                    </Grid>
                    {Boolean(canViewBillingContracts) && (
                      <Grid item xs={6}>
                        {amountTitleField(totalEstimatedContractLabel)}
                        {amountField({
                          cost: totalEstimatedContract,
                          dataQeId: 'TotalContractCostAmount'
                        })}
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          <FormSection classes={costClasses} title={budgetLabel}>
            <FormRow>
              <FormReadOnlyField
                label={budgetHoursLabel}
                textSize="small"
                className={classes.total}
              >
                {isRolledUpTaskEstimateCalculationMethodEnabled &&
                budgetHours === null ? (
                  <NoValue />
                ) : (
                  <Decimal value={budgetHours} />
                )}
              </FormReadOnlyField>
              {canViewProjectCostData && (
                <FormReadOnlyField
                  label={budgetedCostLabel}
                  textSize="small"
                  className={classes.total}
                >
                  {isRolledUpTaskEstimateCalculationMethodEnabled &&
                  budgetedCost &&
                  budgetedCost.amount === null ? (
                    <NoValue />
                  ) : budgetedCost && budgetedCost.currency ? (
                    <Decimal
                      value={budgetedCost.amount}
                      prefix={`${budgetedCost.currency.displayText} `}
                    />
                  ) : null}
                </FormReadOnlyField>
              )}
              {Boolean(canViewBillingContracts) && (
                <FormReadOnlyField
                  label={totalEstimatedContractLabel}
                  textSize="small"
                  className={classes.total}
                >
                  {totalEstimatedContract && totalEstimatedContract.currency ? (
                    <Decimal
                      value={totalEstimatedContract.amount}
                      prefix={`${totalEstimatedContract.currency.displayText} `}
                    />
                  ) : null}
                </FormReadOnlyField>
              )}
            </FormRow>
          </FormSection>
        </>
      )}
      <Divider className={classes.divider} />
      {checkForExpenseProduct ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={canViewCostTypes ? 8 : 12}>
              {titleField(estimatesLabel)}
            </Grid>
            {canViewCostTypes && (
              <Grid item xs={4}>
                {titleField(costTypeLabel)}
              </Grid>
            )}
            <Grid
              item
              xs={canViewCostTypes ? 4 : 6}
              data-qe-id="EstimatedHours"
            >
              {amountTitleField(estimatedHoursLabel)}
              <Typography variant="body2" className={classes.amount}>
                {isRolledUpTaskEstimateCalculationMethodEnabled &&
                estimatedHours === null ? (
                  <NoValue />
                ) : (
                  <Decimal value={estimatedHours} fixedDecimalScale />
                )}
              </Typography>
            </Grid>
            {canViewProjectCostData && (
              <>
                <Grid
                  item
                  xs={canViewCostTypes ? 4 : 6}
                  data-qe-id="EstimatedCost"
                >
                  {amountTitleField(estimatedCostLabel)}
                  {amountField({
                    cost: estimatedCost,
                    dataQeId: 'EstimatedCostAmount'
                  })}
                </Grid>
              </>
            )}
            {canViewCostTypes && (
              <>
                <Grid item xs={4} data-qe-id="CostType">
                  {amountTitleField(costTypeLabel)}
                  <Typography>
                    {costType ? costType.displayText : null}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        <div className={classes.container}>
          <div className={classes.formSection}>
            <FormSection classes={costClasses} title={estimatesLabel}>
              <FormRow>
                <FormReadOnlyField
                  label={estimatedHoursLabel}
                  textSize="small"
                  className={classes.total}
                >
                  {isRolledUpTaskEstimateCalculationMethodEnabled &&
                  estimatedHours === null ? (
                    <NoValue />
                  ) : (
                    <Decimal value={estimatedHours} fixedDecimalScale />
                  )}
                </FormReadOnlyField>
                {canViewProjectCostData && (
                  <FormReadOnlyField
                    label={estimatedCostLabel}
                    textSize="small"
                    className={classes.total}
                  >
                    {isRolledUpTaskEstimateCalculationMethodEnabled &&
                    estimatedCost &&
                    estimatedCost.amount === null ? (
                      <NoValue />
                    ) : estimatedCost && estimatedCost.currency ? (
                      <OverviewItemTooltip
                        currencySymbol={
                          estimatedCost?.currency?.displayText ||
                          estimatedCost?.currency?.symbol
                        }
                        value={estimatedCost.amount}
                        moneySettings={moneySettings}
                        numberSettings={numberSettings}
                        intlKey="cost"
                        id="projectFinancialsCard.costDescription"
                      />
                    ) : null}
                  </FormReadOnlyField>
                )}
              </FormRow>
            </FormSection>
          </div>
          {canViewCostTypes && (
            <div>
              <FormSection classes={costClasses} title={costTypeLabel}>
                <FormReadOnlyField
                  label={costTypeLabel}
                  textSize="small"
                  className={classes.total}
                >
                  <span>{costType ? costType.displayText : null}</span>
                </FormReadOnlyField>
              </FormSection>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FinancialsReadOnly.propTypes = {
  projectPermissions: PropTypes.object
};

export default FinancialsReadOnly;
