import React from 'react';
import PropTypes from 'prop-types';
import AttachmentsBlock from './AttachmentsBlock';

export const FileAttachment = ({
  attachToUri,
  editable,
  isLoading,
  customMetadataFn,
  ariaAttributes
}) => (
  <AttachmentsBlock
    key="attachments"
    isLoadingDetails={isLoading}
    attachToUri={attachToUri}
    editable={editable}
    customMetadataFn={customMetadataFn}
    ariaAttributes={ariaAttributes}
  />
);

FileAttachment.propTypes = {
  attachToUri: PropTypes.string,
  editable: PropTypes.bool,
  isLoading: PropTypes.bool,
  customMetadataFn: PropTypes.func,
  ariaAttributes: PropTypes.object
};

export default FileAttachment;
