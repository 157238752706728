import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardExpansionPanel } from '~/modules/common/components';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import ExpansionPanelTitle from './ExpansionPanelTitle';

const useCardStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useCardExpanstionPanelStyles = makeStyles(theme => ({
  expansionPanelSummary: {
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  },
  expansionPanel: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  expansionPanelSummaryContent: {
    padding: theme.spacing(0, 0, 0, 1),
    alignItems: 'center',
    minHeight: theme.spacing(6)
  }
}));

export const ReadOnlyExpandableCard = ({
  title,
  subTitle,
  avatar,
  editable,
  onEdit,
  onChange,
  children,
  ariaLabel,
  ariaAttributes = {},
  headerButtons,
  isLoading = false
}) => {
  const cardExpanstionPanelCalsses = useCardExpanstionPanelStyles();
  const cardHeaderClass = useCardStyles();
  const { role: ariaRole, 'aria-level': ariaLevel } = ariaAttributes;
  const titleComponentProps = useMemo(
    () => ({
      classes: cardHeaderClass,
      avatar,
      title,
      subTitle,
      editable,
      headerButtons,
      onEdit,
      ariaLabel,
      ariaAttributes: { role: ariaRole, 'aria-level': ariaLevel },
      fontSize: 'small'
    }),
    [
      avatar,
      cardHeaderClass,
      ariaLabel,
      ariaRole,
      ariaLevel,
      editable,
      headerButtons,
      onEdit,
      subTitle,
      title
    ]
  );

  if (isLoading) return <CardLoading />;

  return (
    <CardExpansionPanel
      TitleComponent={ExpansionPanelTitle}
      titleComponentProps={titleComponentProps}
      classes={cardExpanstionPanelCalsses}
      defaultExpanded={false}
      defaultExpandIcon={false}
      onChange={onChange}
    >
      {React.Children.toArray(children)
        .filter(child => child.type.displayName === 'ReadOnlyContent')
        .map(child => child.props.children)}
    </CardExpansionPanel>
  );
};

ReadOnlyExpandableCard.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  avatar: PropTypes.node,
  headerButtons: PropTypes.node,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  ariaAttributes: PropTypes.object
};

export default ReadOnlyExpandableCard;
