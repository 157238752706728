import { useCallback, useState } from 'react';

export const useResourceMatchPopoverHandler = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverResourceRequest, setPopoverResourceRequest] = useState(null);

  const handleCloseResourceMatchPopover = useCallback(() => {
    setPopoverResourceRequest(null);
    setAnchorEl(null);
  }, [setAnchorEl, setPopoverResourceRequest]);

  return {
    anchorEl,
    popoverResourceRequest,
    setAnchorEl,
    setPopoverResourceRequest,
    handleCloseResourceMatchPopover
  };
};

export default useResourceMatchPopoverHandler;
