import { IconButton, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import { useIntl } from 'react-intl';
import { ResourceRequestAccessLevel } from '~/types';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { getFullWidth } from '~/modules/common/charts/timeline/calculations';
import useResourceAllocationChartTabStateContext from '~/modules/resourcing/hooks/useResourceAllocationChartTabStateContext';
import { useIsToggleExpanded } from '~/modules/resourcing/common/hooks';
import ResourceAllocationGroupDetail from '../ResourceAllocationGroupDetail';
import ResourcePlanItem from '../ResourcePlanItem';
import useStyles from './useStyles';

const rightComponent = <div />;

export const ResourceAllocationGroupRow = memo(
  ({
    chartDates,
    isExpandedOnInitialRender,
    leftComponentWidth,
    maxSkillLevel,
    plan,
    requestAccessLevel,
    scale,
    setAnchorEl,
    setPopoverResourceRequest
  }) => {
    const { selectedChartTab } = useResourceAllocationChartTabStateContext();
    const classes = useStyles();
    const intl = useIntl();

    const { isExpanded, toggleIsExpandedState } = useIsToggleExpanded(
      isExpandedOnInitialRender
    );

    const cssClasses = useMemo(
      () => ({
        root: classes.itemRoot,
        itemContainer: classes.container,
        timeContainer: classes.timeContainer,
        timeContainerMonth: classes.timeContainerMonth,
        timeContainerWeek: classes.timeContainerWeek,
        timeContainerQuarter: classes.timeContainerQuarter,
        timeContainerDay: classes.timeContainerDay,
        totalContainer: classes.totalContainer
      }),
      [
        classes.container,
        classes.itemRoot,
        classes.timeContainer,
        classes.timeContainerDay,
        classes.timeContainerMonth,
        classes.timeContainerWeek,
        classes.totalContainer,
        classes.timeContainerQuarter
      ]
    );

    const leftComponent = useMemo(
      () => (
        <>
          <IconButton
            data-qe-id={`${plan.planName}_Expand_Collapse`}
            className={classNames(classes.expandButton, {
              [classes.expandButtonOpen]: isExpanded
            })}
            onClick={toggleIsExpandedState}
            aria-expanded={isExpanded}
            aria-label={
              isExpanded
                ? intl.formatMessage({
                    id: 'resourceAllocationProjectRow.showLess'
                  })
                : intl.formatMessage({
                    id: 'resourceAllocationProjectRow.showMore'
                  })
            }
          >
            <ExpandMoreIcon />
          </IconButton>
          <ResourcePlanItem
            name={
              plan.planName ||
              intl.formatMessage({
                id: 'resourceAllocationProjectRow.noRole'
              })
            }
          />
        </>
      ),
      [
        plan.planName,
        classes.expandButton,
        classes.expandButtonOpen,
        isExpanded,
        toggleIsExpandedState,
        intl
      ]
    );

    return (
      <Paper
        className={classes.root}
        style={useMemo(
          () => ({ minWidth: getFullWidth(scale, chartDates.length) }),
          [scale, chartDates.length]
        )}
      >
        <StyledChartRangeItem2
          scale={scale}
          classes={cssClasses}
          leftComponentWidth={leftComponentWidth}
          scaleItemCount={chartDates.length}
          chartStartDate={chartDates.length && chartDates[0].start}
        >
          {leftComponent}
          <div />
          {rightComponent}
        </StyledChartRangeItem2>

        {isExpanded && (
          <ResourceAllocationGroupDetail
            chartSelectedTab={selectedChartTab}
            maxSkillLevel={maxSkillLevel}
            leftComponentWidth={leftComponentWidth}
            plan={plan}
            requestAccessLevel={requestAccessLevel}
            setAnchorEl={setAnchorEl}
            setPopoverResourceRequest={setPopoverResourceRequest}
          />
        )}
      </Paper>
    );
  }
);

ResourceAllocationGroupRow.propTypes = {
  plan: PropTypes.object.isRequired,
  scale: PropTypes.string.isRequired,
  chartDates: PropTypes.array,
  maxSkillLevel: PropTypes.number,
  leftComponentWidth: PropTypes.number.isRequired,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  isExpandedOnInitialRender: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired
};

export default ResourceAllocationGroupRow;
