import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { roundToDecimals } from '~/modules/resourcing/common/util/scheduleUtil';
import TooltipLayout from '../../common/components/TooltipLayout';

const EditorPopoverTooltip = ({
  allocationRemainingVariance,
  endDate,
  otherTaskAllocationHours,
  newProjectAllocationHours,
  startDate,
  taskAllocationHours
}) => {
  const startDateUtc = mapRepliconDateToUtcObject(startDate);
  const endDateUtc = mapRepliconDateToUtcObject(endDate);
  const calculationRows = useMemo(
    () => [
      {
        messageId: 'taskAllocationEditor.projectAllocation',
        value: roundToDecimals(newProjectAllocationHours || 0)
      },
      {
        messageId: 'taskAllocationEditor.allocatedToOtherTask',
        value: roundToDecimals(otherTaskAllocationHours || 0)
      },
      {
        messageId: 'taskAllocationEditor.thisTaskAllocation',
        value: taskAllocationHours
      }
    ],
    [newProjectAllocationHours, otherTaskAllocationHours, taskAllocationHours]
  );

  const resultRow = useMemo(
    () => [
      {
        messageId: 'taskAllocationEditor.projectAllocationRemaining',
        value: allocationRemainingVariance
      }
    ],
    [allocationRemainingVariance]
  );

  return (
    <TooltipLayout
      calculationRows={calculationRows}
      resultRow={resultRow}
      startDate={startDateUtc}
      endDate={endDateUtc}
    />
  );
};

EditorPopoverTooltip.propTypes = {
  endDate: PropTypes.object,
  otherTaskAllocationHours: PropTypes.number,
  allocationRemainingVariance: PropTypes.number,
  startDate: PropTypes.object,
  newProjectAllocationHours: PropTypes.number,
  taskAllocationHours: PropTypes.number
};

export default EditorPopoverTooltip;
