import { gql, useApolloClient } from '@apollo/client';

export const TASK_RESOURCE_ESTIMATE_QUERY = gql`
  query getTaskResourceEstimate(
    $resourceEstimateUri: String!
    $includeTaskResourceUserHoursProgressSummary: Boolean!
    $includeTaskResourceUserCostProgressSummary: Boolean!
    $includeTaskResourceUserAllocation: Boolean!
  ) {
    taskResourceEstimateDetails(resourceEstimateUri: $resourceEstimateUri) {
      id
      taskResourceEstimateId
      taskId
      initialEstimatedHours
      initialEstimatedCost {
        amount
        currency {
          symbol
        }
      }
      resourceUser {
        id
        uri
        slug
        displayText
      }
      projectRole {
        id
        displayText
      }
      taskResourceUserHoursProgressSummary
        @include(if: $includeTaskResourceUserHoursProgressSummary) {
        actualHours
        estimatedAtCompletionHours
        estimatedRemainingHours
      }
      taskResourceUserCostProgressSummary
        @include(if: $includeTaskResourceUserCostProgressSummary) {
        actualCost {
          amount
          currency {
            symbol
          }
        }
        estimatedAtCompletionCost {
          amount
          currency {
            symbol
          }
        }
        estimatedRemainingCost {
          amount
          currency {
            symbol
          }
        }
      }
      taskResourceUserAllocation
        @include(if: $includeTaskResourceUserAllocation) {
        id
        taskUri
        projectUri
        roleUri
        startDate
        endDate
        scheduleRules {
          dateRange {
            startDate
            endDate
          }
          do
        }
        totalHours
      }
    }
  }
`;
export const useTaskResourceEstimate = () => {
  const apolloClient = useApolloClient();

  return {
    getTaskResourceEstimate: async ({
      resourceEstimateUri,
      includeTaskResourceUserHoursProgressSummary,
      includeTaskResourceUserCostProgressSummary = false,
      includeTaskResourceUserAllocation
    }) => {
      const {
        data: { taskResourceEstimateDetails },
        loading
      } = await apolloClient.query({
        query: TASK_RESOURCE_ESTIMATE_QUERY,
        variables: {
          resourceEstimateUri,
          includeTaskResourceUserHoursProgressSummary,
          includeTaskResourceUserCostProgressSummary,
          includeTaskResourceUserAllocation
        },
        fetchPolicy: 'network-only'
      });

      return { taskResourceEstimate: taskResourceEstimateDetails, loading };
    }
  };
};
