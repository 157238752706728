import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { SortDirection } from '~/types';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';
import QuickAllocationProjectChartHeader from '../QuickAllocationProjectChartHeader';
import useStyles, { useLeftComponentStyles } from './useStyles';

const useTaskAllocationFFOnStyles = makeStyles(theme => ({
  itemContainer: {
    padding: theme.spacing(0, 0, 0, 3)
  }
}));

const QuickAllocationProjectGroupHeader = ({
  chartDates,
  isRoleColumnSortable = true,
  leftComponentWidth,
  onSortChange,
  scale,
  sort,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const taskAllocationFFOnClasses = useTaskAllocationFFOnStyles();
  const classes = useStyles({
    classes: isPsaRmpTaskAllocation1Enabled
      ? taskAllocationFFOnClasses
      : undefined
  });
  const leftClasses = useLeftComponentStyles();

  const handleSortChange = useCallback(
    field => {
      onSortChange({
        field,
        direction:
          sort.field === field && sort.direction === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc
      });
    },
    [sort, onSortChange]
  );

  const leftComponent = useMemo(
    () => (
      <>
        <TableSortLabel
          field="projectName"
          show
          className={leftClasses.resourceHeader}
          active={sort.field === 'projectName'}
          direction={sort.direction.toLowerCase()}
          onClick={handleSortChange}
          data-qe-id="SortProject"
        >
          <FormattedMessage id="quickAllocation.project" />
        </TableSortLabel>
        {!isPsaRmpTaskAllocation1Enabled && (
          <TableSortLabel
            field="roleName"
            show={isRoleColumnSortable}
            className={leftClasses.roleHeader}
            active={sort.field === 'roleName'}
            direction={sort.direction.toLowerCase()}
            onClick={handleSortChange}
            data-qe-id="SortRole"
          >
            <FormattedMessage id="quickAllocation.projectRole" />
          </TableSortLabel>
        )}
      </>
    ),
    [
      handleSortChange,
      isPsaRmpTaskAllocation1Enabled,
      isRoleColumnSortable,
      leftClasses.resourceHeader,
      leftClasses.roleHeader,
      sort.direction,
      sort.field
    ]
  );

  return (
    <QuickAllocationProjectChartHeader
      chartDates={chartDates}
      classes={classes}
      leftComponent={leftComponent}
      leftComponentWidth={leftComponentWidth}
      scale={scale}
    />
  );
};

QuickAllocationProjectGroupHeader.propTypes = {
  chartDates: PropTypes.array.isRequired,
  isRoleColumnSortable: PropTypes.bool,
  scale: PropTypes.string.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default QuickAllocationProjectGroupHeader;
