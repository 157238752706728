import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    justifyContent: 'space-between'
  },
  resourceSkeleton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%'
  },
  proposeSkeleton: {
    paddingRight: theme.spacing(2)
  }
}));

export const ResourceMatchListLoading = () => {
  const classes = useStyles();

  return Array.from(Array(3).keys()).map(i => (
    <Paper key={i} className={classes.root} square elevation={0}>
      <div className={classes.resourceSkeleton}>
        <Skeleton height={36} width="65%" />
        <Skeleton height={36} width="11%" />
      </div>
      <Skeleton className={classes.proposeSkeleton} height={36} width="18%" />
    </Paper>
  ));
};

export default ResourceMatchListLoading;
