import PropTypes from 'prop-types';
import React, { useMemo, memo } from 'react';
import { useTheme } from '@material-ui/core';
import {
  RangeBoundaries,
  StyledChartRangeItem2
} from '~/modules/common/charts/timeline/components';
import { ResourceRequestAccessLevel } from '~/types';
import { useRowDynamicStyle } from '~/modules/resourcing/common/hooks';
import { getResourceRequestTotal } from '~/modules/resourcing/common/enhancers/withResourceRequestTotal';
import {
  AllocatedResourceRequestRowTotal,
  AllocatedResourceRequestBlockGroup
} from './components';
import useStyles from './useStyles';
import AllocatedResourceRequestItem from './components/AllocatedResourceRequestItem';

export const AllocatedResourceRequestRow = memo(
  ({
    chartDates,
    chartDisplayDateRange,
    chartDisplayPeriods,
    chartSelectedTab,
    displayEntityType,
    displayUnit,
    leftComponentWidth,
    maxSkillLevel,
    project,
    qeIdTag,
    requestAccessLevel,
    resourceRequest,
    scale,
    setAnchorEl,
    setPopoverResourceRequest
  }) => {
    const classes = useStyles();

    const { resourceAllocations } = resourceRequest;

    const theme = useTheme();

    const numberOfRows = useMemo(
      () =>
        Math.max(resourceRequest.quantity, resourceAllocations?.length || 0),
      [resourceRequest, resourceAllocations]
    );

    const dynamicContainerHeight = useMemo(
      () =>
        numberOfRows > 0
          ? theme.spacing(3) * numberOfRows +
            theme.spacing(0.5) * (numberOfRows - 1) +
            theme.spacing(1) * 2
          : 36,
      [numberOfRows, theme]
    );

    const { dynamicRowStyle } = useRowDynamicStyle({
      dynamicContainerHeight
    });

    const {
      resourceRequestTotalHours,
      resourceRequestTotalCost
    } = getResourceRequestTotal(resourceRequest);

    const total = useMemo(
      () => (
        <AllocatedResourceRequestRowTotal
          qeIdTag={qeIdTag}
          resourceRequest={resourceRequest}
          resourceAllocations={resourceAllocations}
          resourceRequestTotalHours={resourceRequestTotalHours}
          resourceRequestTotalCost={resourceRequestTotalCost}
          contextCurrency={resourceRequest.currency}
        />
      ),
      [
        qeIdTag,
        resourceRequest,
        resourceAllocations,
        resourceRequestTotalCost,
        resourceRequestTotalHours
      ]
    );

    const cssClassses = useMemo(
      () => ({
        root: classes.itemRoot,
        itemContainer: classes.itemContainer,
        totalContainer: classes.totalContainer
      }),
      [classes.itemContainer, classes.itemRoot, classes.totalContainer]
    );

    const resourceRequestItemClasses = useMemo(
      () => ({
        column: classes.column,
        clickableColumn: classes.clickableColumn,
        openButton: classes.openButton
      }),
      [classes.clickableColumn, classes.column, classes.openButton]
    );

    const item = useMemo(
      () => (
        <AllocatedResourceRequestItem
          classes={resourceRequestItemClasses}
          qeIdTag={qeIdTag}
          displayEntityType={displayEntityType}
          resourceRequest={resourceRequest}
          maxSkillLevel={maxSkillLevel}
          chartSelectedTab={chartSelectedTab}
          requestAccessLevel={requestAccessLevel}
          project={project}
          scale={scale}
          displayUnit={displayUnit}
          setAnchorEl={setAnchorEl}
          setPopoverResourceRequest={setPopoverResourceRequest}
        />
      ),
      [
        resourceRequestItemClasses,
        qeIdTag,
        displayEntityType,
        resourceRequest,
        maxSkillLevel,
        chartSelectedTab,
        requestAccessLevel,
        project,
        scale,
        displayUnit
      ]
    );

    const timeItem = useMemo(
      () => (
        <>
          <RangeBoundaries
            scale={scale}
            chartStartDate={chartDates[0].start}
            start={project.startDate}
            end={project.endDate}
          />
          <AllocatedResourceRequestBlockGroup
            resourceRequest={resourceRequest}
            scale={scale}
            chartDisplayDateRange={chartDisplayDateRange}
            chartDisplayPeriods={chartDisplayPeriods}
            displayUnit={displayUnit}
          />
        </>
      ),
      [
        scale,
        chartDates,
        project.startDate,
        project.endDate,
        resourceRequest,
        chartDisplayDateRange,
        chartDisplayPeriods,
        displayUnit
      ]
    );

    return (
      <>
        <StyledChartRangeItem2
          classes={cssClassses}
          dynamicStyle={dynamicRowStyle}
          scale={scale}
          scaleItemCount={chartDates.length}
          chartStartDate={chartDates.length && chartDates[0].start}
          leftComponentWidth={leftComponentWidth}
        >
          {item}
          {timeItem}
          {total}
        </StyledChartRangeItem2>
      </>
    );
  }
);

AllocatedResourceRequestRow.propTypes = {
  qeIdTag: PropTypes.string,
  scale: PropTypes.string.isRequired,
  chartDates: PropTypes.array,
  project: PropTypes.object.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  displayEntityType: PropTypes.string,
  chartDisplayDateRange: PropTypes.object.isRequired,
  maxSkillLevel: PropTypes.number,
  chartSelectedTab: PropTypes.string,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  displayUnit: PropTypes.string,
  chartDisplayPeriods: PropTypes.array,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired
};

export default AllocatedResourceRequestRow;
