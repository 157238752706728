import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import useKeyBoardEventHandlers from '~/modules/common/hooks/useKeyBoardEventHandlers';
import TaskAllocationEditor, { FieldLabel } from '../../TaskAllocationEditor';
import TaskNoResourcePopover from '../../TaskNoResourcePopover';
import TaskAllocationCardContent from './TaskAllocationCardContent';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 3,
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(30),
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 1, 1),
    flexDirection: 'column'
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  container: {
    justifyContent: 'initial'
  },
  label: {
    pointerEvents: 'none'
  }
}));

export const TaskAllocationCard = ({
  isReadOnlyView,
  classes: classesOverride
}) => {
  const { task, resourceEstimate, canEditTasks } = useTaskEstimateRowContext();

  const { resourceUser, taskResourceUserAllocation } = resourceEstimate;

  const classes = useStyles({ classes: classesOverride });
  const {
    project: { id: projectId }
  } = task;
  const [anchorEl, setAnchorEl] = useState(null);
  const onOpen = useCallback(
    event => {
      if (!isReadOnlyView && canEditTasks) setAnchorEl(event.target);
    },
    [isReadOnlyView, setAnchorEl, canEditTasks]
  );

  const { handleKeyDown } = useKeyBoardEventHandlers({ onEnterClick: onOpen });

  return (
    <>
      <Grid
        item
        container
        className={classNames(classes.root, {
          [classes.hover]: !isReadOnlyView && canEditTasks,
          [classes.container]: !taskResourceUserAllocation
        })}
        onClick={onOpen}
        onKeyDown={handleKeyDown}
        tabIndex={!isReadOnlyView && canEditTasks ? 0 : -1}
        data-qe-id="TaskAllocationCard"
      >
        {taskResourceUserAllocation ? (
          <TaskAllocationCardContent
            taskResourceUserAllocation={taskResourceUserAllocation}
            resourceUser={resourceUser}
          />
        ) : (
          <>
            <FieldLabel
              variant="caption"
              color="textSecondary"
              messageId="taskResourceEstimates.allocation"
              className={classes.label}
            />
            <FieldLabel
              variant="body2"
              messageId={
                isReadOnlyView
                  ? 'taskResourceEstimates.noAllocationSet'
                  : resourceUser
                  ? 'taskResourceEstimates.addAllocation'
                  : 'taskResourceEstimates.noResourceSelected'
              }
              color="textSecondary"
              className={classes.label}
            />
          </>
        )}
      </Grid>
      {Boolean(anchorEl) &&
        (resourceUser ? (
          <TaskAllocationEditor
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            projectId={projectId}
            userId={resourceUser.id}
            taskResourceUserAllocation={taskResourceUserAllocation}
          />
        ) : (
          <TaskNoResourcePopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        ))}
    </>
  );
};

TaskAllocationCard.propTypes = {
  isReadOnlyView: PropTypes.bool,
  classes: PropTypes.object
};

export default TaskAllocationCard;
