import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import QuickAllocationActions2 from '../../../../QuickAllocationActions/QuickAllocationsActions2';

const useContainerStyles = makeStyles(theme => ({
  rowCell: {
    width: theme.spacing(31.75),
    paddingLeft: theme.spacing(2)
  }
}));
const LeftComponentModel1 = ({
  allocatedProject,
  classes,
  committedResourceAllocations,
  handleRemoveResourceAllocation,
  projectsSlugLoading,
  resourceUser,
  setSnackBarState,
  showResourceActions
}) => {
  const containerClasses = useContainerStyles();

  return (
    <>
      <div className={containerClasses.rowCell}>
        <Tooltip title={allocatedProject.project.displayText}>
          <div className={classNames(classes.text, classes.projectText)}>
            {allocatedProject.project.displayText}
          </div>
        </Tooltip>
      </div>
      {showResourceActions || Boolean(allocatedProject.project.slug) ? (
        <QuickAllocationActions2
          allocatedProject={allocatedProject}
          resourceAllocations={committedResourceAllocations}
          resourceUser={resourceUser}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          setSnackBarState={setSnackBarState}
          showResourceActions={showResourceActions}
        />
      ) : projectsSlugLoading ? (
        <div className={classes.loadingMenuItems}>
          <Skeleton variant="rect" width={10} height={12} />
        </div>
      ) : (
        <div className={classes.actionPlaceHolder} />
      )}
    </>
  );
};

LeftComponentModel1.propTypes = {
  allocatedProject: PropTypes.object,
  classes: PropTypes.object,
  committedResourceAllocations: PropTypes.array,
  handleRemoveResourceAllocation: PropTypes.func,
  projectsSlugLoading: PropTypes.bool,
  resourceUser: PropTypes.object,
  setSnackBarState: PropTypes.func,
  showResourceActions: PropTypes.bool
};

export default LeftComponentModel1;
