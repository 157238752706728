import React from 'react';
import VeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfiedSharp';
import DissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedSharp';
import SatisfiedIcon from '@material-ui/icons/SentimentSatisfiedSharp';
import VerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfiedSharp';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import { SentimentNeutralIcon } from '~/modules/common/components/Icons';

const useStyles = makeStyles(theme => ({
  verySatisfied: {
    color: theme.palette.quality.verySatisfied
  },
  satisfied: {
    color: theme.palette.quality.satisfied
  },
  neutral: {
    color: theme.palette.text.disabled
  },
  dissatisfied: {
    color: theme.palette.quality.dissatisfied
  },
  veryDissatisfied: {
    color: theme.palette.quality.veryDissatisfied
  },
  sizeSmall: {
    fontSize: theme.spacing(2)
  },
  sizeMedium: {
    fontSize: theme.spacing(3)
  }
}));

export const QualityIcon = ({
  className,
  quality,
  size = 'medium',
  ...props
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();
  const classes = useStyles();
  const sizeclasses = {
    [classes.sizeSmall]: size === 'small',
    [classes.sizeMedium]: size === 'medium'
  };

  const intl = useIntl();

  switch (quality) {
    case 2:
      return (
        <VerySatisfiedIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.great'
            })
          }}
          className={classNames(className, classes.verySatisfied, sizeclasses)}
          {...props}
        />
      );
    case 1:
      return (
        <SatisfiedIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.good'
            })
          }}
          className={classNames(className, classes.satisfied, sizeclasses)}
          {...props}
        />
      );
    case 0:
      return (
        <SentimentNeutralIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.neutral'
            })
          }}
          className={classNames(className, classes.neutral, sizeclasses)}
          {...props}
        />
      );
    case -1:
      return (
        <DissatisfiedIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.bad'
            })
          }}
          className={classNames(className, classes.dissatisfied, sizeclasses)}
          {...props}
        />
      );
    case -2:
      return (
        <VeryDissatisfiedIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.horrible'
            })
          }}
          className={classNames(
            className,
            classes.veryDissatisfied,
            sizeclasses
          )}
          {...props}
        />
      );
    default:
      return (
        <SentimentNeutralIcon
          {...{
            [isPsaPrpWcagFixesQ4Enabled
              ? 'titleAccess'
              : 'aria-label']: intl.formatMessage({
              id: 'qualityIcon.neutral'
            })
          }}
          className={classNames(className, classes.neutral, sizeclasses)}
          {...props}
        />
      );
  }
};

QualityIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  className: PropTypes.string,
  quality: PropTypes.oneOf([-2, -1, 0, 1, 2])
};

export default QualityIcon;
