import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useHasPermission } from '~/modules/common/permissions';
import {
  mapIsoStringtoUtcObject,
  isoStringToObject
} from '~/modules/common/dates/convert';
import { isNullOrUndefined } from '~/modules/common/util';
import {
  getProjectAllocationHours,
  getTaskAllocationHoursForResourceUser,
  useTotalResourceAvailableDurationForDateRangeCallback
} from '~/modules/tasks/hooks';
import { CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE } from '../enum';

export const useOnChangeHandler = ({
  projectId,
  taskResourceUserAllocation,
  setIsNewUserInfoLoading
}) => {
  const apolloClient = useApolloClient();
  const { values, setValues, setFieldValue } = useFormikContext();
  const {
    getTotalResourceAvailableHoursForDateRange
  } = useTotalResourceAvailableDurationForDateRangeCallback({
    fetchPolicy: 'network-only'
  });
  const isResourceManager = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const onResourceUserChange = useCallback(
    async newUser => {
      let availableHours = null;

      setFieldValue('newResourceUser', newUser);
      if (!newUser || !taskResourceUserAllocation) return;
      setIsNewUserInfoLoading(true);
      const startDate = mapIsoStringtoUtcObject(
        taskResourceUserAllocation.startDate
      );
      const endDate = mapIsoStringtoUtcObject(
        taskResourceUserAllocation.endDate
      );

      if (isResourceManager) {
        availableHours =
          newUser?.availableDuration ??
          (
            await getTotalResourceAvailableHoursForDateRange({
              endDate: isoStringToObject(taskResourceUserAllocation.endDate),
              resourceUserId: newUser.id,
              startDate: isoStringToObject(taskResourceUserAllocation.startDate)
            })
          ).availableDuration;
      } else {
        const [
          projectAllocationHours,
          otherTaskAllocationHours
        ] = await Promise.all([
          getProjectAllocationHours({
            apolloClient,
            projectId,
            userId: newUser.id,
            startDate,
            endDate
          }),
          getTaskAllocationHoursForResourceUser({
            apolloClient,
            projectId,
            userId: newUser.id,
            startDate,
            endDate,
            taskAllocationId: taskResourceUserAllocation.id
          })
        ]);

        if (!isNullOrUndefined(projectAllocationHours))
          availableHours = Math.max(
            projectAllocationHours - otherTaskAllocationHours,
            0
          );
      }
      setValues({
        ...values,
        allocationMode: !availableHours
          ? CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.REMOVE_ALLOCATION
          : CHANGE_TASK_RESOURCE_USER_ALLOCATION_MODE.ALLOCATE_FULL_AMOUNT,
        newResourceUser: newUser,
        availableHours
      });
      setIsNewUserInfoLoading(false);
    },
    [
      setFieldValue,
      taskResourceUserAllocation,
      setIsNewUserInfoLoading,
      isResourceManager,
      getTotalResourceAvailableHoursForDateRange,
      setValues,
      values,
      apolloClient,
      projectId
    ]
  );

  return {
    onResourceUserChange
  };
};

export default useOnChangeHandler;
