import React from 'react';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  NullableMoneyValue,
  ConditionalWrapper
} from '~/modules/common/components';

const useStyles = makeStyles(() => ({
  infoIcon: {
    cursor: 'help'
  },
  pointerIcon: {
    cursor: 'pointer'
  },
  autoIcon: {
    cursor: 'auto'
  },
  srOnly: {
    height: '1px',
    width: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute'
  }
}));

export const ResourceEstimateCost = ({
  hours,
  cost,
  className,
  variant = 'autoIcon'
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const isNA = Boolean(hours && hours !== 0 && !cost);
  const defaultIcon =
    variant === 'pointerIcon' ? classes.pointerIcon : classes.autoIcon;
  const mouseIcon = isNA ? classes.infoIcon : defaultIcon;
  const title = intl.formatMessage({
    id: 'taskResourceEstimates.noRoleForResource'
  });

  return (
    <ConditionalWrapper
      condition={isNA}
      title={title}
      WrapperComponent={Tooltip}
    >
      <div className={classNames(className, mouseIcon)}>
        <NullableMoneyValue
          money={cost}
          className={className}
          variant="notApplicable"
        />
        {isNA && <Typography className={classes.srOnly}>{title}</Typography>}
      </div>
    </ConditionalWrapper>
  );
};

ResourceEstimateCost.propTypes = {
  hours: PropTypes.number,
  cost: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.string
};
