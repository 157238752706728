/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMeContext } from '~/modules/me/useMeContext';
import { NoDataItem } from '~/modules/common/components/NoDataItem';
import { CardLoading } from '~/modules/common/components/DetailsPage/Card';
import { CertificateIcon as LicensesCertificatesIcon } from '~/modules/common/components/Icons';
import { useCertificates } from '~/modules/resource-management/resourceUserDetails/hooks';
import { RedirectToUnhandledError } from '~/modules/common/unhandled-error';
import EditCertificate from './EditCertificate';
import AddCertificate from './AddCertificate';

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    ...theme.typography.h6,
    lineHeight: `${theme.spacing(3)}px`,
    minHeight: theme.spacing(3),
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    color: theme.palette.text.secondary
  },
  headerRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  contentRoot: {
    paddingTop: '0px',
    '&:before': {
      content: '""',
      display: 'block',
      margin: '0 auto 24px auto',
      width: '100%'
    }
  }
}));

const avatar = <LicensesCertificatesIcon />;
const title = (
  <FormattedMessage id="resourceCertificatesAndLicensesCard.title" />
);

export const CertificatesCard = ({ editable = false, userDetails }) => {};

export const Certificates = ({
  editable = false,
  userDetails,
  isResourceDetailsLoading,
  ariaAttributes = {}
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();
  const classes = useStyles();
  const cardHeaderClasses = useMemo(
    () => ({
      title: classes.title,
      avatar: classes.avatar,
      root: classes.headerRoot
    }),
    [classes.avatar, classes.headerRoot, classes.title]
  );

  const cardContentClasses = useMemo(() => ({ root: classes.contentRoot }), [
    classes.contentRoot
  ]);

  const { slug, uri } = userDetails || {};

  const {
    error: errorInLoadingCertificates,
    certificates = [],
    isCertificateLoading
  } = useCertificates({
    userSlug: slug
  });

  const { role = 'heading', ['aria-level']: ariaLevel = 2 } = ariaAttributes;

  if (errorInLoadingCertificates) return <RedirectToUnhandledError />;

  if (isCertificateLoading || isResourceDetailsLoading)
    return <CardLoading avatar={avatar} title={title} />;

  return (
    <Card>
      <CardHeader
        classes={cardHeaderClasses}
        avatar={avatar}
        title={title}
        {...(isPsaPrpWcagFixesQ4Enabled && {
          role,
          'aria-level': ariaLevel
        })}
      />
      <CardContent
        data-qe-id="CertificateContentSection"
        classes={cardContentClasses}
      >
        {certificates.length > 0 ? (
          certificates.map(certificate => (
            <EditCertificate
              key={certificate.uri}
              certificate={certificate}
              editable={editable}
              userUri={uri}
            />
          ))
        ) : (
          <NoDataItem>
            <FormattedMessage id="resourceCertificatesAndLicensesCard.noCertificates" />
          </NoDataItem>
        )}
        {editable && <AddCertificate userUri={uri} />}
      </CardContent>
    </Card>
  );
};

Certificates.propTypes = {
  editable: PropTypes.bool,
  userDetails: PropTypes.object,
  isResourceDetailsLoading: PropTypes.bool,
  ariaAttributes: PropTypes.object
};

CertificatesCard.propTypes = {
  editable: PropTypes.bool,
  userDetails: PropTypes.object
};

export default Certificates;
