import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from '~/modules/common/util';
import { NoValue } from '../NoValue';
import MoneyValue from './MoneyValue';

export const DisplayNull = ({ variant, className }) =>
  variant === 'notApplicable' ? (
    <Typography className={className}>
      <FormattedMessage id="nullableMoneyValue.na" />
    </Typography>
  ) : (
    <NoValue />
  );

export const NullableMoneyValue = ({ money = {}, className, variant }) =>
  isNullOrUndefined(money?.amount) ? (
    <DisplayNull variant={variant} className={className} />
  ) : (
    <MoneyValue money={money} className={className} />
  );

NullableMoneyValue.propTypes = {
  className: PropTypes.string,
  money: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.object
  }),
  variant: PropTypes.oneOf(['noValue', 'notApplicable'])
};

export default NullableMoneyValue;
