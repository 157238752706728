import { Button, DialogActions, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { EVENT_TYPE } from '~/modules/common/enums/EventTypes';
import LoadingButton from '../../../LoadingButton';
import { useOnSubmit } from '../hooks/useOnChangeHandlers';
import { DEFAULT_PAGE_SIZE } from '../hooks/useTaskResourceEstimates';

const getResourceKey = ({ isEditMode, isPlaceholder }) => {
  if (isEditMode) {
    return isPlaceholder
      ? 'editResourceEstimateDialog.savePlaceholder'
      : 'editResourceEstimateDialog.setResource';
  }

  return isPlaceholder
    ? 'addResourceEstimateDialog.addPlaceholder'
    : 'addResourceEstimateDialog.addResource';
};

export const InnerDialogActions = ({
  classes,
  disabled,
  loading,
  isPlaceholder,
  onClose,
  onSubmit,
  isEditMode
}) => {
  const { formatMessage } = useIntl();

  return (
    <DialogActions className={classes.actions}>
      <Button onClick={onClose} variant="contained">
        {formatMessage({ id: 'button.cancel' })}
      </Button>
      <LoadingButton
        color="primary"
        onClick={onSubmit}
        variant="contained"
        disabled={disabled}
        isLoading={loading}
      >
        {formatMessage({ id: getResourceKey({ isEditMode, isPlaceholder }) })}
      </LoadingButton>
    </DialogActions>
  );
};

InnerDialogActions.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool
};

const useStyles = makeStyles(theme => ({
  actions: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const AddResourceEstimateDialogFooter2 = ({
  projectId,
  resourceEstimates,
  canViewCost,
  taskId,
  onClose,
  setFieldValue,
  loadingContent
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    errors,
    values: {
      role,
      resource,
      initialEstimatedHours,
      startDate,
      endDate,
      isCreateTaskAllocationChecked,
      taskResourceEstimateId
    },
    dirty
  } = useFormikContext();

  const isPlaceholder = Boolean(role && !resource);

  const { onResourceEstimateSubmit } = useOnSubmit({
    taskId,
    loadTaskResourceUserAllocation: true,
    canViewCost
  });
  const isEditMode = Boolean(taskResourceEstimateId);

  const isAddResourceButtonDisabled =
    loadingContent ||
    (!role && !resource) ||
    (!dirty && isEditMode) ||
    (!isPlaceholder && Object.keys(errors).length > 0);

  const onSubmit = useCallback(async () => {
    setLoading(true);

    const resourceEstimateInput = {
      resourceUserId: resource?.id,
      projectRoleId: role?.id,
      initialEstimatedHours,
      taskResourceEstimateId
    };

    if (
      isCreateTaskAllocationChecked &&
      resource?.id &&
      initialEstimatedHours
    ) {
      resourceEstimateInput.taskResourceEstimateAllocationInput = {
        projectId,
        startDate,
        endDate,
        allocatedHours: initialEstimatedHours
      };
    }

    const {
      taskResourceEstimate,
      loading: submitLoading
    } = await onResourceEstimateSubmit(resourceEstimateInput, [
      {
        eventName: EVENT_TYPE.GANTT_TASK_RESOURCE_COUNT_UPDATE,
        payload: {
          taskId,
          updateResourceEstimatesCountBy: isEditMode ? 0 : 1,
          updateResourceEstimatesWithUsersCountBy: resource ? 1 : 0
        }
      }
    ]);

    setLoading(submitLoading);

    const updatedResourceEstimates = isEditMode
      ? resourceEstimates.map(estimate =>
          estimate?.id === taskResourceEstimate?.id
            ? {
                ...taskResourceEstimate,
                initialEstimatedHours,
                resourceUser: resource,
                projectRole: role
              }
            : estimate
        )
      : [
          {
            ...taskResourceEstimate,
            initialEstimatedHours,
            resourceUser: resource,
            projectRole: role
          },
          ...resourceEstimates
        ];

    setFieldValue(
      'resourceEstimates',
      updatedResourceEstimates?.length > DEFAULT_PAGE_SIZE
        ? updatedResourceEstimates.slice(0, DEFAULT_PAGE_SIZE)
        : updatedResourceEstimates
    );
    onClose();
  }, [
    resource,
    role,
    initialEstimatedHours,
    taskResourceEstimateId,
    isCreateTaskAllocationChecked,
    onResourceEstimateSubmit,
    taskId,
    isEditMode,
    resourceEstimates,
    setFieldValue,
    onClose,
    projectId,
    startDate,
    endDate
  ]);

  return (
    <InnerDialogActions
      classes={classes}
      disabled={isAddResourceButtonDisabled}
      loading={loading}
      isPlaceholder={isPlaceholder}
      onClose={onClose}
      onSubmit={onSubmit}
      isEditMode={isEditMode}
    />
  );
};

AddResourceEstimateDialogFooter2.propTypes = {
  projectId: PropTypes.string.isRequired,
  canViewCost: PropTypes.bool.isRequired,
  resourceEstimates: PropTypes.array.isRequired,
  taskId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  loadingContent: PropTypes.bool.isRequired
};

export default AddResourceEstimateDialogFooter2;
