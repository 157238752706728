import { useEffect, useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  useCombinedPagination,
  sortByDisplayText
} from '~/modules/resourcing/common/hooks/useCombinedPagination';
import getPageOfTaskResourceEstimateSummaryForResourceUser from '~/modules/resourcing/common/hooks/usePageOfTaskResourceEstimateSummaryForResourceUser';

export const getMappedTaskAssignmentsData = combinedPaginationData => {
  return Object.values(
    (combinedPaginationData || []).reduce((acc, taskAssignment) => {
      acc[taskAssignment.task.id] =
        acc[taskAssignment.task.id] || taskAssignment;

      return acc;
    }, {})
  );
};

export const useFetchResourceUserTaskAssignments = ({
  userId,
  projectId,
  pageSize,
  currentPage,
  setCurrentPage,
  resetResourceAllocationChart
}) => {
  const apolloClient = useApolloClient();

  const fetchAllocatedTaskAssignments = async page => {
    const {
      taskResourceEstimateSummaryForResourceUser
    } = await getPageOfTaskResourceEstimateSummaryForResourceUser({
      page: page + 1,
      pageSize,
      userId,
      projectId,
      apolloClient
    });

    return taskResourceEstimateSummaryForResourceUser;
  };

  const {
    loading,
    data,
    setData,
    getNext,
    refetch,
    reset
  } = useCombinedPagination({
    getters: [fetchAllocatedTaskAssignments],
    sort: sortByDisplayText
  });

  const userTaskAssignmentsData = useMemo(() => {
    return loading && currentPage === 1
      ? {}
      : getMappedTaskAssignmentsData(data);
  }, [loading, currentPage, data]);

  useEffect(() => {
    if (
      !loading &&
      Object.keys(userTaskAssignmentsData).length < pageSize * currentPage
    ) {
      getNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleRemoveTaskAssignment = useCallback(
    ({ taskId }) => {
      const updatedData = userTaskAssignmentsData.filter(
        taskAssignment => taskAssignment.task.id !== taskId
      );

      setData(updatedData);

      if (updatedData.length === 0 && resetResourceAllocationChart) {
        resetResourceAllocationChart();
      }
    },
    [userTaskAssignmentsData, setData]
  );

  const handleUpdateTaskAssignment = useCallback(() => {
    if (currentPage === 1) {
      refetch();
    } else {
      reset();
      setCurrentPage(1);
    }
  }, [currentPage, setCurrentPage, refetch, reset]);

  return {
    loading,
    userTaskAssignmentsData,
    handleRemoveTaskAssignment,
    handleUpdateTaskAssignment
  };
};

export default useFetchResourceUserTaskAssignments;
