import { PropTypes } from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Divider } from '@material-ui/core';
import { NoDataItem } from '~/modules/common/components';
import PageOutOfBoundsMessage from '~/modules/common/components/ListTable/PageOutOfBoundsMessage';
import TaskEstimateRow from './TaskEstimateRow';
import TaskResourceEstimatesRowSkeleton from './TaskResourceEstimatesRowSkeleton';

const PAGE_SIZE = 10;

const useDividerStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 2)
  }
}));

const useRowStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 2),
    minHeight: theme.spacing(5)
  }
}));

const usePageBoundaryMessageStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1, 2.5)
  }
}));

export const TaskResourceEstimatesContent = ({
  loading = false,
  task,
  currentPage,
  setCurrentPage,
  onRemoveResourceEstimateSuccess
}) => {
  const dividerClasses = useDividerStyles();
  const rowClasses = useRowStyles();
  const pageBoundaryMessageClasses = usePageBoundaryMessageStyles();

  const {
    values: { resourceEstimates }
  } = useFormikContext();

  const assignmentCount = resourceEstimates?.length || 0;

  return (
    <div className={rowClasses.container}>
      <Divider className={dividerClasses.root} />
      {!loading ? (
        assignmentCount === 0 && currentPage > 1 ? (
          <PageOutOfBoundsMessage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            classes={pageBoundaryMessageClasses}
          />
        ) : assignmentCount === 0 ? (
          <NoDataItem>
            <FormattedMessage id="taskResourceEstimates.noResourceAssignments" />
          </NoDataItem>
        ) : (
          resourceEstimates?.map((resourceEstimate, index) => (
            <React.Fragment key={resourceEstimate.id}>
              {resourceEstimate.loading ? (
                <TaskResourceEstimatesRowSkeleton />
              ) : (
                <TaskEstimateRow
                  resourceEstimate={resourceEstimate}
                  task={task}
                  rowIndex={index}
                  onRemoveResourceEstimateSuccess={
                    onRemoveResourceEstimateSuccess
                  }
                />
              )}

              <Divider className={dividerClasses.root} />
            </React.Fragment>
          ))
        )
      ) : (
        [...Array(assignmentCount || PAGE_SIZE)].map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <TaskResourceEstimatesRowSkeleton />
            <Divider className={dividerClasses.root} />
          </React.Fragment>
        ))
      )}
    </div>
  );
};

TaskResourceEstimatesContent.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  onRemoveResourceEstimateSuccess: PropTypes.func
};

export default TaskResourceEstimatesContent;
