import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useProposeTopMatch } from '~/modules/resourcing/common/hooks';
import { UserAvatar } from '~/modules/common/components/Avatars';
import AssignResourceButton from '~/modules/resourcing/common/components/AssignResourceButton';
import { DelaStarIcon } from '~/modules/common/components';
import useStyles from './useStyles';
import useTooltipStyles from './useTooltipStyles';

export const ResourceAllocationMatchItem = ({
  onAssignResourceClick,
  setAnchorEl,
  setPopoverResourceRequest,
  resourceRequest
}) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { formatMessage } = useIntl();
  const { id, requestStatus, resourceMatches } = resourceRequest;

  const [resourceMatch] = resourceMatches || [];

  const { proposeTopMatch } = useProposeTopMatch();

  const handlePropose = useCallback(
    event => {
      proposeTopMatch({ resourceRequestUris: [id] });
    },
    [proposeTopMatch, id]
  );

  const handlePopover = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      setPopoverResourceRequest(resourceRequest);
    },
    [setAnchorEl, setPopoverResourceRequest, resourceRequest]
  );

  return resourceMatch ? (
    <div className={classes.root}>
      <UserAvatar user={resourceMatch} size="small" />
      <div className={classes.resourceMatchTextColumn}>
        <div className={classes.resourceMatchDisplayText}>
          {resourceMatch.displayText}
        </div>
        <ButtonBase onClick={handlePopover}>
          <div className={classes.resourceMatchAlternativeText}>
            <FormattedMessage id="resourceAllocationItem.findAlternative" />
          </div>
        </ButtonBase>
      </div>
      <DelaStarIcon className={classes.delaStarIcon} />
      <ButtonBase onClick={handlePropose}>
        <Tooltip
          classes={tooltipClasses}
          placement="bottom-start"
          title={formatMessage({
            id: 'resourceAllocationItem.proposeResource'
          })}
        >
          <CheckCircle className={classes.checkCircleIcon} />
        </Tooltip>
      </ButtonBase>
    </div>
  ) : (
    <div className={classes.allocation}>
      <AssignResourceButton
        onClick={onAssignResourceClick}
        resourceRequestStatus={requestStatus}
      />
      <div className={classes.toBeHired}>
        <FormattedMessage id="resourceAllocationItem.toBeHired" />
      </div>
    </div>
  );
};

ResourceAllocationMatchItem.propTypes = {
  onAssignResourceClick: PropTypes.func.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired,
  resourceRequest: PropTypes.object.isRequired
};

export default ResourceAllocationMatchItem;
