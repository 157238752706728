import React from 'react';
import { Grid, Radio, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DateField } from '~/modules/common/components';
import { CHANGE_RESOURCE_ALLOCATION_TYPE } from '../enum';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(2)
  },
  asOfSection: {
    flexGrow: 0,
    maxWidth: '53%',
    flexBasis: '53%',
    paddingLeft: `${theme.spacing(0)}px !important`,
    marginLeft: theme.spacing(-0.25)
  },
  changeEntireSection: {
    flexGrow: 0,
    maxWidth: '47%',
    flexBasis: '47%'
  },
  root: {
    width: '75%',
    '&.MuiFormControl-root': {
      width: '75%'
    }
  }
}));

export const ChangeResourceAllocationRangeOptions = ({
  asOfDate,
  changeType,
  onChangeTypeSelect,
  onAsOfDateChange
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid item container className={classes.container}>
      <Grid item className={classes.asOfSection}>
        <Radio
          checked={changeType === CHANGE_RESOURCE_ALLOCATION_TYPE.AsOf}
          color="primary"
          name="changeType"
          onChange={onChangeTypeSelect}
          value={CHANGE_RESOURCE_ALLOCATION_TYPE.AsOf}
        />
        <DateField
          clearable={false}
          editable
          fullWidth={false}
          label={intl.formatMessage({
            id: 'changeResourceDialog.changeAsOf'
          })}
          onChange={onAsOfDateChange}
          value={asOfDate}
          variant="filled"
        />
      </Grid>
      <Grid item className={classes.changeEntireSection}>
        <Radio
          checked={changeType === CHANGE_RESOURCE_ALLOCATION_TYPE.Entirely}
          color="primary"
          name="changeType"
          onChange={onChangeTypeSelect}
          value={CHANGE_RESOURCE_ALLOCATION_TYPE.Entirely}
        />
        <FormattedMessage id="changeResourceDialog.changeEntirely" />
      </Grid>
    </Grid>
  );
};

ChangeResourceAllocationRangeOptions.propTypes = {
  asOfDate: PropTypes.string.isRequired,
  changeType: PropTypes.string.isRequired,
  onAsOfDateChange: PropTypes.func.isRequired,
  onChangeTypeSelect: PropTypes.func.isRequired
};

export default ChangeResourceAllocationRangeOptions;
