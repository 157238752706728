import { PROJECT_PERMISSION } from '~/modules/common/enums';

export const hasPermittedAction = (
  permittedActionUris = []
) => permittedActionUri => permittedActionUris.includes(permittedActionUri);

export const useProjectObjectPermissions = input => {
  const hasAction = hasPermittedAction(
    input?.project?.permittedActionUris || []
  );

  return {
    canViewEstimate: hasAction(PROJECT_PERMISSION.VIEW_PROJECT_ESTIMATES),
    canEditEstimate: hasAction(PROJECT_PERMISSION.EDIT_PROJECT_ESTIMATES),
    canViewCost: hasAction(PROJECT_PERMISSION.VIEW_COST_DATA),
    canEditCost: hasAction(PROJECT_PERMISSION.EDIT_COST_DATA),
    canEditClient: hasAction(PROJECT_PERMISSION.EDIT_CLIENT),
    canViewClient: hasAction(PROJECT_PERMISSION.VIEW_CLIENT),
    canEditProgram: hasAction(PROJECT_PERMISSION.EDIT_PROGRAM),
    canViewProgram: hasAction(PROJECT_PERMISSION.VIEW_PROGRAM),
    canViewBillingContracts: hasAction(
      PROJECT_PERMISSION.VIEW_BILLING_CONTRACTS
    ),
    canViewTasks: hasAction(PROJECT_PERMISSION.VIEW_TASK),
    canEditTasks: hasAction(PROJECT_PERMISSION.EDIT_TASK),
    canViewTeam: hasAction(PROJECT_PERMISSION.VIEW_TEAM),
    canEditTeam: hasAction(PROJECT_PERMISSION.EDIT_TEAM),
    canEditBillingRates: hasAction(PROJECT_PERMISSION.EDIT_BILLING_RATES),
    canEditExpenseCodes: hasAction(PROJECT_PERMISSION.EDIT_EXPENSE_CODES),
    canEditBillingContracts: hasAction(
      PROJECT_PERMISSION.EDIT_BILLING_CONTRACTS
    ),
    canViewResourceTaskAllocations: hasAction(
      PROJECT_PERMISSION.VIEW_RESOURCE_TASK_ALLOCATIONS
    ),
    canEditResourceTaskAllocations: hasAction(
      PROJECT_PERMISSION.EDIT_RESOURCE_TASK_ALLOCATIONS
    ),
    canEditRevenueContract: hasAction(PROJECT_PERMISSION.EDIT_REVENUE_CONTRACT)
  };
};

export default useProjectObjectPermissions;
