import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { buildResourceAllocation } from '~/modules/resourcing/hooks/useCreateResourceAllocationHandler';
import { ResourceAllocationStatus } from '~/types';
import { updateCreateAllocationCache } from '~/modules/quickAllocation/components/QuickAllocationUserChart/hooks/useFulfilledResourceAllocationHandler';
import { useFulfilledResourceAllocationHandlersWithoutResourceRequest } from '~/modules/resourcing/common/hooks';
import mergePeriodRulesIntoScheduleRules from '~/modules/resourcing/common/util/mergeScheduleUtil';
import useQuickAllocationContext from './useQuickAllocationContext';
import useResourceAllocationUpdateHandler from './useResourceAllocationUpdateHandler';

export const updateCache = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort: [userSort],
  periodResolution,
  scale,
  me,
  filter,
  projectStatusFilterOption,
  handleAddResourceAllocation,
  onPopupMenuClose,
  setSnackBarState,
  formatMessage,
  isPsaRmpTaskAllocation1Enabled
}) => (proxy, mutationResponse) => {
  const {
    data: {
      createFulfilledResourceAllocationWithoutResourceRequest: {
        resourceAllocation
      }
    }
  } = mutationResponse;

  onPopupMenuClose();
  setSnackBarState({
    open: true,
    message: formatMessage(
      {
        id: isPsaRmpTaskAllocation1Enabled
          ? 'addallocationSnackBar.allocatedMessage'
          : 'addallocationSnackBar.addallocation'
      },
      {
        username: resourceAllocation.user.user.displayText,
        projectname: resourceAllocation.project.displayText,
        role:
          resourceAllocation.role?.displayText ||
          formatMessage({
            id: 'addallocationSnackBar.noRole'
          })
      }
    )
  });
  handleAddResourceAllocation({ allocation: resourceAllocation });

  updateCreateAllocationCache({
    resourceUsersCurrentPage,
    dateRange,
    userSort: [userSort],
    periodResolution,
    scale,
    me,
    filter,
    projectStatusFilterOption
  })(proxy, mutationResponse);
};

export const useCreateResourceAllocation = ({
  me,
  user,
  handleAddResourceAllocation,
  onPopupMenuClose,
  setSnackBarState,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const {
    scale,
    projectStatusFilterOption,
    resourceUsersCurrentPage,
    periodResolution,
    dateRange,
    filter,
    sort: userSort
  } = useQuickAllocationContext();
  const { formatMessage } = useIntl();
  const {
    onCreateResourceAllocation,
    loading
  } = useFulfilledResourceAllocationHandlersWithoutResourceRequest({
    updateCreateCache: updateCache({
      resourceUsersCurrentPage,
      dateRange,
      userSort: [userSort],
      periodResolution,
      scale,
      me,
      filter,
      projectStatusFilterOption,
      handleAddResourceAllocation,
      onPopupMenuClose,
      setSnackBarState,
      formatMessage,
      isPsaRmpTaskAllocation1Enabled
    })
  });

  const {
    onUpdateResourceAllocation,
    loading: updateLoading
  } = useResourceAllocationUpdateHandler();

  const createResourceAllocation = useCallback(
    ({ allocation, overrideScheduleRules, existingResourceAllocation }) => {
      const existingResourceAllocationScheduleRules =
        existingResourceAllocation?.scheduleRules;

      if (
        isPsaRmpTaskAllocation1Enabled &&
        existingResourceAllocationScheduleRules
      ) {
        const mergedAllocations = mergePeriodRulesIntoScheduleRules(
          existingResourceAllocationScheduleRules
        )(overrideScheduleRules);

        onUpdateResourceAllocation({
          allocation: {
            ...existingResourceAllocation,
            scheduleRules: mergedAllocations
          },
          existingResourceAllocation,
          projectName: allocation.project.displayText,
          onPopupMenuClose,
          setSnackBarState,
          formatMessage,
          handleAddResourceAllocation
        });
      } else {
        const alloc = buildResourceAllocation({
          user,
          scheduleRules: overrideScheduleRules,
          projectUri: allocation.project.id,
          roleUri: allocation.roleUri,
          me,
          allocationStatus: ResourceAllocationStatus.Committed
        });

        onCreateResourceAllocation({ allocation: alloc });
      }
    },
    [
      formatMessage,
      handleAddResourceAllocation,
      isPsaRmpTaskAllocation1Enabled,
      me,
      onCreateResourceAllocation,
      onPopupMenuClose,
      onUpdateResourceAllocation,
      setSnackBarState,
      user
    ]
  );

  return {
    createResourceAllocation,
    loading: loading || updateLoading
  };
};

export default useCreateResourceAllocation;
