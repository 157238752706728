import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alternative: {
    marginTop: theme.spacing(1.75)
  },
  uppercaseText: {
    textTransform: 'uppercase',
    color: theme.palette.text.primary
  }
}));

export const ResourceMatchCancelLink = ({ onClose }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div data-qe-id="ResourceMatchCancelLink" className={classes.alternative}>
      <ButtonBase className={classes.uppercaseText} onClick={onClose}>
        <Typography variant="body1">
          {formatMessage({
            id: 'resourceMatchPopover.cancel'
          })}
        </Typography>
      </ButtonBase>
    </div>
  );
};

ResourceMatchCancelLink.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ResourceMatchCancelLink;
