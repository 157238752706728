import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import Percentage from '../Percentage';
import useStyles from './useStyles';

export const PercentProgressBar = ({
  classes: classesOverride,
  percentage,
  precision = 0,
  showPercentage,
  dataQeId
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const classes = useStyles({ classes: classesOverride, percentage });

  const linearProgressClasses = useMemo(
    () => ({
      root: classes.linearProgressRoot,
      bar: classes.linearProgressBar
    }),
    [classes.linearProgressRoot, classes.linearProgressBar]
  );

  const boundPercentage = Math.min(100, percentage);

  return (
    <div className={classes.root}>
      <LinearProgress
        classes={linearProgressClasses}
        value={boundPercentage}
        variant="determinate"
        {...(isPsaPrpWcagFixesQ4Enabled && showPercentage
          ? dataQeId && {
              'aria-labelledby': `${dataQeId}_Percentage`
            }
          : {
              'aria-label': percentage
            })}
      />
      {showPercentage && (
        <Percentage
          id={
            isPsaPrpWcagFixesQ4Enabled && dataQeId && `${dataQeId}_Percentage`
          }
          className={classes.percentage}
          value={percentage}
          precision={precision}
        />
      )}
    </div>
  );
};

PercentProgressBar.propTypes = {
  classes: PropTypes.object,
  percentage: PropTypes.number.isRequired,
  precision: PropTypes.number,
  showPercentage: PropTypes.bool,
  dataQeId: PropTypes.string
};

export default PercentProgressBar;
