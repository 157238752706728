import { makeStyles, Tooltip } from '@material-ui/core';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Decimal } from '~/modules/common/components/Decimal/Decimal';
import { isNullOrUndefined } from '~/modules/common/util';
import { useIsBudgetExceeded } from './hooks';
import ProgressItemError from './ProgressItemError';
import ProgressItemDetailLoading from './ProgressItemDetailLoading';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.625)
    }
  },
  help: {
    '&:hover': {
      cursor: 'help'
    }
  },
  delimiter: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  warning: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(2)
  },
  message: {
    fontSize: theme.typography.caption,
    color: theme.palette.text.secondary
  },
  details: {
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.text.primary
  },
  budgetExceeded: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  }
}));

export const ProgressItemMetricDetailInternal = ({
  message,
  id,
  denominator,
  numerator,
  fixedDecimalScale,
  suffix,
  prefix
}) => {
  const classes = useStyles();

  const numTooltipContent = useMemo(
    () =>
      !isNullOrUndefined(numerator) && (
        <Decimal value={numerator} suffix={suffix} prefix={prefix} />
      ),
    [numerator, prefix, suffix]
  );

  const denomTooltipContent = useMemo(
    () =>
      !isNullOrUndefined(denominator) && (
        <Decimal value={denominator} suffix={suffix} prefix={prefix} />
      ),
    [denominator, prefix, suffix]
  );

  const messageTooltipContent = useMemo(
    () => <FormattedMessage id={message} />,
    [message]
  );

  const isBudgetExceeded = useIsBudgetExceeded({
    actuals: numerator,
    budget: denominator
  });

  return (
    <div className={classes.rowItems}>
      {!isNullOrUndefined(numerator) && !isNullOrUndefined(denominator) ? (
        <>
          <Tooltip title={numTooltipContent}>
            <span>
              <Decimal
                value={numerator}
                className={classNames(classes.details, {
                  [classes.budgetExceeded]: isBudgetExceeded
                })}
                fixedDecimalScale={fixedDecimalScale}
                suffix={suffix}
                prefix={prefix}
              />
            </span>
          </Tooltip>
          <span className={classes.delimiter}>/</span>
          <Tooltip title={denomTooltipContent}>
            <span>
              <Decimal
                value={denominator}
                className={classes.details}
                fixedDecimalScale={fixedDecimalScale}
                suffix={suffix}
                prefix={prefix}
              />
            </span>
          </Tooltip>
        </>
      ) : (
        <Tooltip title={messageTooltipContent}>
          <span className={classes.rowItems}>
            <WarningSharpIcon className={classes.warning} />
            <span className={classNames(classes.details, classes.help)}>
              <FormattedMessage id={id} />
            </span>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

ProgressItemMetricDetailInternal.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  denominator: PropTypes.number,
  numerator: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string
};

export const ProgressItemMetricDetail = ({
  message,
  id,
  denominator,
  numerator,
  fixedDecimalScale,
  suffix,
  prefix,
  isLoading = false,
  error
}) => {
  const classes = useStyles();

  return isLoading || error ? (
    <div className={classes.rowItems}>
      {isLoading && <ProgressItemDetailLoading />}
      {error && <ProgressItemError />}
    </div>
  ) : (
    <ProgressItemMetricDetailInternal
      message={message}
      id={id}
      denominator={denominator}
      numerator={numerator}
      fixedDecimalScale={fixedDecimalScale}
      suffix={suffix}
      prefix={prefix}
    />
  );
};

ProgressItemMetricDetail.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  denominator: PropTypes.number,
  numerator: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

export default ProgressItemMetricDetail;
