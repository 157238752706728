import { makeStyles } from '@material-ui/core';

export const useListTableThemeProviderStyles = makeStyles(theme => ({
  tableRoot: {
    whiteSpace: 'nowrap',
    paddingTop: ({ hasAdhocLineItems }) => (hasAdhocLineItems ? '10px' : '0px'),
    borderCollapse: 'separate',
    '& th:last-child': {
      minWidth: theme.spacing(31.25),
      paddingRight: theme.spacing(1.25),
      zIndex: 1,
      borderLeft: `1px solid ${theme.palette.grey[200]}`
    },
    '& th:nth-last-child(2)': {
      paddingLeft: ({ editable }) =>
        editable ? theme.spacing(0.5) : theme.spacing(1)
    },
    '& th:first-child': {
      left: 'unset',
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      zIndex: 3
    },
    '& tbody td:last-child': {
      paddingRight: ({ editable }) =>
        editable ? theme.spacing(0) : theme.spacing(1.25)
    },
    '& tbody td:nth-last-child(2)': {
      paddingLeft: ({ editable }) =>
        editable ? theme.spacing(0.5) : theme.spacing(1)
    },
    '& tbody td:first-child': {
      borderRight: `1px solid ${theme.palette.grey[200]}`
    },
    '& tfoot td:first-child': {
      left: 0,
      zIndex: 3,
      borderRight: `1px solid ${theme.palette.grey[200]}`
    },
    '& tbody td:nth-last-child(1)': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(0)
      }
    },
    '& td:last-child': {
      paddingRight: theme.spacing(1) + 3,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      flip: false
    }
  },
  tableHeaderCell: {
    paddingLeft: theme.spacing(2),
    verticalAlign: 'bottom',
    whiteSpace: 'normal',
    height: 40,
    zIndex: 1
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0, 2),
    zIndex: 1
  },
  tableFooterCell: {
    height: 20,
    fontWeight: theme.typography.fontWeightRegular,
    '& td:last-child': {
      paddingRight: theme.spacing(1) + 2
    }
  },
  tableFooterRow: {
    backgroundColor: theme.palette.grey[50]
  }
}));

export const useColumnStyles = makeStyles(theme => ({
  deleteIcon: {
    width: theme.spacing(7)
  },
  text: {
    width: theme.spacing(16),
    paddingRight: theme.spacing(0.5)
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.table.border}`
  },
  amount: {
    paddingLeft: theme.spacing(0.5),
    width: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(25)
    }
  },
  totalAmount: {},
  displayText: {
    borderRight: `1px solid ${theme.palette.grey[200]}`
  },
  billingType: { whiteSpace: 'nowrap' },
  user: {
    '& span': {
      whiteSpace: 'break-spaces'
    }
  },
  rate: {
    paddingRight: theme.spacing(2.85),
    whiteSpace: 'nowrap'
  },
  quantity: {
    paddingRight: theme.spacing(2.85),
    whiteSpace: 'nowrap'
  },
  task: {
    overflowWrap: 'break-word'
  },
  project: {
    overflowWrap: 'break-word'
  },
  description: {
    overflowWrap: 'break-word'
  },
  subTotal: {
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const useTableStyles = makeStyles({
  card: {
    boxShadow: 'none',
    overflowX: 'auto'
  }
});

export const useNoDataStyles = makeStyles(theme => ({
  noData: {
    padding: theme.spacing(0, 0, 0, 1)
  }
}));
