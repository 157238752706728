import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMeContext } from '~/modules/me';
import { TaskEstimateRowContext } from './TaskEstimateRowContext';

const TaskEstimateRowContextProvider = ({
  canEditTasks,
  canViewCost,
  dialogProps,
  resourceEstimate,
  task,
  children
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const value = useMemo(
    () => ({
      canViewCost: Boolean(canViewCost) && isRmpTaskAllocationPhase2Enabled,
      canEditTasks,
      dialogProps,
      resourceEstimate,
      task
    }),
    [
      canEditTasks,
      canViewCost,
      dialogProps,
      isRmpTaskAllocationPhase2Enabled,
      resourceEstimate,
      task
    ]
  );

  return (
    <TaskEstimateRowContext.Provider value={value}>
      {children}
    </TaskEstimateRowContext.Provider>
  );
};

TaskEstimateRowContextProvider.propTypes = {
  children: PropTypes.node,
  canEditTasks: PropTypes.bool,
  canViewCost: PropTypes.bool,
  dialogProps: PropTypes.object,
  resourceEstimate: PropTypes.object,
  task: PropTypes.object
};

export default TaskEstimateRowContextProvider;
