import { DateTime } from 'luxon';
import flatMap from 'lodash/fp/flatMap';
import { grey, common } from '@material-ui/core/colors';
import isEqual from 'lodash.isequal';
import { useIntl } from 'react-intl';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { ResourceAllocationStatus } from '~/types';
import {
  convertDurationToHours,
  roundNumberWithHighPrecision
} from '~/modules/common/util';
import {
  isoStringToObjectWithCache as isoStringToObject,
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject
} from '~/modules/common/dates/convert';
import { getWorkScheduleFromScheduleRule } from '~/modules/resourcing/common/util/scheduleUtil';

export const TIME_OFF_MEASUREMENT_UNIT_TYPE = {
  HOURS: 'HOURS',
  DAYS: 'DAYS'
};

export const startDateInISODateFormat = dateString => {
  const d = isoStringToObject(dateString);

  return d ? DateTime.fromObject(d).toISODate() : null;
};

export const endDateISODateFormat = dateString => {
  const date = mapIsoStringtoUtcObject(dateString);
  const shimmedDate = date.plus({ days: 1 });

  return shimmedDate.toISODate();
};

const getAllocationColor = status => {
  switch (status) {
    case ResourceAllocationStatus.Draft:
      return 'pending';
    case ResourceAllocationStatus.Proposed:
      return 'proposed';
    case ResourceAllocationStatus.Committed:
      return 'complete';
    case ResourceAllocationStatus.Rejected:
      return 'resourceRejected';
    default:
      return '';
  }
};

export const mapAllocation = allocation =>
  allocation.scheduleRules
    .filter(schedule => schedule.do.setHours > 0)
    .flatMap(schedule => {
      const workSchedules = getWorkScheduleFromScheduleRule({
        start: schedule.dateRange.startDate,
        end: schedule.dateRange.endDate,
        excludeWeekdays: schedule.do.excludeWeekdays
      });

      return workSchedules.map(workSchedule => ({
        start: workSchedule.startDate
          ? startDateInISODateFormat(workSchedule.startDate)
          : null,
        end: workSchedule.endDate
          ? endDateISODateFormat(workSchedule.endDate)
          : null,
        title: allocation.project ? allocation.project.displayText : null,
        color:
          theme.palette.resourceRequest[
            getAllocationColor(allocation.allocationStatus)
          ].main,
        allocationStatus: allocation.allocationStatus,
        textColor: theme.palette.getContrastText(
          theme.palette.resourceRequest[
            getAllocationColor(allocation.allocationStatus)
          ].main
        )
      }));
    });

export const mapAllocationEvents = ({ allocations = [] }) =>
  flatMap(allocation => mapAllocation(allocation))(allocations);

export const mapHoliday = holiday => ({
  start: mapRepliconDateToUtcObject(holiday.date).toISODate(),
  end: mapRepliconDateToUtcObject(holiday.date).toISODate(),
  title: holiday.name,
  color: grey[600],
  textColor: common.white
});

export const mapHolidayWithDuration = (holiday, formatMessage) => ({
  start: mapRepliconDateToUtcObject(holiday.date).toISODate(),
  end: mapRepliconDateToUtcObject(holiday.date).toISODate(),
  effectiveDuration: `${roundNumberWithHighPrecision(
    convertDurationToHours(holiday.effectiveDuration)
  )} ${formatMessage({ id: 'calendar.hours' })}`,
  title: holiday.name,
  color: grey[600],
  textColor: common.white
});

export const mapHolidayEvents = ({ holidays }) =>
  flatMap(holiday => mapHoliday(holiday))(holidays || []);

export const mapHolidayEventsWithDuration = ({ holidays, formatMessage }) =>
  flatMap(holiday => mapHolidayWithDuration(holiday, formatMessage))(
    holidays || []
  );

export const mapTimeoff = (timeoff, formatMessage) => {
  const eventClasses = [];
  const totalHours = (timeoff.entries || []).reduce(
    (total, current) => total + current.hours,
    0
  );

  const units = timeoff.units.includes(TIME_OFF_MEASUREMENT_UNIT_TYPE.DAYS)
    ? timeoff.days
    : parseFloat(totalHours.toFixed(2));

  (timeoff.entries || []).forEach(timeoffEntry => {
    if (isEqual(timeoffEntry.date, timeoff.dateRange.startDate)) {
      timeoffEntry.days === 1 ? null : eventClasses.push('fc-half-days');
    } else if (isEqual(timeoffEntry.date, timeoff.dateRange.endDate)) {
      timeoffEntry.days === 1 ? null : eventClasses.push('fc-half-day');
    }
  });

  return {
    start: mapRepliconDateToUtcObject(timeoff.dateRange.startDate).toISODate(),
    end: mapRepliconDateToUtcObject(timeoff.dateRange.endDate)
      .plus({ days: 1 })
      .toISODate(),
    title: timeoff.timeOffType.displayText,
    color: grey[700],
    textColor: common.white,
    classNames: eventClasses,
    description:
      units === 0
        ? timeoff.timeOffType.displayText
        : `${timeoff.timeOffType.displayText}: ${units} ${formatMessage({
            id:
              units > 1
                ? timeoff.units.includes(TIME_OFF_MEASUREMENT_UNIT_TYPE.HOURS)
                  ? 'calendar.hours'
                  : 'calendar.days'
                : timeoff.units.includes(TIME_OFF_MEASUREMENT_UNIT_TYPE.HOURS)
                ? 'calendar.hour'
                : 'calendar.day'
          })}`
  };
};

export const mapTimeoffEvents = ({ timeoffs = [], formatMessage }) =>
  flatMap(timeoff => mapTimeoff(timeoff, formatMessage))(timeoffs);

export const useEvents = props => {
  const { formatMessage } = useIntl();
  const allocationEvents = mapAllocationEvents(props);
  const timeoffEvents = mapTimeoffEvents({ ...props, formatMessage });
  const holidayEvents = mapHolidayEventsWithDuration({
    holidays: props.holidays,
    formatMessage
  });

  const mergedEvents = allocationEvents.concat(timeoffEvents) || [];

  return {
    events: mergedEvents,
    holidayEvents
  };
};

export default useEvents;
