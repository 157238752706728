import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DelaStarIcon } from '~/modules/common/components';

const useStyles = makeStyles(theme => ({
  subHeader: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  delaStarIcon: {
    fill: theme.palette.Dela.darkIcon,
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(2)
  }
}));

export const ResourceMatchPopoverHeader = ({ resourceRequest }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography data-qe-id="ResourceMatchPopover_Role" variant="h6">
        {resourceRequest.role?.displayText ||
          formatMessage({
            id: 'resourceMatchPopover.noRole'
          })}
      </Typography>
      <div className={classes.subHeader}>
        <DelaStarIcon
          data-qe-id="ResourceMatchPopover_DelaStarIcon"
          className={classes.delaStarIcon}
        />
        <Typography
          data-qe-id="ResourceMatchPopover_Suggestions"
          variant="subtitle1"
        >
          {formatMessage({
            id: 'resourceMatchPopover.delaTopSuggestions'
          })}
        </Typography>
      </div>
    </>
  );
};

ResourceMatchPopoverHeader.propTypes = {
  resourceRequest: PropTypes.object.isRequired
};

export default ResourceMatchPopoverHeader;
