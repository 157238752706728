import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from '@material-ui/lab';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  loading: {
    marginBottom: theme.spacing(-3)
  },
  alert: {
    marginTop: theme.spacing(2)
  },
  ul: {
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(0.5, 0)
  }
}));

const boldText = chunks => <strong>{chunks}</strong>;

export const getTextInBold = values => ({
  ...(values || {}),
  b: boldText
});

export const ImpactedTaskSummaryAlert = ({
  loading,
  messages,
  severity,
  title
}) => {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <Skeleton height={100} width="100%" className={classes.loading} />
      ) : (
        <Alert severity={severity} icon={false} className={classes.alert}>
          <Typography variant="body1">
            <FormattedMessage
              id={title.messageId}
              values={getTextInBold(title?.values)}
            />
          </Typography>
          <ul className={classes.ul}>
            {messages.map(({ messageId, values }) => (
              <li key={messageId}>
                <Typography variant="body2">
                  <FormattedMessage
                    id={messageId}
                    values={getTextInBold(values)}
                  />
                </Typography>
              </li>
            ))}
          </ul>
        </Alert>
      )}
    </>
  );
};

ImpactedTaskSummaryAlert.propTypes = {
  loading: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
  severity: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired
};

export default ImpactedTaskSummaryAlert;
