import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { TaskAllocationEditorContext } from './TaskAllocationEditorContext';

const TaskAllocationEditorContextProvider = ({ children }) => {
  const taskAllocationHoursInputRef = useRef(null);
  const value = useMemo(
    () => ({
      taskAllocationHoursInputRef
    }),
    [taskAllocationHoursInputRef]
  );

  return (
    <TaskAllocationEditorContext.Provider value={value}>
      {children}
    </TaskAllocationEditorContext.Provider>
  );
};

TaskAllocationEditorContextProvider.propTypes = {
  children: PropTypes.node
};

export default TaskAllocationEditorContextProvider;
