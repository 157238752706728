import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { TaskAllocationEditor } from '../ResourceAllocationPeriodEditPopover/components/ResourceAllocationEditor/TaskAllocationEditor';

const defaultOffSet = 80;
const paperProps = {
  elevation: 2
};

const TaskAllocationPeriodEditPopover = ({
  allocatedHours = 0,
  anchorEl,
  handleAllocationPeriodClose,
  isLoading,
  periodDetails,
  scheduledHours = 0,
  setNextPeriod,
  setPreviousPeriod,
  taskResourceUserAllocation,
  onAllocationChange,
  fetchRemainingAndAvailableValues,
  initialProjectAllocationHours
}) => {
  const handlePeriodUpdate = useCallback(
    event => {
      handleAllocationPeriodClose(periodDetails, event);
    },
    [handleAllocationPeriodClose, periodDetails]
  );

  const style = anchorEl
    ? {
        minWidth: anchorEl.offsetWidth,
        minHeight: anchorEl.offsetHeight,
        width: 'min-content'
      }
    : null;

  const anchorProps = useMemo(() => {
    const anchorRight =
      mapIsoStringtoUtcObject(taskResourceUserAllocation.startDate) >
      periodDetails.startDate;
    const anchorLeft =
      mapIsoStringtoUtcObject(taskResourceUserAllocation.endDate) <
      periodDetails.endDate;

    return {
      vertical: 'top',
      horizontal: anchorRight ? 'right' : anchorLeft ? 'left' : 'center'
    };
  }, [periodDetails, taskResourceUserAllocation]);

  return (
    <Popover
      id="task-allocation-period-editor"
      anchorEl={anchorEl}
      anchorOrigin={anchorProps}
      transformOrigin={anchorProps}
      open={Boolean(anchorEl)}
      onClose={handleAllocationPeriodClose}
      PaperProps={paperProps}
    >
      <div style={style}>
        <TaskAllocationEditor
          handlePeriodUpdate={handlePeriodUpdate}
          handlePreviousPeriod={setPreviousPeriod}
          handleNextPeriod={setNextPeriod}
          key={periodDetails.startDate.toISO()}
          allocatedHours={allocatedHours}
          targetWidth={anchorEl ? anchorEl.offsetWidth : defaultOffSet}
          targetHeight={anchorEl ? anchorEl.offsetHeight : defaultOffSet}
          scheduledHours={scheduledHours}
          isLoading={isLoading}
          onAllocationChange={onAllocationChange}
          fetchRemainingAndAvailableValues={fetchRemainingAndAvailableValues}
          initialProjectAllocationHours={initialProjectAllocationHours}
        />
      </div>
    </Popover>
  );
};

TaskAllocationPeriodEditPopover.propTypes = {
  allocatedHours: PropTypes.number,
  anchorEl: PropTypes.object,
  handleAllocationPeriodClose: PropTypes.func,
  isLoading: PropTypes.bool,
  periodDetails: PropTypes.object,
  scheduledHours: PropTypes.number,
  setNextPeriod: PropTypes.func,
  setPreviousPeriod: PropTypes.func,
  taskResourceUserAllocation: PropTypes.object,
  onAllocationChange: PropTypes.func,
  fetchRemainingAndAvailableValues: PropTypes.func,
  initialProjectAllocationHours: PropTypes.number
};

export default TaskAllocationPeriodEditPopover;
