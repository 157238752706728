import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.dtm.main,
    borderRadius: theme.spacing(0.5),
    textAlign: 'center'
  },
  text: {
    ...theme.typography.caption,
    color: theme.palette.dtm.text,
    padding: theme.spacing(0.5, 1),
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export const HiringBadge = ({
  classes: classesOverrides,
  requisitionHiringStatus
}) => {
  const classes = useStyles({ classes: classesOverrides });
  const values = useMemo(() => ({ hiringStatus: requisitionHiringStatus }), [
    requisitionHiringStatus
  ]);

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>
        <FormattedMessage id="hiringBadge.hiring" values={values} />
      </Typography>
    </div>
  );
};

HiringBadge.propTypes = {
  classes: PropTypes.object,
  requisitionHiringStatus: PropTypes.string.isRequired
};

export default HiringBadge;
