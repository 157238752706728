import { gql } from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util';

export const TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY = gql`
  query GetTaskResourceUserAllocationsSummary(
    $userId: String!
    $filter: TaskResourceUserAllocationsSummaryForUserFilter
  ) {
    getTaskResourceUserAllocationsSummaryForUser(
      userId: $userId
      filter: $filter
    ) {
      scheduleRules {
        dateRange {
          startDate
          endDate
        }
        do
      }
    }
  }
`;

const getTaskAllocationHoursForResourceUser = async ({
  apolloClient,
  projectId,
  taskAllocationId,
  userId,
  startDate,
  endDate
}) => {
  const {
    data: { getTaskResourceUserAllocationsSummaryForUser }
  } = await apolloClient.query({
    query: TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
    variables: {
      userId,
      filter: {
        projectId,
        excludeTaskAllocationId: taskAllocationId
      }
    },
    fetchPolicy: 'network-only'
  });

  return getTotalHoursForDateRangeFromScheduleRules({
    scheduleRules:
      getTaskResourceUserAllocationsSummaryForUser?.scheduleRules || [],
    start: startDate,
    end: endDate
  });
};

export const useTaskAllocationHours = ({
  projectId,
  taskAllocationId,
  userId,
  startDate,
  endDate,
  skip
}) => {
  const { loading, data } = useQuery(
    TASK_RESOURCE_USER_ALLOCATIONS_SUMMARY_QUERY,
    {
      variables: {
        userId,
        filter: {
          projectId,
          excludeTaskAllocationId: taskAllocationId
        }
      },
      skip
    }
  );

  return {
    loading,
    taskAllocationHoursInDateRange: getTotalHoursForDateRangeFromScheduleRules({
      scheduleRules:
        data?.getTaskResourceUserAllocationsSummaryForUser?.scheduleRules || [],
      start: startDate,
      end: endDate
    })
  };
};

export default getTaskAllocationHoursForResourceUser;
