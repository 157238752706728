import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  TextField,
  InputAdornment,
  Popover,
  makeStyles,
  Divider,
  Typography
} from '@material-ui/core';
import { NullableMoneyValue } from '~/modules/common/components';
import FormattedHoursAndCost from '~/modules/resourcing/common/components/FormattedHoursAndCost';
import ButtonBaseHover from '~/modules/common/components/ButtonBaseHover';
import { isNullOrUndefined } from '~/modules/common/util';
import useOnChangeHandlers from './useOnChangeHandlers';

const highlightTarget = event => event.target.select();

const useStyles = makeStyles(theme => ({
  inputAdornment: {
    ...theme.typography.caption,
    padding: theme.spacing(0, 0.5)
  },
  hoursFieldInput: {
    textAlign: 'right',
    padding: theme.spacing(0.5, 0, 0.5, 23),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  estimatedCost: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  },
  buttonBase: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.text.secondary
  },
  enterHoursLabel: {
    pointerEvents: 'none'
  }
}));

const useEstimatedStyles = makeStyles(theme => ({
  root: {
    pointerEvents: 'none'
  },
  currency: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary
  },
  hours: {
    ...theme.typography.body2,
    color: theme.palette.text.primary
  }
}));

const EstimatedHoursEditor = ({
  resourceEstimate,
  onEstimatedHoursChange,
  canViewCost
}) => {
  const classes = useStyles();
  const estimatedClasses = useEstimatedStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hours, setHours] = useState(resourceEstimate.estimatedHours);
  const { formatMessage } = useIntl();

  const {
    onEstimatedHoursClick,
    handleChange,
    onClose,
    handleOnBlur,
    handleHoursKeyPress
  } = useOnChangeHandlers({
    setAnchorEl,
    setHours,
    onEstimatedHoursChange,
    hours
  });

  const inputProps = useMemo(
    () => ({
      classes: {
        input: classes.hoursFieldInput
      },
      disableUnderline: true,
      inputProps: {
        min: 0
      },
      endAdornment: (
        <InputAdornment className={classes.inputAdornment}>h</InputAdornment>
      )
    }),
    [classes.inputAdornment, classes.hoursFieldInput]
  );

  const formattedCost = useMemo(() => {
    const estimateCostRate = resourceEstimate.resource
      ? resourceEstimate.resource?.userRateInProjectCurrency
      : resourceEstimate.role?.currentRate;

    return [
      {
        amount:
          hours !== undefined && !isNullOrUndefined(estimateCostRate?.amount)
            ? hours * estimateCostRate.amount
            : null,
        currencySymbol: estimateCostRate?.currency?.displayText,
        currency: estimateCostRate?.currency
      }
    ];
  }, [hours, resourceEstimate]);

  return (
    <>
      <ButtonBaseHover
        component="a"
        className={classes.buttonBase}
        onClick={onEstimatedHoursClick}
      >
        {resourceEstimate.estimatedHours ? (
          <FormattedHoursAndCost
            classes={estimatedClasses}
            hideCosts={!canViewCost}
            hours={resourceEstimate.estimatedHours}
            hoursSuffix={formatMessage({
              id: 'taskResourceAssignments.hoursSuffix'
            })}
            costs={formattedCost}
            showAbbreviatedValue={false}
          />
        ) : (
          <Typography variant="body2" className={classes.enterHoursLabel}>
            {formatMessage({
              id: 'taskResourceAssignments.enterEstimatedHours'
            })}
          </Typography>
        )}
      </ButtonBaseHover>
      {Boolean(anchorEl) && (
        <Popover
          id="estimated-hours-editor"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
        >
          <div>
            <TextField
              type="number"
              step="0.01"
              min="0"
              variant="standard"
              value={hours}
              onBlur={handleOnBlur}
              onChange={handleChange}
              onFocus={highlightTarget}
              onKeyDown={handleHoursKeyPress}
              autoFocus
              InputProps={inputProps}
            />
            {canViewCost && (
              <>
                <Divider />
                <div className={classes.estimatedCost}>
                  {formatMessage({
                    id: 'taskResourceAssignments.estimatedCost'
                  })}
                  : &nbsp;
                  <NullableMoneyValue money={formattedCost[0]} />
                </div>
              </>
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

EstimatedHoursEditor.propTypes = {
  resourceEstimate: PropTypes.object,
  onEstimatedHoursChange: PropTypes.func.isRequired,
  canViewCost: PropTypes.bool
};

export default EstimatedHoursEditor;
