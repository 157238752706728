import { CHANGE_RESOURCE_ALLOCATION_TYPE } from '../enum';

const useFormState = () => {
  const today = new Date();

  return {
    initialValues: {
      asOfDate: today.toISOString(),
      changeType: CHANGE_RESOURCE_ALLOCATION_TYPE.AsOf,
      newResourceUser: null
    }
  };
};

export default useFormState;
