import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, ListSubheader, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import TableSortLabel from '~/modules/common/components/ListTable/components/DataTable/components/TableSortLabel';
import {
  useAvailabilityTitleStyles,
  useTableSortLabelHoverStyles,
  useIconButtonStyles
} from './useStyles';

const AvailabilityListSubheader = ({
  sort,
  onSortChange,
  isAvailabilityEnabled,
  showAvailability,
  toggleAvailabilityColumnVisibility,
  group,
  classes
}) => {
  const availabilityClasses = useAvailabilityTitleStyles();
  const iconButtonClasses = useIconButtonStyles();
  const tableSortLabelHoverClasses = useTableSortLabelHoverStyles();

  return (
    <ListSubheader component="div" className={classes.groupLabel}>
      <span className={classes.resourceTitle}>
        <TableSortLabel
          show
          field="displayText"
          active={sort.field === 'displayText'}
          direction={sort.direction.toLowerCase()}
          onClick={onSortChange}
        >
          <div className={classes.group}>{group}</div>
        </TableSortLabel>
      </span>
      {showAvailability && (
        <span
          className={classNames(availabilityClasses.root, {
            [availabilityClasses.isDisabled]: !isAvailabilityEnabled
          })}
        >
          <TableSortLabel
            show={isAvailabilityEnabled}
            field="availableDuration"
            active={sort.field === 'availableDuration'}
            direction={sort.direction.toLowerCase()}
            onClick={onSortChange}
            classes={tableSortLabelHoverClasses}
            align="right"
          >
            <FormattedMessage id="addResource.availability" />
          </TableSortLabel>
          <IconButton
            label="Availability-icon"
            aria-label="availability-preview"
            onClick={toggleAvailabilityColumnVisibility}
            classes={iconButtonClasses}
          >
            {isAvailabilityEnabled ? (
              <Tooltip title="Visibility On" placement="bottom-start">
                <Visibility className={availabilityClasses.isEnabled} />
              </Tooltip>
            ) : (
              <Tooltip title="Visibility Off" placement="bottom-start">
                <VisibilityOff className={availabilityClasses.isDisabled} />
              </Tooltip>
            )}
          </IconButton>
        </span>
      )}
    </ListSubheader>
  );
};

AvailabilityListSubheader.propTypes = {
  sort: PropTypes.object,
  onSortChange: PropTypes.func,
  isAvailabilityEnabled: PropTypes.bool,
  showAvailability: PropTypes.bool,
  toggleAvailabilityColumnVisibility: PropTypes.func,
  group: PropTypes.node,
  classes: PropTypes.object.isRequired
};

export default AvailabilityListSubheader;
