import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  mapIsoStringtoUtcObject,
  mapRepliconDateToUtcObject,
  toRepliconDate
} from '~/modules/common/dates/convert';
import useTotalResourceAvailableDurationForDateRange from '~/modules/tasks/hooks/useTotalResourceAvailableDurationForDateRange';
import { TaskAllocationEditorSkeleton } from '../common/components';
import { useTaskEstimateRowContext } from '../../TaskEstimateRowContext';
import { EditorPopoverContent, EditorPopoverFooter } from './components';
import { useFormState as useEditorFormState } from './hooks';

const ResourceManagerRoleEditor = ({
  onClose,
  otherTaskAllocationsSummary,
  resourceAllocation,
  isLoading
}) => {
  const {
    resourceEstimate: {
      id: resourceEstimateId,
      resourceUser: { id: userId },
      projectRole,
      taskResourceUserAllocation,
      initialEstimatedHours,
      taskResourceUserHoursProgressSummary
    },

    task: {
      id: taskId,
      startDate: taskStartDate,
      endDate: taskEndDate,
      project: { id: projectId }
    }
  } = useTaskEstimateRowContext();

  const { estimatedAtCompletionHours } =
    taskResourceUserHoursProgressSummary || {};

  const { startDate, endDate } = taskResourceUserAllocation
    ? {
        startDate: mapIsoStringtoUtcObject(
          taskResourceUserAllocation.startDate
        ),
        endDate: mapIsoStringtoUtcObject(taskResourceUserAllocation.endDate)
      }
    : {
        startDate: taskStartDate
          ? mapRepliconDateToUtcObject(taskStartDate)
          : null,
        endDate: taskEndDate ? mapRepliconDateToUtcObject(taskEndDate) : null
      };

  const {
    availableDuration: availabilityHours,
    workDuration: workHours,
    allocatedDuration: totalResourceAllocatedHours,
    loading: isLoadingAvailability
  } = useTotalResourceAvailableDurationForDateRange({
    resourceUserId: userId,
    startDate: toRepliconDate(startDate),
    endDate: toRepliconDate(endDate),
    skip: !startDate || !endDate
  });

  const { initialValues, validationSchema, onSubmit } = useEditorFormState({
    startDate,
    endDate,
    otherTaskAllocationsSummary,
    resourceAllocation,
    initialEstimatedHours,
    taskResourceUserAllocation,
    availabilityHours,
    onClose,
    projectId,
    taskId,
    taskStartDate,
    taskEndDate,
    userId,
    roleId: projectRole?.id,
    resourceEstimateId,
    workHours,
    totalResourceAllocatedHours
  });

  if (isLoadingAvailability || isLoading)
    return (
      <TaskAllocationEditorSkeleton
        onClose={onClose}
        canEditResourceAllocation
      />
    );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      <>
        <EditorPopoverContent
          resourceAllocation={resourceAllocation}
          otherTaskAllocationsSummary={otherTaskAllocationsSummary}
          initialEstimatedHours={initialEstimatedHours}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
          taskResourceUserAllocation={taskResourceUserAllocation}
          userUri={userId}
        />
        <EditorPopoverFooter
          onClose={onClose}
          hasTaskResourceUserAllocation={Boolean(taskResourceUserAllocation)}
        />
      </>
    </Formik>
  );
};

ResourceManagerRoleEditor.propTypes = {
  onClose: PropTypes.func,
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object,
  isLoading: PropTypes.bool.isRequired
};

export default ResourceManagerRoleEditor;
