import React from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import { ConditionalWrapper } from '~/modules/common/components';
import { useGetKeyValueSettings } from '~/modules/common/hooks';
import { PROJECT_PERMISSION } from '~/modules/common/enums';
import { useMeContext } from '~/modules/me';
import { ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING } from './constants';
import AddResourceEstimateDialogHeader from './AddResourceEstimateDialogHeader';
import AddResourceEstimateDialogContent2 from './AddResourceEstimateDialogContent2';
import AddResourceEstimateDialogFooter2 from './AddResourceEstimateDialogFooter2';
import useAddResourceEstimateDialogFormState from './useAddResourceEstimateDialogFormState';
import useGetTotalResourceEstimateHoursForTask from './useGetTotalResourceEstimateHoursForTask';

const resourceEstimateDialogTitleId = 'AddResourceEstimateDialogTitle';

const AddResourceEstimateDialog = ({
  task,
  isOpen,
  onClose,
  resourceEstimate
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();
  const canEditCompletedResourceAllocation = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );
  const isFullVersionEnabled =
    isRmpTaskAllocationPhase2Enabled && canEditCompletedResourceAllocation;
  const canViewCost =
    Boolean(
      task.project?.permittedActionUris?.includes(
        PROJECT_PERMISSION.VIEW_COST_DATA
      )
    ) && isRmpTaskAllocationPhase2Enabled;
  const {
    loading,
    totalResourceEstimateHours
  } = useGetTotalResourceEstimateHoursForTask({
    taskId: task.id,
    skip: Boolean(resourceEstimate)
  });

  const {
    isLoading: keyValueSettingsLoading,
    keyValueSettings: { isCreateTaskAllocationEnabled } = {}
  } = useGetKeyValueSettings(ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING);

  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const {
    initialValues,
    validationSchema
  } = useAddResourceEstimateDialogFormState({
    task,
    totalResourceEstimateHours,
    resourceEstimate,
    isCreateTaskAllocationEnabled,
    isFullVersionEnabled
  });

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby={resourceEstimateDialogTitleId}
    >
      <AddResourceEstimateDialogHeader
        task={task}
        isEditMode={Boolean(resourceEstimate)}
        dialogTitleId={resourceEstimateDialogTitleId}
      />
      <ConditionalWrapper
        condition={!(loading || keyValueSettingsLoading)}
        WrapperComponent={Formik}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <>
          <AddResourceEstimateDialogContent2
            isFullVersionEnabled={isFullVersionEnabled}
            loading={loading || keyValueSettingsLoading}
            task={task}
          />
          <AddResourceEstimateDialogFooter2
            projectId={task.project.id}
            resourceEstimates={resourceEstimates}
            taskId={task.id}
            onClose={onClose}
            setFieldValue={setFieldValue}
            loadingContent={loading}
            canViewCost={canViewCost}
          />
        </>
      </ConditionalWrapper>
    </Dialog>
  );
};

AddResourceEstimateDialog.propTypes = {
  task: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  resourceEstimate: PropTypes.object
};

export default AddResourceEstimateDialog;
