import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceRequestAccessLevel } from '~/types';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { NoDataItem } from '~/modules/common/components';
import { useResourceMatchPopoverHandler } from '~/modules/resourcing/common/hooks';
import { ResourceMatchPopover } from '~/modules/resourcing/common/components';
import { useResourceAllocationToolbarStateContext } from '~/modules/resourcing/hooks';
import ResourceAllocationChartHeaderRow from '../ResourceAllocationChartHeaderRow';
import ResourceAllocationGroupRow, {
  ResourceAllocationGroupRowLoading
} from '../ResourceAllocationGroupRow';

const useStyles = makeStyles(theme => ({
  noMessage: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  }
}));

const leftComponentWidth = 510;

export const ResourceAllocationChart = ({
  plans = [],
  loading = false,
  maxSkillLevel,
  requestAccessLevel
}) => {
  const {
    chartDates,
    scale,
    resourcePlanGroup,
    displayUnit
  } = useResourceAllocationToolbarStateContext();
  const isPsaPraaResourceMatchingEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaPraaResourceMatchingEnabled'
  });

  const classes = useStyles();

  const {
    anchorEl,
    popoverResourceRequest,
    setAnchorEl,
    setPopoverResourceRequest,
    handleCloseResourceMatchPopover
  } = useResourceMatchPopoverHandler();

  return (
    <>
      <ResourceAllocationChartHeaderRow
        leftComponentWidth={leftComponentWidth}
        scale={scale}
        chartDates={chartDates}
        resourcePlanGroup={resourcePlanGroup}
      />
      {plans.length > 0
        ? plans.map((plan, index) => (
            <ResourceAllocationGroupRow
              key={plan.planUri}
              scale={scale}
              leftComponentWidth={leftComponentWidth}
              plan={plan}
              chartDates={chartDates}
              maxSkillLevel={maxSkillLevel}
              requestAccessLevel={requestAccessLevel}
              isExpandedOnInitialRender={index === 0}
              setAnchorEl={setAnchorEl}
              setPopoverResourceRequest={setPopoverResourceRequest}
            />
          ))
        : !loading && (
            <div className={classes.noMessage}>
              <NoDataItem>
                <FormattedMessage id="resourceAllocationProjectChart.noResourceRequests" />
              </NoDataItem>
            </div>
          )}
      {loading && <ResourceAllocationGroupRowLoading />}
      {isPsaPraaResourceMatchingEnabled && popoverResourceRequest && (
        <ResourceMatchPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseResourceMatchPopover}
          scale={scale}
          displayUnit={displayUnit}
          resourceRequest={popoverResourceRequest}
        />
      )}
    </>
  );
};

ResourceAllocationChart.propTypes = {
  plans: PropTypes.array,
  loading: PropTypes.bool,
  maxSkillLevel: PropTypes.number,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ])
};

export default ResourceAllocationChart;
