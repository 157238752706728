import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTaskResourceEstimate } from './useTaskResourceEstimate';

const useOnChangeTaskResourceSuccess = ({
  taskResourceEstimateId,
  canViewCost
}) => {
  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  const { getTaskResourceEstimate } = useTaskResourceEstimate();

  return {
    onChangeTaskResourceSuccess: useCallback(
      async ({ newTaskResourceEstimateId }) => {
        const {
          taskResourceEstimate: newTaskResourceEstimate
        } = await getTaskResourceEstimate({
          resourceEstimateUri: newTaskResourceEstimateId,
          includeTaskResourceUserHoursProgressSummary: true,
          includeTaskResourceUserCostProgressSummary: canViewCost,
          includeTaskResourceUserAllocation: true
        });

        const updatedResourceEstimates = resourceEstimates.map(
          resourceEstimate => {
            return taskResourceEstimateId === resourceEstimate.id
              ? newTaskResourceEstimate
              : resourceEstimate;
          }
        );

        setFieldValue('resourceEstimates', updatedResourceEstimates);
      },
      [
        getTaskResourceEstimate,
        resourceEstimates,
        setFieldValue,
        taskResourceEstimateId
      ]
    )
  };
};

export default useOnChangeTaskResourceSuccess;
