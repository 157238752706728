import React, { useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Chip, Input, makeStyles, MenuItem } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import ExpandLessSharp from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreSharp from '@material-ui/icons/ExpandMoreSharp';
import { TaskStatus } from '~/types';
import { themeWithoutDir as theme } from '~/modules/App/withRootTheme';
import { SELECTED_TASK_STATUS } from '~/modules/common/enums';
import FormSelectField from '~/modules/common/components/FormSelectField';
import { useOnTaskStatusSelected } from '../hooks';

const useStyles = makeStyles({
  subheading: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    color: theme.palette.text.secondary
  },
  statusChip: ({ status }) => ({
    backgroundColor: theme.palette.taskStatus[status].color,
    fontWeight: 600
  }),
  field: ({ status }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.taskStatus[status].color,
    borderRadius: theme.spacing(2),
    padding: 0,
    fontWeight: 600
  }),
  inputBase: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    height: theme.spacing(4),
    '&.MuiInputBase-root.Mui-disabled': {
      color: theme.palette.common.white
    },
    justifyContent: 'center',
    '& .MuiSelect-select': {
      paddingRight: theme.spacing(3.75),
      backgroundColor: 'transparent'
    }
  },
  select: {
    padding: theme.spacing(0.25, 1.5, 0, 1.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    color: theme.palette.text.main
  },
  icon: {
    color: theme.palette.text.main,
    right: theme.spacing(0.5)
  },
  option: {
    display: 'flex',
    flexDirection: 'column'
  },
  optionDescription: {
    fontSize: theme.spacing(1.25),
    color: theme.palette.text.secondary
  },
  boldItem: {
    fontWeight: theme.typography.fontWeightBold
  }
});

const InputBase = classes => (
  <Input className={classes.inputBase} disableUnderline />
);

const TaskStatusSelectField = ({ task, canEditTask }) => {
  const intl = useIntl();

  const {
    onTaskStatusSelected,
    isTaskStatusSelectorExpanded,
    toggleTaskStatusSelectorExpanded
  } = useOnTaskStatusSelected(task);

  const {
    taskStatus,
    overriddenTaskStatus,
    rolledUpSummary,
    actualHours,
    totalActualHours
  } = task;

  const hasActualHours = Boolean(
    rolledUpSummary?.actualHours || actualHours || totalActualHours
  );

  const classes = useStyles({ status: taskStatus });

  const notStartedText = intl.formatMessage({ id: `taskDrawer.notStarted` });
  const inProgressText = intl.formatMessage({ id: `taskDrawer.inProgress` });
  const completedText = intl.formatMessage({ id: 'taskDrawer.completed' });
  const calculatedByTimeEntriesTitle = useMemo(
    () =>
      hasActualHours ? (
        <>
          <FormattedMessage id="taskDrawer.automatic" />:{' '}
          <FormattedMessage id="taskDrawer.notStarted" /> /{' '}
          <span className={classes.boldItem}>
            <FormattedMessage id="taskDrawer.inProgress" />
          </span>
        </>
      ) : (
        <>
          <FormattedMessage id="taskDrawer.automatic" />:{' '}
          <span className={classes.boldItem}>
            <FormattedMessage id="taskDrawer.notStarted" />
          </span>{' '}
          / <FormattedMessage id="taskDrawer.inProgress" />
        </>
      ),
    [hasActualHours, classes]
  );

  const options = [
    {
      value: SELECTED_TASK_STATUS.CALCULATED_BY_TIME_ENTRIES,
      collapsedTitle: hasActualHours ? inProgressText : notStartedText,
      expandedTitle: calculatedByTimeEntriesTitle,
      description: intl.formatMessage({
        id: `taskDrawer.calculatedByTimeEntriesDescription`
      })
    },
    {
      value: SELECTED_TASK_STATUS.MANUAL_NOT_STARTED,
      collapsedTitle: notStartedText,
      expandedTitle: notStartedText,
      description: intl.formatMessage({
        id: `taskDrawer.manualNotStartedDescription`
      })
    },
    {
      value: SELECTED_TASK_STATUS.MANUAL_IN_PROGRESS,
      collapsedTitle: inProgressText,
      expandedTitle: inProgressText,
      description: intl.formatMessage({
        id: `taskDrawer.manualInProgressDescription`
      })
    },
    {
      value: SELECTED_TASK_STATUS.COMPLETED,
      collapsedTitle: completedText,
      expandedTitle: completedText,
      description: intl.formatMessage({
        id: `taskDrawer.completedDescription`
      })
    }
  ];

  const selectedTaskStatus =
    taskStatus === TaskStatus.Completed
      ? SELECTED_TASK_STATUS.COMPLETED
      : overriddenTaskStatus || SELECTED_TASK_STATUS.CALCULATED_BY_TIME_ENTRIES;

  const selectClasses = useMemo(
    () => ({
      icon: classes.icon,
      select: classes.select
    }),
    [classes.icon, classes.select]
  );

  const renderOptionValue = useCallback(
    value => options.find(o => o.value === value)?.collapsedTitle || ''
  );

  return (
    <>
      <span id="taskDrawerStatusLabel" className={classes.subheading}>
        {intl.formatMessage({
          id: 'taskDrawer.Status'
        })}
      </span>
      {canEditTask ? (
        <FormSelectField
          className={classes.field}
          classes={selectClasses}
          value={selectedTaskStatus}
          renderValue={renderOptionValue}
          onChange={onTaskStatusSelected}
          onOpen={toggleTaskStatusSelectorExpanded}
          onClose={toggleTaskStatusSelectorExpanded}
          input={InputBase(classes)}
          IconComponent={
            isTaskStatusSelectorExpanded ? ExpandLessSharp : ExpandMoreSharp
          }
          disabled={!canEditTask}
          labelId="taskDrawerStatusLabel"
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <div className={classes.option}>
                <span>{option.expandedTitle}</span>
                <span className={classes.optionDescription}>
                  {option.description}
                </span>
              </div>
            </MenuItem>
          ))}
        </FormSelectField>
      ) : (
        <Chip
          className={classes.statusChip}
          label={intl.formatMessage({
            id: `projectTasksPage.tasksStatus.${taskStatus}`
          })}
        />
      )}
    </>
  );
};

TaskStatusSelectField.propTypes = {
  task: PropTypes.object.isRequired,
  canEditTask: PropTypes.bool.isRequired
};

export default TaskStatusSelectField;
