import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import AvailabilityPeriod from '~/modules/resourcing/common/components/AvailabilityPeriod';
import { useMeContext } from '~/modules/me';
import {
  ResourceIcon,
  ProjectAllocationIcon
} from '~/modules/common/components';
import { TaskAllocationEditorTooltip } from './components/TaskAllocationEditorTooltip';

const useStyles = makeStyles(theme => ({
  detailsContainer: {
    padding: theme.spacing(0.25),
    textAlign: 'right',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary
  },
  details: {
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detailsText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  overAllocated: {
    color: theme.palette.error.dark,
    fontWeight: theme.typography.fontWeightBold
  },
  value: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 0.25, 0, 0)
  }
}));

export const TaskAllocationEditorContent = ({
  remainingValue,
  availableValue,
  isHoursMode,
  hideRemainingValue
}) => {
  const {
    featureFlags: { isRmpTaskAllocationPhase2Enabled },
    permissionsMap
  } = useMeContext();

  const isCompleteResourceAllocationPermissionEnabled = Boolean(
    permissionsMap['urn:replicon-webui:completed-resource-allocation:edit']
  );

  const showAvailability = isCompleteResourceAllocationPermissionEnabled;

  const showRemaining =
    !isRmpTaskAllocationPhase2Enabled ||
    !isCompleteResourceAllocationPermissionEnabled ||
    !hideRemainingValue;

  const classes = useStyles();

  const tooltipContent = useMemo(
    () => (
      <TaskAllocationEditorTooltip
        showAvailability={showAvailability}
        showRemaining={showRemaining}
      />
    ),
    [showAvailability, showRemaining]
  );

  const remainingComponent = useMemo(
    () => (
      <div className={classes.details}>
        {isRmpTaskAllocationPhase2Enabled && (
          <ProjectAllocationIcon fontSize="small" className={classes.icon} />
        )}
        <div className={classes.detailsText}>
          <FormattedMessage id="taskAllocationEditor.remaining" />:
          <AvailabilityPeriod
            availableHours={remainingValue}
            isHoursMode={isHoursMode}
            classes={classes}
          />
        </div>
      </div>
    ),
    [classes, isHoursMode, isRmpTaskAllocationPhase2Enabled, remainingValue]
  );

  return isRmpTaskAllocationPhase2Enabled ? (
    <Tooltip title={tooltipContent} placement="right">
      <div className={classes.detailsContainer}>
        {showAvailability && (
          <div className={classes.details}>
            <ResourceIcon fontSize="small" className={classes.icon} />
            <div className={classes.detailsText}>
              <FormattedMessage id="taskAllocationEditor.availability" />:
              <AvailabilityPeriod
                availableHours={availableValue}
                isHoursMode={isHoursMode}
                classes={classes}
              />
            </div>
          </div>
        )}
        {showRemaining && remainingComponent}
      </div>
    </Tooltip>
  ) : (
    <div className={classes.details}>{remainingComponent}</div>
  );
};

TaskAllocationEditorContent.propTypes = {
  availableValue: PropTypes.number.isRequired,
  remainingValue: PropTypes.number.isRequired,
  isHoursMode: PropTypes.bool,
  hideRemainingValue: PropTypes.bool
};
