import PropTypes from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';
import { ChangeResourceDialogContent } from './ChangeResourceDialogContent';
import { ChangeResourceDialogFooter } from './ChangeResourceDialogFooter';
import { useChangeResourceUserTaskAssignmentSummary } from './hooks';

export const ChangeResourceDialogBody = ({
  existingResourceUser,
  onClose,
  projectId
}) => {
  const {
    values: { newResourceUser }
  } = useFormikContext();

  const {
    hasTaskAssignmentConflict,
    isLoading: isTaskAssignmentConflictLoading
  } = useChangeResourceUserTaskAssignmentSummary({
    existingResourceUserId: existingResourceUser.id,
    newResourceUserId: newResourceUser?.id,
    projectId,
    skip: !newResourceUser
  });

  return (
    <>
      <ChangeResourceDialogContent
        existingResourceUser={existingResourceUser}
        hasTaskAssignmentConflict={hasTaskAssignmentConflict}
        isTaskAssignmentConflictLoading={isTaskAssignmentConflictLoading}
        projectId={projectId}
      />
      <ChangeResourceDialogFooter
        existingResourceUser={existingResourceUser}
        hasTaskAssignmentConflict={hasTaskAssignmentConflict}
        isTaskAssignmentConflictLoading={isTaskAssignmentConflictLoading}
        newResourceUser={newResourceUser}
        onClose={onClose}
      />
    </>
  );
};

ChangeResourceDialogBody.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ChangeResourceDialogBody;
