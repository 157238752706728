import { useCallback } from 'react';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import {
  getAllocatedValuesForTaskAllocation,
  startIsAfterEnd
} from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor/common/taskAllocationEditorUtil';
import { calculateProjectAllocationHours } from '~/modules/resourcing/common/util';

export const onDateChange = async ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  setValues,
  values,
  taskResourceUserAllocation,
  getTotalResourceAvailableHoursForDateRange,
  userUri
}) => {
  if (!startDate || !endDate) {
    setValues({
      ...values,
      startDate,
      endDate,
      newTaskAllocationHours: null
    });
  } else {
    const {
      loading,
      workDuration,
      allocatedDuration
    } = await getTotalResourceAvailableHoursForDateRange({
      resourceUserId: userUri,
      startDate,
      endDate
    });

    const {
      newProjectAllocationHours,
      otherTaskAllocationHours
    } = getAllocatedValuesForTaskAllocation({
      start: mapRepliconDateToUtcObject(startDate),
      end: mapRepliconDateToUtcObject(endDate),
      taskResourceUserAllocation,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules
    });

    setValues({
      ...values,
      startDate,
      endDate,
      newWorkHours: !loading && workDuration,
      newTotalResourceAllocatedHours: !loading && allocatedDuration,
      otherTaskAllocationHours,
      newProjectAllocationHours,
      initialProjectAllocationHours: newProjectAllocationHours
    });
  }
};

const useFormChangeHandlers = ({
  values,
  setValues,
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  userUri,
  taskResourceUserAllocation,
  getTotalResourceAvailableHoursForDateRange
}) => ({
  onStartDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate: val,
        endDate:
          endDate && startIsAfterEnd({ start: val, end: endDate })
            ? val
            : endDate,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        setValues,
        userUri,
        taskResourceUserAllocation,
        getTotalResourceAvailableHoursForDateRange,
        values
      });
    },
    [
      endDate,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      setValues,
      userUri,
      taskResourceUserAllocation,
      getTotalResourceAvailableHoursForDateRange,
      values
    ]
  ),
  onEndDateChange: useCallback(
    async val => {
      await onDateChange({
        startDate:
          startDate && startIsAfterEnd({ start: startDate, end: val })
            ? val
            : startDate,
        endDate: val,
        resourceAllocationScheduleRules,
        otherTaskAllocationsSummaryScheduleRules,
        setValues,
        userUri,
        taskResourceUserAllocation,
        getTotalResourceAvailableHoursForDateRange,
        values
      });
    },
    [
      getTotalResourceAvailableHoursForDateRange,
      otherTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      setValues,
      startDate,
      taskResourceUserAllocation,
      userUri,
      values
    ]
  ),
  onTaskAllocationHoursChange: useCallback(
    event => {
      const {
        initialTaskAllocationHours,
        initialProjectAllocationHours,
        otherTaskAllocationHours
      } = values;
      const newTaskAllocationHours = event.target.value || 0;
      const newProjectAllocationHours = calculateProjectAllocationHours({
        initialTaskAllocationHours,
        initialProjectAllocationHours,
        initialTotalTaskAllocationHours:
          initialTaskAllocationHours + otherTaskAllocationHours,
        newTaskAllocationHours
      });

      setValues({
        ...values,
        newTaskAllocationHours,
        newProjectAllocationHours
      });
    },
    [setValues, values]
  )
});

export default useFormChangeHandlers;
