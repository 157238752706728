import { useCallback } from 'react';

const useFormOnChangeHandlers = ({ setFieldValue, setValues, values }) => {
  const onAsOfDateChange = useCallback(
    date =>
      setValues({
        ...values,
        asOfDate: date,
        newResourceUser: null
      }),
    [setValues, values]
  );

  const onChangeTypeSelect = useCallback(
    event =>
      setValues({
        ...values,
        changeType: event.target.value,
        newResourceUser: null
      }),
    [setValues, values]
  );

  const onResourceUserChange = useCallback(
    resource => {
      setFieldValue('newResourceUser', resource);
    },
    [setFieldValue]
  );

  return {
    onAsOfDateChange,
    onChangeTypeSelect,
    onResourceUserChange
  };
};

export default useFormOnChangeHandlers;
