import { makeStyles, alpha } from '@material-ui/core';
import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';

export const useStatusChipStyle = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    borderRadius: theme.spacing(2),
    maxWidth: theme.spacing(12.5)
  },
  label: {
    padding: theme.spacing(0, 1.5)
  },
  ...getResourceRequestStatusStyles(theme)
}));

export const useDelaStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.Dela.lightBackground
  }
}));

export const useDelaStarIconStyles = makeStyles(theme => ({
  root: {
    fill: theme.palette.Dela.darkIcon,
    margin: theme.spacing(0, 2)
  }
}));

export const useHiringBadgeStyles = makeStyles(() => ({
  container: {
    margin: 0
  },
  text: {
    margin: 0
  }
}));

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(0.5, 1, 0),
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  labelRow: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    lineHeight: `${theme.spacing(2)}px`,
    height: `${theme.spacing(2)}px`
  },
  roleColumn: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    lineHeight: `${theme.spacing(5)}px`,
    flexBasis: 196
  },
  roleName: {
    color: theme.palette.text.primary,
    ...theme.palette.text.primary
  },
  noValue: {
    color: theme.palette.text.secondary,
    ...theme.palette.text.secondary
  },
  tooltipDetails: {
    margin: theme.spacing(0.5, 0, 0)
  },
  statusColumn: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: 0,
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    minWidth: theme.spacing(15),
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  outerColumn: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: 0,
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: theme.spacing(30),
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  delaColumn: {
    minWidth: 'unset',
    flexBasis: 'unset'
  },
  column: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: [[0, 0, 0, 0]],
    display: 'flex',
    textAlign: 'left',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: theme.spacing(15),
    '&:hover $openButton': {
      display: 'block',
      background: 'none'
    }
  },
  clickableColumn: {
    cursor: 'pointer',
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.15)
    }
  },
  openButton: {
    display: 'none',
    opacity: 1,
    alignSelf: 'flex-start',
    margin: theme.spacing(-0.5, -2, 0, -1)
  }
}));
