import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from '@material-ui/core';
import FormCustomField from '~/modules/common/components/FormCustomField';
import { getError, hasError } from '~/util';

export const TaskCustomFields = ({
  customFieldDefinitions,
  values,
  errors,
  editable,
  textProps,
  numericProps,
  dropdownProps,
  dateProps,
  variant = 'filled',
  align,
  breakpoints = {}
}) => (
  <>
    {customFieldDefinitions
      .filter(definition => definition.isVisible)
      .map(definition => (
        <Grid key={definition.uri} item xs={12} sm={6} {...breakpoints}>
          <FormCustomField
            editable={editable}
            definition={definition}
            value={values[definition.uri]}
            onChange={definition.onChange}
            error={hasError(errors, definition.uri)}
            helperText={getError(errors, definition.uri)}
            fullWidth
            align={align}
            variant={variant}
            {...(definition.type.uri ===
              'urn:replicon:custom-field-type:text' && textProps)}
            {...(definition.type.uri ===
              'urn:replicon:custom-field-type:numeric' && numericProps)}
            {...(definition.type.uri ===
              'urn:replicon:custom-field-type:drop-down' && dropdownProps)}
            {...(definition.type.uri ===
              'urn:replicon:custom-field-type:date' && dateProps)}
          />
        </Grid>
      ))}
  </>
);

TaskCustomFields.propTypes = {
  editable: PropTypes.bool,
  customFieldDefinitions: PropTypes.array,
  values: PropTypes.object,
  align: PropTypes.string,
  variant: PropTypes.string,
  errors: PropTypes.object,
  textProps: PropTypes.object,
  numericProps: PropTypes.object,
  dropdownProps: PropTypes.object,
  dateProps: PropTypes.object,
  breakpoints: PropTypes.object
};

export default TaskCustomFields;
