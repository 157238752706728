import React, {
  useCallback,
  useState,
  useEffect,
  useReducer,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import {
  ResourcePoolIcon,
  CancelButton,
  LoadingButton
} from '~/modules/common/components';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  useAssignedResourcePoolCount,
  useAssignedResourcePools
} from '../../ResourceDrawer/components/ResourceDrawerContent/components/ResourcePoolSection/hooks';

import { useNavigateTablePages } from './hooks';
import { useDialogStyles } from './useStyles';
import ResourcePoolUserTable from './ResourcePoolUserTable';
import {
  FETCH_RESOURCE_POOLS_PAGE_SIZE,
  makeResourcePoolsReducer,
  initialResourcePoolReducerState,
  CANCEL_RESOURCE_POOL,
  SAVE_RESOURCE_POOL,
  FETCH_RESOURCE_POOLS_WITH_SEARCH,
  FETCH_RESOURCE_POOLS
} from './resourcePoolsReducer';
import {
  useBulkAssignResourcePoolsToUser,
  useBulkUnassignResourcePoolsToUser
} from './hooks/useUpdateAvailableResourcePoolsForUser';

const ResourcePoolUserDialog = ({
  open,
  onClose,
  userDetails,
  editable,
  setSnackbarMessage
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const classes = useDialogStyles();
  const { formatMessage } = useIntl();
  const [isSaving, setIsSaving] = useState(false);

  const {
    page,
    localSearchText,
    searchText,
    onNextPage,
    onPreviousPage,
    handleSearchChange,
    resetSearchState,
    onFocus,
    onBlur
  } = useNavigateTablePages();

  const resourcePoolsReducer = useMemo(
    () =>
      makeResourcePoolsReducer({
        page,
        searchText
      }),
    [page, searchText]
  );

  const [resourcePoolsState, dispatch] = useReducer(resourcePoolsReducer, {
    ...initialResourcePoolReducerState
  });

  const { resourcePools, loading } = useAssignedResourcePools({
    page,
    userSlug: userDetails.slug,
    searchFilter: {
      searchTextParam: searchText,
      includeOnlyEnabled: false
    },
    pageSize: FETCH_RESOURCE_POOLS_PAGE_SIZE
  });

  const {
    loading: loadingCount,
    assignedResourcePoolCount
  } = useAssignedResourcePoolCount({
    userSlug: userDetails.slug,
    searchFilter: {
      searchTextParam: searchText,
      includeOnlyEnabled: false
    }
  });

  useEffect(() => {
    if (!loading && !loadingCount) {
      const dispatchEvent = searchText
        ? FETCH_RESOURCE_POOLS_WITH_SEARCH
        : FETCH_RESOURCE_POOLS;

      dispatch({
        type: dispatchEvent,
        fetchedResourcePools: resourcePools,
        resourcePoolsCount: assignedResourcePoolCount
      });
    }
  }, [
    assignedResourcePoolCount,
    loading,
    loadingCount,
    resourcePools,
    searchText
  ]);

  const { bulkAssignResourcePoolsToUser } = useBulkAssignResourcePoolsToUser();
  const {
    bulkUnassignResourcePoolsToUser
  } = useBulkUnassignResourcePoolsToUser();

  const onSave = useCallback(async () => {
    setIsSaving(true);
    onClose();
    setSnackbarMessage({
      poolsAdded: resourcePoolsState.selectedResourcePools,
      poolsRemoved: resourcePoolsState.unselectedResourcePools,
      existingPools: resourcePoolsState.allFetchedResourcePools
    });
    dispatch({
      type: SAVE_RESOURCE_POOL
    });

    await Promise.all([
      resourcePoolsState.unselectedResourcePools.length > 0
        ? bulkUnassignResourcePoolsToUser({
            userUri: userDetails.uri,
            resourcePoolUris: resourcePoolsState.unselectedResourcePools.map(
              x => x.id
            )
          })
        : null,
      resourcePoolsState.selectedResourcePools.length > 0
        ? bulkAssignResourcePoolsToUser({
            userUri: userDetails.uri,
            resourcePoolUris: resourcePoolsState.selectedResourcePools.map(
              x => x.id
            )
          })
        : null
    ]);
    resetSearchState();
    setIsSaving(false);
  }, [
    onClose,
    setSnackbarMessage,
    resourcePoolsState.selectedResourcePools,
    resourcePoolsState.unselectedResourcePools,
    resourcePoolsState.allFetchedResourcePools,
    bulkUnassignResourcePoolsToUser,
    userDetails.uri,
    bulkAssignResourcePoolsToUser,
    resetSearchState
  ]);

  const onCancelClick = useCallback(() => {
    onClose();
    resetSearchState();
    if (!loading && !loadingCount) {
      dispatch({
        type: CANCEL_RESOURCE_POOL,
        fetchedResourcePools: resourcePools,
        resourcePoolsCount: assignedResourcePoolCount
      });
    }
  }, [
    onClose,
    resetSearchState,
    loading,
    loadingCount,
    resourcePools,
    assignedResourcePoolCount
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="resource-pools-user-settings-dialog-title"
      aria-describedby={
        isPsaPrpWcagFixesQ4Enabled
          ? 'resource-pools-user-settings-dialog-description resource-pools-user-settings-dialog-actions'
          : 'resource-pools-user-settings-dialog-description'
      }
    >
      <DialogTitle
        {...(isPsaPrpWcagFixesQ4Enabled && {
          id: 'resource-pools-user-settings-dialog-title'
        })}
        className={classes.dialogTitle}
        disableTypography
      >
        <div className={classes.container}>
          <ResourcePoolIcon className={classes.icon} />
          <Typography variant="h6">
            {formatMessage({
              id: 'resourceBasicInfoCard.resourcePools'
            })}
          </Typography>
        </div>
        <TextField
          placeholder={formatMessage({
            id: 'resourceBasicInfoCard.resourcePoolDialog.placeHolderText'
          })}
          value={localSearchText}
          onChange={handleSearchChange}
          onFocus={onFocus}
          onBlur={onBlur}
          fullWidth
          size="small"
          variant="outlined"
          className={classes.root}
        />
      </DialogTitle>
      <DialogContent
        {...(isPsaPrpWcagFixesQ4Enabled && {
          id: 'resource-pools-user-settings-dialog-description'
        })}
      >
        <ResourcePoolUserTable
          userDetails={userDetails}
          searchText={searchText}
          currentPage={page}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          loading={loading}
          editOnlyMode={editable}
          loadingCount={loadingCount}
          dispatch={dispatch}
          resourcePoolsState={resourcePoolsState}
        />
      </DialogContent>
      <DialogActions
        {...(isPsaPrpWcagFixesQ4Enabled && {
          id: 'resource-pools-user-settings-dialog-actions'
        })}
      >
        <CancelButton onClick={onCancelClick} disabled={isSaving} />
        <LoadingButton
          color="primary"
          onClick={onSave}
          isLoading={false}
          disabled={!editable || isSaving || loading}
        >
          {formatMessage({
            id: 'button.save'
          })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ResourcePoolUserDialog.propTypes = {
  userDetails: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired
};

export default ResourcePoolUserDialog;
