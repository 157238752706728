import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import {
  isoStringToFormattedISOString,
  isoStringToObject
} from '~/modules/common/dates/convert';
import Decimal from '~/modules/common/components/Decimal';
import { FieldLabel } from '../../TaskAllocationEditor';
import { UserOverAllocationChip } from '../UserOverAllocationChip';
import useTaskResourceUserOverAllocationSummary from './hooks/useTaskResourceUserOverAllocationSummary';

const dateFormatString = 'LLL dd, yyyy';

export const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    color: theme.palette.text.secondary
  },
  dateRange: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    marginBottom: -theme.spacing(0.25)
  },
  hours: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1)
  },
  hoursContent: {
    display: 'flex'
  }
}));

const TaskAllocationCardContent = ({
  taskResourceUserAllocation,
  resourceUser
}) => {
  const {
    startDate,
    endDate,
    totalHours,
    projectUri
  } = taskResourceUserAllocation;

  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    isOverAllocated,
    isOverDistributed
  } = useTaskResourceUserOverAllocationSummary({
    resourceUserId: resourceUser.id,
    startDate,
    endDate,
    projectUri,
    taskAllocationHours: totalHours
  });

  const hoursLabel = formatMessage({ id: 'taskResourceEstimates.hoursSuffix' });
  const dateRangeLabelValues = useMemo(
    () => ({
      startDate: isoStringToFormattedISOString(startDate, dateFormatString),
      endDate: isoStringToFormattedISOString(endDate, dateFormatString)
    }),
    [endDate, startDate]
  );

  return (
    <>
      <div aria-live="polite" className={classes.root}>
        <div>
          <FieldLabel
            variant="caption"
            messageId="taskResourceEstimates.allocation"
            className={classes.label}
          />
          <div className={classes.hoursContent}>
            <Decimal
              value={totalHours}
              className={classes.hours}
              suffix={hoursLabel}
            />
            {isOverDistributed && (
              <InfoIcon
                className={classes.error}
                aria-label={formatMessage({
                  id: 'taskResourceEstimates.overDistributedIcon'
                })}
                fontSize="small"
                aria-hidden
              />
            )}
          </div>
        </div>
        {isOverAllocated && (
          <UserOverAllocationChip
            startDate={isoStringToObject(startDate)}
            endDate={isoStringToObject(endDate)}
          />
        )}
      </div>
      <div className={classes.dateRange}>
        <FieldLabel
          messageId="taskResourceEstimates.dateRange"
          messageValues={dateRangeLabelValues}
        />
      </div>
    </>
  );
};

TaskAllocationCardContent.propTypes = {
  taskResourceUserAllocation: PropTypes.object.isRequired,
  resourceUser: PropTypes.object
};

export default TaskAllocationCardContent;
