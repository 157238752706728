import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItemText,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { SimpleDropdown } from '~/modules/common/components';
import LoadingButton from '~/modules/common/components/LoadingButton';
import {
  useUpdateParentTaskDateRollupSetting,
  PARENT_TASK_DATE_ROLLUP_OPTION
} from './hooks/useUpdateParentTaskDateRollupSetting';

const useStyles = makeStyles(theme => ({
  primaryTypography: {
    fontSize: theme.typography.body1.fontSize
  },
  select: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(2, 2)
  }
}));

const TaskSettingsDialog = ({
  open,
  onClose,
  isTaskDateRollupEnabled,
  hasTasks,
  projectId
}) => {
  const {
    setParentTaskDateRollup,
    loading
  } = useUpdateParentTaskDateRollupSetting();

  const classes = useStyles();
  const intl = useIntl();
  const dialogLabels = useMemo(
    () => ({
      confirmationCheckbox: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.confirmationCheckbox'
      }),
      dialogWarning: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.dialogWarning'
      }),
      dialogTitle: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.dialogTitle'
      }),
      setExplicitlyOption: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.setExplicitlyOption'
      }),
      setExplicitlySubtitle: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.setExplicitlySubtitle'
      }),
      calculateAutomaticallyOption: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.calculateAutomaticallyOption'
      }),
      calculateAutomaticallySubtitle: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.calculateAutomaticallySubtitle'
      }),
      dropdownTitle: intl.formatMessage({
        id: 'taskDateRollupSettingsDialog.dropdownTitle'
      })
    }),
    [intl]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        id: PARENT_TASK_DATE_ROLLUP_OPTION.CalculateAutomatically,
        displayText: dialogLabels.calculateAutomaticallyOption,
        component: (
          <ListItemText
            primary={dialogLabels.calculateAutomaticallyOption}
            secondary={dialogLabels.calculateAutomaticallySubtitle}
            primaryTypographyProps={{
              className: classes.primaryTypography
            }}
          />
        )
      },
      {
        id: PARENT_TASK_DATE_ROLLUP_OPTION.SetExplicitly,
        displayText: dialogLabels.setExplicitlyOption,
        component: (
          <ListItemText
            primary={dialogLabels.setExplicitlyOption}
            secondary={dialogLabels.setExplicitlySubtitle}
            primaryTypographyProps={{
              className: classes.primaryTypography
            }}
          />
        )
      }
    ],
    [classes, dialogLabels]
  );

  const [
    parentTaskDateRollupSetting,
    setParentTaskDateRollupSetting
  ] = useState(
    isTaskDateRollupEnabled
      ? dropdownOptions.find(
          x => x.id === PARENT_TASK_DATE_ROLLUP_OPTION.CalculateAutomatically
        )
      : dropdownOptions.find(
          x => x.id === PARENT_TASK_DATE_ROLLUP_OPTION.SetExplicitly
        )
  );
  const [showAcknowledge, setShowAcknowledge] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const parenTaskDateSettingDisabled = hasTasks && !isTaskDateRollupEnabled;

  const handleSelectedChange = useCallback(
    selectedOption => {
      const automaticSelected =
        selectedOption.id ===
        PARENT_TASK_DATE_ROLLUP_OPTION.CalculateAutomatically;

      setParentTaskDateRollupSetting(selectedOption);
      setShowAcknowledge(isTaskDateRollupEnabled && !automaticSelected);
      setCanSave(automaticSelected || acknowledged);
    },
    [acknowledged, isTaskDateRollupEnabled]
  );

  const handleCheckboxChange = useCallback(
    event => {
      setAcknowledged(event.target.checked);
      setCanSave(event.target.checked);
    },
    [setAcknowledged]
  );

  const handleSave = useCallback(async () => {
    await setParentTaskDateRollup(projectId, parentTaskDateRollupSetting.id);
    onClose();
  }, [
    setParentTaskDateRollup,
    parentTaskDateRollupSetting,
    projectId,
    onClose
  ]);

  const selectProps = useMemo(() => {
    return {
      labelId: 'parent-task-dates-label',
      id: 'parent-task-dates',
      'aria-live': 'assertive',
      'aria-label': `${parentTaskDateRollupSetting?.displayText} selected`,
      renderValue: () =>
        dropdownOptions.find(x => x.id === parentTaskDateRollupSetting.id)
          .displayText,
      classes: { select: classes.select }
    };
  }, [classes.select, dropdownOptions, parentTaskDateRollupSetting.id]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="task-settings-dialog-title"
      aria-describedby="task-settings-dialog-content"
    >
      <DialogTitle
        id="task-settings-dialog-title"
        aria-label={dialogLabels.dialogTitle}
      >
        {dialogLabels.dialogTitle}
      </DialogTitle>
      <DialogContent id="task-settings-dialog-content">
        <SimpleDropdown
          label={dialogLabels.dropdownTitle}
          options={dropdownOptions}
          value={parentTaskDateRollupSetting}
          onChange={handleSelectedChange}
          disabled={parenTaskDateSettingDisabled}
          disableSort
          noneOption={false}
          classes={classes}
          hiddenLabel={false}
          variant="outlined"
          formControlMargin="normal"
          selectProps={selectProps}
          ariaLabel={parentTaskDateRollupSetting?.displayText}
        />
        {showAcknowledge && (
          <Alert severity="warning" icon={false}>
            <Typography variant="body2">
              <strong>{`${intl.formatMessage({
                id: 'warning.warning'
              })}: `}</strong>
              {dialogLabels.dialogWarning}
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={acknowledged}
                  onChange={handleCheckboxChange}
                />
              }
              label={dialogLabels.confirmationCheckbox}
            />
          </Alert>
        )}
        {parenTaskDateSettingDisabled && (
          <Alert severity="warning" icon={false}>
            <Typography variant="body2">
              {dialogLabels.dialogWarning}
            </Typography>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <LoadingButton
          isLoading={loading}
          onClick={handleSave}
          color="primary"
          disabled={!canSave}
        >
          {intl.formatMessage({ id: 'button.save' })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

TaskSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isTaskDateRollupEnabled: PropTypes.bool.isRequired,
  hasTasks: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default TaskSettingsDialog;
