import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  tooltipHeader: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold
  },
  tooltipDescription: {
    ...theme.typography.caption,
    lineHeight: `${theme.spacing(2)}px`,
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const useRemainingStyles = makeStyles(theme => ({
  tooltipHeader: {
    padding: theme.spacing(1, 0, 0)
  }
}));

export const TaskAllocationEditorTooltipContent = ({
  classes: classesOverride,
  titleId,
  descriptionId
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <>
      <div className={classes.tooltipHeader}>
        <FormattedMessage id={titleId} />
      </div>
      <div className={classes.tooltipDescription}>
        <FormattedMessage id={descriptionId} />
      </div>
    </>
  );
};

TaskAllocationEditorTooltipContent.propTypes = {
  classes: PropTypes.object,
  titleId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string.isRequired
};

export const TaskAllocationEditorTooltip = ({
  showAvailability,
  showRemaining
}) => {
  const classes = useRemainingStyles();

  return (
    <>
      {showAvailability && (
        <TaskAllocationEditorTooltipContent
          titleId="resourceAssignmentDialog.resourceAvailability"
          descriptionId="resourceAssignmentDialog.resourceAvailabilityDetails"
        />
      )}
      {showRemaining && (
        <TaskAllocationEditorTooltipContent
          classes={showAvailability && classes}
          titleId="resourceAssignmentDialog.projectAllocationRemaining"
          descriptionId="resourceAssignmentDialog.projectAllocationRemainingDetails"
        />
      )}
    </>
  );
};

TaskAllocationEditorTooltip.propTypes = {
  showAvailability: PropTypes.bool.isRequired,
  showRemaining: PropTypes.bool.isRequired
};

export default TaskAllocationEditorTooltip;
