import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Decimal } from '~/modules/common/components/Decimal/Decimal';
import { isNullOrUndefined } from '~/modules/common/util';
import ProgressItemError from './ProgressItemError';
import ProgressItemDetailLoading from './ProgressItemDetailLoading';

const useStyles = makeStyles(theme => ({
  rowItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  columnItems: {
    display: 'flex',
    flexDirection: 'column'
  },
  budgetRemaining: {
    alignItems: 'flex-end',
    textAlign: 'end'
  },
  workRemaining: {
    alignItems: 'flex-start',
    textAlign: 'start'
  },
  delimiter: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: 1.6,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  },
  details: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary
  },
  negativeValue: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  },
  message: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary
  },
  notAvailable: {
    color: theme.palette.text.disabled
  }
}));

export const ProgressItemVariationDetailInternal = ({
  budgetRemaining,
  estimatedRemaining,
  fixedDecimalScale,
  suffix,
  prefix,
  dataQeId,
  resourceKeyOverrides
}) => {
  const classes = useStyles();

  const budgetRemainingClass = classNames(
    classes.columnItems,
    classes.budgetRemaining
  );
  const estimatedRemainingClass = classNames(
    classes.columnItems,
    classes.workRemaining
  );

  const budgetRemainingTooltipContent = useMemo(
    () => <Decimal value={budgetRemaining} suffix={suffix} prefix={prefix} />,
    [budgetRemaining, prefix, suffix]
  );

  const estimatedRemainingTooltipContent = useMemo(
    () =>
      !isNullOrUndefined(estimatedRemaining) && (
        <Decimal value={estimatedRemaining} suffix={suffix} prefix={prefix} />
      ),
    [estimatedRemaining, prefix, suffix]
  );

  return (
    <>
      {!isNullOrUndefined(budgetRemaining) ||
      !isNullOrUndefined(estimatedRemaining) ? (
        <div className={classes.rowItems} data-qe-id={dataQeId}>
          <div className={budgetRemainingClass}>
            {!isNullOrUndefined(budgetRemaining) ? (
              <Tooltip title={budgetRemainingTooltipContent}>
                <span>
                  <Decimal
                    value={budgetRemaining}
                    className={
                      budgetRemaining >= 0
                        ? classes.details
                        : classes.negativeValue
                    }
                    fixedDecimalScale={fixedDecimalScale}
                    suffix={suffix}
                    prefix={prefix}
                  />
                </span>
              </Tooltip>
            ) : (
              <span className={classes.notAvailable}>
                <FormattedMessage id="dashboardProgressChart.na" />
              </span>
            )}
            <span className={classes.message}>
              <FormattedMessage id="dashboardProgressChart.budgetRemaining" />
            </span>
          </div>
          <span className={classNames(classes.delimiter, classes.message)}>
            <FormattedMessage id="dashboardProgressChart.vs" />
          </span>
          <div className={estimatedRemainingClass}>
            {!isNullOrUndefined(estimatedRemaining) ? (
              <Tooltip title={estimatedRemainingTooltipContent}>
                <span>
                  <Decimal
                    value={estimatedRemaining}
                    className={classes.details}
                    fixedDecimalScale={fixedDecimalScale}
                    suffix={suffix}
                    prefix={prefix}
                  />
                </span>
              </Tooltip>
            ) : (
              <span className={classes.notAvailable}>
                <FormattedMessage id="dashboardProgressChart.na" />
              </span>
            )}
            <span className={classes.message}>
              <FormattedMessage
                id={
                  resourceKeyOverrides?.workRemaining ||
                  'dashboardProgressChart.workRemaining'
                }
              />
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

ProgressItemVariationDetailInternal.propTypes = {
  budgetRemaining: PropTypes.number,
  estimatedRemaining: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  dataQeId: PropTypes.string,
  resourceKeyOverrides: PropTypes.object
};

export const ProgressItemVariationDetail = ({
  budgetRemaining,
  estimatedRemaining,
  fixedDecimalScale,
  suffix,
  prefix,
  dataQeId,
  resourceKeyOverrides,
  isLoading = false,
  error
}) => {
  const classes = useStyles();

  return isLoading || error ? (
    <div className={classes.rowItems}>
      {isLoading && <ProgressItemDetailLoading />}
      {error && <ProgressItemError />}
    </div>
  ) : (
    <ProgressItemVariationDetailInternal
      budgetRemaining={budgetRemaining}
      estimatedRemaining={estimatedRemaining}
      fixedDecimalScale={fixedDecimalScale}
      suffix={suffix}
      prefix={prefix}
      dataQeId={dataQeId}
      resourceKeyOverrides={resourceKeyOverrides}
    />
  );
};

ProgressItemVariationDetail.propTypes = {
  budgetRemaining: PropTypes.number,
  estimatedRemaining: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  dataQeId: PropTypes.string,
  resourceKeyOverrides: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

export default ProgressItemVariationDetail;
