import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';
import get from 'lodash.get';
import deepEqual from 'fast-deep-equal';
import { useActiveRolesQuery } from '~/types';
import { useHasPermission } from '~/modules/common/permissions';
import { PAGE_SIZE } from '~/modules/common/const';
import { prependNoneItem } from '../enhancers/prependNoneItem';
import { useSessionStorage } from './useSessionStorage';
import ACTIVE_ROLES_QUERY from './activeRolesQuery.graphql';

export const getEffectivePageSize = pageSize => pageSize ?? PAGE_SIZE + 1;
export const useRoles = ({
  searchTerm = '',
  fetchPolicy = 'cache-and-network',
  includeSkills = false,
  includeNoneOption,
  noneOptionText,
  includeCurrentRate = true,
  includeBillingScheduleEntries = false,
  targetCurrencyId,
  pageSize,
  queryVariablesStorageKey,
  skip
}) => {
  const intl = useIntl();
  const apolloClient = useApolloClient();
  const hasViewPermission = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:view-project-role'
  });

  const { storedValue, setValue: setQueryVariables } = useSessionStorage(
    queryVariablesStorageKey,
    null
  );

  const variables = {
    page: 1,
    pageSize: getEffectivePageSize(pageSize),
    searchTerm,
    targetCurrencyId,
    includeSkills,
    isCurrentRateEnabled: hasViewPermission && includeCurrentRate,
    includeBillingScheduleEntries
  };

  if (queryVariablesStorageKey && !deepEqual(storedValue, variables)) {
    setQueryVariables(variables);
  }

  const { loading, error, data } = useActiveRolesQuery({
    fetchPolicy,
    variables,
    skip
  });

  const roles = get(data, 'activeRoles', []);

  const defaultOptions = roles.slice(0, PAGE_SIZE);

  const fetchOptions = fetchActiveRoles({
    apolloClient,
    hasViewPermission,
    includeNoneOption,
    intl,
    pageSize
  });

  return {
    error,
    hasMore: roles.length > PAGE_SIZE,
    isLoading: loading,
    fetchOptions,
    defaultOptions: includeNoneOption
      ? prependNoneItem(noneOptionText, defaultOptions)
      : defaultOptions
  };
};

export const fetchActiveRoles = ({
  apolloClient,
  hasViewPermission,
  includeNoneOption,
  intl,
  pageSize
}) => async searchTerm => {
  const { data: { activeRoles = [] } = {} } = await apolloClient.query({
    query: ACTIVE_ROLES_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      page: 1,
      pageSize: getEffectivePageSize(pageSize),
      searchTerm,
      includeSkills: false,
      isCurrentRateEnabled: hasViewPermission
    },
    context: {
      debounceKey: 'user-role-search',
      debounceTimeout: 250
    }
  });

  const roles = activeRoles.map(activeRole => ({
    ...activeRole,
    value: activeRole.id,
    label: activeRole.displayText,
    key: activeRole.displayText
  }));

  if (includeNoneOption)
    return prependNoneItem(
      intl.formatMessage({ id: 'projectTasksPage.noRole' }),
      roles
    );

  return roles;
};

export const addLocalRoleToCache = ({
  role,
  client,
  includeSkills,
  isCurrentRateEnabled
}) => {
  try {
    const cache = client.readQuery({
      query: ACTIVE_ROLES_QUERY,
      variables: {
        page: 1,
        pageSize: PAGE_SIZE + 1,
        searchTerm: '',
        includeSkills,
        isCurrentRateEnabled
      }
    });

    const { activeRoles } = cache;

    const updatedRoles = [
      ...activeRoles,
      {
        __typename: 'Role',
        ...role
      }
    ];

    client.writeQuery({
      query: ACTIVE_ROLES_QUERY,
      variables: {
        page: 1,
        pageSize: PAGE_SIZE + 1,
        searchTerm: '',
        includeSkills,
        isCurrentRateEnabled
      },
      data: {
        activeRoles: updatedRoles.sort((a, b) =>
          a.displayText.localeCompare(b.displayText)
        )
      }
    });
  } catch (e) {} // eslint-disable-line
};

export const useSearchableRoles = () => {
  const apolloClient = useApolloClient();
  const intl = useIntl();
  const hasViewPermission = useHasPermission({
    actionUri: 'urn:replicon:project-role-action:view-project-role'
  });

  const fetchOptions = fetchActiveRoles({
    apolloClient,
    hasViewPermission,
    intl
  });

  return { fetchOptions };
};

export const useActiveRolesFromCache = ({ queryVariablesStorageKey }) => {
  const apolloClient = useApolloClient();
  const { storedValue: variables } = useSessionStorage(
    queryVariablesStorageKey,
    null
  );

  const getActiveRolesFromCache = () => {
    const cache = apolloClient.readQuery({
      query: ACTIVE_ROLES_QUERY,
      variables
    });

    return cache?.activeRoles || [];
  };

  return {
    getActiveRolesFromCache
  };
};

export default useRoles;
