import { useResourceUserSlug } from '~/modules/common/hooks';
import { useResourceMatches } from '~/modules/resourcing/common/hooks';

export const useGetResourceMatchRowData = ({ resourceRequestUri }) => {
  const { loading, resourceMatches } = useResourceMatches({
    page: 1,
    pageSize: 3,
    resourceRequestUris: [resourceRequestUri]
  });

  const resourceRequestResourceMatch =
    resourceMatches.length === 1 ? resourceMatches[0].resourceMatches : [];

  const { loading: loading2, resourceUsers } = useResourceUserSlug({
    page: 1,
    pageSize: 3,
    userUris: resourceRequestResourceMatch.map(
      resourceMatch => resourceMatch.id
    ),
    skip: loading
  });

  return {
    loading: loading || loading2,
    userResourceMatchesMap: resourceRequestResourceMatch.map(resourceMatch => ({
      userSlug: resourceUsers.find(
        resourceUser => resourceUser.id === resourceMatch.id
      )?.slug,
      resourceMatch
    }))
  };
};

export default useGetResourceMatchRowData;
