import {
  Divider,
  InputAdornment,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoValue, Hours } from '~/modules/common/components';
import { ESTIMATED_HOURS_MAX } from '~/modules/common/components/TaskDrawer/EditTask/constants';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import useOnChangeHandlers from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/hooks/useOnChangeHandlers';
import { FormattedDeltaChip } from '../../FormattedDeltaChip';
import { PopoverItemMetric } from './PopoverItemMetric';
import { ResourceEstimateCost } from './ResourceEstimateCost';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0.5, 0)
  },
  divider: {
    margin: theme.spacing(0, 0, 1)
  },
  label: {
    minWidth: theme.spacing(22),
    alignContent: 'center'
  },
  rowContainer: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1)
  },
  values: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold
  },
  cost: {
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    minWidth: theme.spacing(16),
    padding: theme.spacing(0, 0.5, 0, 0)
  },
  diff: {
    minWidth: theme.spacing(16)
  },
  inputAdornment: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const usePopoverStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.spacing(42.5),
    minHeight: theme.spacing(9),
    padding: theme.spacing(1.75, 1.5, 1)
  }
}));

const useDifferenceStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0.25, 0.5, 0.25, 0.25),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

const useTaskResourceEstimatesStyles = makeStyles(theme => ({
  input: {
    maxWidth: theme.spacing(7.5),
    '-moz-appearance': 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}));

const getValueOrDefault = value => value || 0;

const ResourceEstimatedHoursEditor = ({
  anchorEl,
  setAnchorEl,
  resourceEstimate,
  rowIndex
}) => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const differenceClasses = useDifferenceStyles();
  const taskResourceEstimatesClasses = useTaskResourceEstimatesStyles();
  const { canViewCost } = useTaskEstimateRowContext();

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="end" className={classes.inputAdornment}>
        <FormattedMessage id="taskResourceEstimates.hoursAdornment" />
      </InputAdornment>
    ),
    [classes.inputAdornment]
  );

  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();

  const {
    initialEstimatedHours,
    initialEstimatedCost,
    taskResourceUserHoursProgressSummary,
    taskResourceUserCostProgressSummary
  } = resourceEstimate;

  const { actualHours, estimatedAtCompletionHours, estimatedRemainingHours } =
    taskResourceUserHoursProgressSummary || {};

  const { actualCost, estimatedAtCompletionCost, estimatedRemainingCost } =
    taskResourceUserCostProgressSummary || {};

  const [estimatedHours, setEstimatedHours] = useState(initialEstimatedHours);

  const { onEstimateChange } = useOnChangeHandlers({
    resourceEstimate,
    setFieldValue,
    rowIndex
  });

  const onChange = useCallback(
    event => {
      setEstimatedHours(Math.min(ESTIMATED_HOURS_MAX, event.target.value));
    },
    [setEstimatedHours]
  );
  const handleOnBlur = useCallback(() => {
    if (initialEstimatedHours === estimatedHours) return;

    onEstimateChange(estimatedHours);
  }, [initialEstimatedHours, estimatedHours, onEstimateChange]);

  const onKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleOnBlur(event);
      }
    },
    [handleOnBlur]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const hoursDifference = estimatedAtCompletionHours - estimatedHours;
  const costDifference = canViewCost
    ? getValueOrDefault(estimatedAtCompletionCost?.amount) -
      getValueOrDefault(initialEstimatedCost?.amount)
    : 0;
  const currencySymbol = `${initialEstimatedCost?.currency?.symbol ||
    estimatedAtCompletionCost?.currency?.symbol} `;

  return (
    <Popover
      id="task-estimate-resource-editor"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={popoverClasses}
    >
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.actual"
        hours={actualHours}
        cost={actualCost}
        canViewCost={canViewCost}
      />
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.workRemaining"
        hours={estimatedRemainingHours}
        cost={estimatedRemainingCost}
        canViewCost={canViewCost}
        showNoValue
      />
      <Divider className={classes.divider} />
      <PopoverItemMetric
        formattedMessageKey="taskResourceEstimates.estimatedAtCompletion"
        hours={estimatedAtCompletionHours}
        cost={estimatedAtCompletionCost}
        canViewCost={canViewCost}
        showNoValue
      />
      <div className={classes.rowContainer}>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage id="taskResourceEstimates.originalEstimate" />
        </Typography>

        <Hours
          dataQeId="TaskEstimateHours"
          ariaLabel={formatMessage({
            id: 'taskResourceEstimates.hoursInputField'
          })}
          autoFocus
          classes={taskResourceEstimatesClasses}
          endAdornment={endAdornment}
          fullWidth={false}
          isEditible
          onChange={onChange}
          onBlur={handleOnBlur}
          onKeyDown={onKeyDown}
          value={estimatedHours}
          variant="outlined"
        />
        {canViewCost && (
          <ResourceEstimateCost
            hours={estimatedHours}
            cost={initialEstimatedCost}
            className={classes.cost}
          />
        )}
      </div>
      <div className={classes.rowContainer}>
        <Typography variant="body2" className={classes.label}>
          <FormattedMessage id="taskResourceEstimates.difference" />
        </Typography>
        <div className={classNames(classes.values, classes.diff)}>
          {hoursDifference ? (
            <FormattedDeltaChip
              isHour
              isRoundedValue={false}
              fixedDecimalScale
              formattedMessageKey="taskResourceEstimates.hoursInput"
              value={hoursDifference}
              showAddIcon={hoursDifference > 0}
              classes={differenceClasses}
            />
          ) : (
            <NoValue />
          )}
        </div>
        {canViewCost && (
          <div className={classNames(classes.values, classes.diff)}>
            {costDifference ? (
              <FormattedDeltaChip
                value={costDifference}
                prefix={currencySymbol}
                isRoundedValue={false}
                classes={differenceClasses}
                showAddIcon={costDifference > 0}
                fixedDecimalScale
              />
            ) : (
              <NoValue className={classNames(classes.values, classes.diff)} />
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

ResourceEstimatedHoursEditor.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
  resourceEstimate: PropTypes.object,
  rowIndex: PropTypes.number
};

export default ResourceEstimatedHoursEditor;
