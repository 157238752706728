import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMeContext } from '~/modules/me/useMeContext';
import useAssignedResourcePools from '~/modules/resource-management/ResourceDrawer/components/ResourceDrawerContent/components/ResourcePoolSection/hooks/useAssignedResourcePools';
import { ReadOnlyContent } from '~/modules/common/components/EditableCard';
import { TextLoading } from '~/modules/common/components/DetailsPage';
import { useDialogState } from '~/modules/common/hooks';
import ResourceUserSnackbar from '../ResourceUserSnackbar';
import { useStyles } from './useStyles';
import ResourcePoolUserDialog from './ResourcePoolUserDialog';
import { useResourcePoolsSnackbarState } from './useResourcePoolsSnackbarState';

const ResourcePoolInformation = ({ editable, userDetails }) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const classes = useStyles();

  const { formatMessage } = useIntl();
  const editAriaLabel = formatMessage({
    id: 'button.edit'
  });

  const { resourcePools, loading } = useAssignedResourcePools({
    page: 1,
    userSlug: userDetails.slug,
    pageSize: 6,
    searchFilter: {
      includeOnlyEnabled: false
    }
  });

  const { open, closeDialog, openDialog } = useDialogState();

  const {
    open: isSnackbarOpen,
    snackBarMessage,
    setSnackbarMessage
  } = useResourcePoolsSnackbarState({
    userName: userDetails.displayText
  });

  return (
    <div className={classes.container}>
      <ResourceUserSnackbar open={isSnackbarOpen} message={snackBarMessage} />
      <Typography variant="h6" className={classes.header}>
        <span className={classes.title}>
          <FormattedMessage id="resourceBasicInfoCard.resourcePools" />
        </span>
        {editable && (
          <IconButton
            className={classes.editButton}
            aria-label={
              isPsaPrpWcagFixesQ4Enabled ? (
                editAriaLabel
              ) : (
                <FormattedMessage id="button.edit" />
              )
            }
            onClick={openDialog}
          >
            <EditIcon />
          </IconButton>
        )}
      </Typography>
      {!loading && (
        <>
          <ReadOnlyContent>
            {(resourcePools || []).slice(0, 5).map(resourcePool => (
              <div key={resourcePool.id}>{resourcePool.displayText}</div>
            ))}
            {resourcePools.length > 5 && (
              <Button
                color="primary"
                className={classes.button}
                onClick={openDialog}
              >
                <FormattedMessage id="resourceBasicInfoCard.showMore" />
              </Button>
            )}
          </ReadOnlyContent>
          {!resourcePools.length && (
            <span className={classes.noValueName}>
              <FormattedMessage id="resourceBasicInfoCard.noResourcePools" />
            </span>
          )}
        </>
      )}
      {loading && <TextLoading width="80%" />}
      <ResourcePoolUserDialog
        open={open}
        onClose={closeDialog}
        userDetails={userDetails}
        editable={editable}
        setSnackbarMessage={setSnackbarMessage}
      />
    </div>
  );
};

ResourcePoolInformation.propTypes = {
  editable: PropTypes.bool,
  userDetails: PropTypes.object
};

export default ResourcePoolInformation;
