import React, { useMemo } from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  objectToFormatString,
  getLuxonJsDateFormatFromMe
} from '~/modules/common/dates/convert';

const useChipStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(2),
    pointerEvents: 'auto'
  },
  label: {
    padding: theme.spacing(0, 1)
  },
  colorPrimary: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.allocationChartStatus.overAllocated.main
  }
}));

const UserOverAllocationChip = ({ endDate, startDate }) => {
  const chipClasses = useChipStyles();
  const { formatMessage } = useIntl();
  const me = useMeContext();
  const dateFormatString = getLuxonJsDateFormatFromMe(me);

  const dateRangeValues = useMemo(
    () => ({
      startDate: objectToFormatString(startDate, dateFormatString),
      endDate: objectToFormatString(endDate, dateFormatString)
    }),
    [dateFormatString, endDate, startDate]
  );

  const tooltipTitle = useMemo(
    () => (
      <FormattedMessage
        id="taskResourceEstimates.overAllocatedChip.message"
        values={dateRangeValues}
      />
    ),
    [dateRangeValues]
  );

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        data-qe-id="userOverAllocatedChip"
        label={formatMessage({
          id: 'taskResourceEstimates.overAllocated'
        })}
        color="primary"
        size="medium"
        classes={chipClasses}
        aria-label={formatMessage({
          id: 'taskResourceEstimates.overAllocated'
        })}
        tabIndex={0}
      />
    </Tooltip>
  );
};

UserOverAllocationChip.propTypes = {
  endDate: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired
};

export default UserOverAllocationChip;
