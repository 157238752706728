const useMessageSummary = ({ allocationHours, taskAssignmentCount }) => {
  const title = {
    messageId: 'changeResourceDialog.impactedTaskSummary.title'
  };
  const messages = [
    {
      messageId: 'changeResourceDialog.impactedTaskSummary.allocationHours',
      values: { allocationHours }
    },
    {
      messageId: 'changeResourceDialog.impactedTaskSummary.taskAssignmentCount',
      values: { taskAssignmentCount }
    }
  ];

  return { title, messages };
};

export default useMessageSummary;
