import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { DescriptionSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useMeContext } from '~/modules/me/useMeContext';
import { hasErrors } from '~/util';
import { useIsBreakpointDown } from '../../hooks';
import LoadingButton from '../LoadingButton';
import CancelButton from '../CancelButton';

const editDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const EditDialog = ({
  title,
  open,
  onClose,
  children,
  formik: { handleSubmit, handleReset, errors, isSubmitting },
  ariaDialogLabelId
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const onCancelClick = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const isMobile = useIsBreakpointDown('sm');
  const classes = useStyles();

  const formError = hasErrors(errors);

  const showAriaDialogLabelId = isPsaPrpWcagFixesQ4Enabled && ariaDialogLabelId;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={editDialogRole}
      {...(showAriaDialogLabelId && {
        'aria-labelledby': `${ariaDialogLabelId}_DialogTitle`,
        'aria-describedby': `${ariaDialogLabelId}_DialogContent ${ariaDialogLabelId}_DialogActions`
      })}
    >
      <DialogTitle
        {...(showAriaDialogLabelId && {
          id: `${ariaDialogLabelId}_DialogTitle`
        })}
        className={classes.dialogTitle}
        disableTypography
      >
        <DescriptionSharp className={classes.icon} />
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent
        {...(showAriaDialogLabelId && {
          id: `${ariaDialogLabelId}_DialogContent`
        })}
      >
        {children}
      </DialogContent>
      <DialogActions
        {...(showAriaDialogLabelId && {
          id: `${ariaDialogLabelId}_DialogActions`
        })}
      >
        <CancelButton onClick={onCancelClick} disabled={isSubmitting} />
        <LoadingButton
          color="primary"
          onClick={handleSubmit}
          disabled={formError}
          isLoading={isSubmitting}
        >
          <FormattedMessage id="button.save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EditDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  formik: PropTypes.object,
  ariaDialogLabelId: PropTypes.string
};

export default connect(EditDialog);
