import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LegendItem from './LegendItemV2';

const useStyles = makeStyles(theme => ({
  legendHeader: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0, 0.5, 2)
  }
}));

const LegendSection = ({ legendHeaderId, legendSection }) => {
  const classes = useStyles();

  return (
    <>
      {legendHeaderId && (
        <Typography className={classes.legendHeader} component="div">
          <FormattedMessage id={legendHeaderId} />
        </Typography>
      )}
      {legendSection.map(
        ({ key, label, color, className, barType, isProgressBarLegend }) => (
          <LegendItem
            key={key}
            label={label}
            color={color}
            legendClassName={className}
            barType={barType}
            isProgressBarLegend={isProgressBarLegend}
          />
        )
      )}
    </>
  );
};

LegendSection.propTypes = {
  legendHeaderId: PropTypes.string,
  legendSection: PropTypes.arrayOf(
    PropTypes.shape({
      isProgressBarLegend: PropTypes.bool,
      barType: PropTypes.oneOf(['dashedBar', 'roundBar', 'squareBar']),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      className: PropTypes.string,
      color: PropTypes.string
    }).isRequired
  ).isRequired
};

export default LegendSection;
