import React, { useCallback, useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { getError } from '~/util';
import TaskResourceEstimatesHeader from './TaskResourceEstimatesHeader';
import TaskResourceEstimatesFooter from './TaskResourceEstimatesFooter';
import TaskResourceEstimateRow from './TaskResourceEstimateRow';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: theme.spacing(89)
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  emptyMessage: {
    color: theme.palette.text.secondary
  }
}));

const getValueOrDefault = value => value || 0;

export const getTotalEstimatedValues = taskResourceEstimates =>
  taskResourceEstimates.reduce(
    (retVal, { estimatedHours, resource, role }) => ({
      totalEstimatedHours:
        retVal.totalEstimatedHours + getValueOrDefault(estimatedHours),
      totalEstimatedAmount:
        retVal.totalEstimatedAmount +
        getValueOrDefault(
          resource
            ? resource.userRateInProjectCurrency?.amount
            : role?.currentRate?.amount
        ) *
          getValueOrDefault(estimatedHours)
    }),
    { totalEstimatedHours: 0, totalEstimatedAmount: 0 }
  );

const getDynamicDataColumnWidth = dialogWidth => {
  const resourcingAreaWidth = dialogWidth - 80;
  const removeColumnWidth = 5 * 8;
  const availableSpace = resourcingAreaWidth - removeColumnWidth;
  const dataColumnCount = 3;

  return Math.floor(Math.max(18 * 12, availableSpace / dataColumnCount));
};

export const TaskResourceEstimates = ({
  taskEstimatedCost,
  taskEstimatedHours,
  onResourceEstimateAdd,
  resourceEstimates,
  setFieldValue,
  projectId,
  projectSlug,
  projectCurrency,
  errors,
  dialogWidth,
  canViewCost
}) => {
  const classes = useStyles();
  const dynamicDataColumnWidth = getDynamicDataColumnWidth(dialogWidth);

  const styleProps = useMemo(
    () => ({
      gridItemStyle: {
        width: `${dynamicDataColumnWidth}px`
      },
      removeColumnStyle: {
        display: 'flex',
        alignItems: `center`,
        width: `40px`,
        paddingLeft: `0px`
      }
    }),
    [dynamicDataColumnWidth]
  );

  const { formatMessage } = useIntl();
  const { totalEstimatedHours, totalEstimatedAmount } = getTotalEstimatedValues(
    resourceEstimates
  );

  const totalEstimatedCost = useMemo(
    () => ({
      amount: totalEstimatedAmount,
      currencySymbol: projectCurrency?.displayText
    }),
    [totalEstimatedAmount, projectCurrency]
  );

  const onResourceEstimateChange = useCallback(
    ({ assignment: modifiedAssignment, isDelete }) => {
      const updatedResourceAssignments = resourceEstimates.reduce(
        (acc, assignment) => {
          if (assignment.rowId === modifiedAssignment.rowId) {
            if (isDelete) {
              return acc;
            }
            acc.push(modifiedAssignment);
          } else {
            acc.push(assignment);
          }

          return acc;
        },
        []
      );

      setFieldValue('resourceEstimates', updatedResourceAssignments);
    },
    [resourceEstimates, setFieldValue]
  );
  const selectedResources = resourceEstimates.map(
    estimate => estimate?.resource?.id
  );

  const taskEstimatedCostWithDefaultAmount = useMemo(
    () => ({
      ...taskEstimatedCost,
      amount: getValueOrDefault(taskEstimatedCost?.amount)
    }),
    [taskEstimatedCost]
  );

  return (
    <div className={classes.container}>
      {resourceEstimates.length === 0 && (
        <>
          <Typography className={classes.title}>
            {formatMessage({ id: 'taskResourceAssignments.resources' })}
          </Typography>
          <span className={classes.emptyMessage}>
            {formatMessage({ id: 'taskResourceAssignments.emptyMessage' })}
          </span>
        </>
      )}
      {resourceEstimates.length > 0 && (
        <TaskResourceEstimatesHeader styleProps={styleProps} />
      )}
      {(resourceEstimates || []).map((resourceEstimate, index) => (
        <TaskResourceEstimateRow
          key={resourceEstimate.rowId}
          styleProps={styleProps}
          onResourceEstimateChange={onResourceEstimateChange}
          resourceEstimate={resourceEstimate}
          projectSlug={projectSlug}
          projectId={projectId}
          projectCurrency={projectCurrency}
          selectedResources={selectedResources}
          error={getError(errors, `resourceEstimates[${index}]`)}
          canViewCost={canViewCost}
        />
      ))}
      <TaskResourceEstimatesFooter
        taskEstimatedCost={taskEstimatedCostWithDefaultAmount}
        styleProps={styleProps}
        taskEstimatedHours={getValueOrDefault(taskEstimatedHours)}
        onResourceEstimateAdd={onResourceEstimateAdd}
        showSummary={resourceEstimates.length > 0}
        totalEstimatedHours={totalEstimatedHours}
        totalEstimatedCost={totalEstimatedCost}
        canViewCost={canViewCost}
        projectCurrency={projectCurrency}
      />
    </div>
  );
};

TaskResourceEstimates.propTypes = {
  errors: PropTypes.object,
  taskEstimatedHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onResourceEstimateAdd: PropTypes.func,
  resourceEstimates: PropTypes.array,
  setFieldValue: PropTypes.func,
  taskEstimatedCost: PropTypes.object,
  projectId: PropTypes.string,
  projectSlug: PropTypes.string,
  projectCurrency: PropTypes.object,
  dialogWidth: PropTypes.number,
  canViewCost: PropTypes.bool
};

export default TaskResourceEstimates;
