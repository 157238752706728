/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  IconButton,
  Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditSharp';
import { useMeContext } from '~/modules/me/useMeContext';
import { getEffectiveSchedule } from '~/modules/common/dates/schedule';
import { NoDataItem, Date } from '~/modules/common/components';
import { ReadOnlyCardContentLoading } from '~/modules/common/components/DetailsPage/Card';
import { RoleIcon } from '~/modules/common/components/Icons';
import useStyles from './useStyles';

import RoleItem from './RoleItem';

const avatar = <RoleIcon />;

export const RoleScheduleReadOnlyCard = ({
  userDetails = {},
  editable,
  onEdit,
  isResourceDetailsLoading = false,
  showCardLayout = true
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();
  const intl = useIntl();
  const classes = useStyles();
  const schedules = userDetails.roleScheduleDetails;
  const recentSchedule =
    schedules && schedules.length > 0 && getEffectiveSchedule(schedules);

  const primaryRole =
    recentSchedule &&
    (recentSchedule.projectRoles || []).find(role => role.isPrimary);
  const additionalRoles =
    recentSchedule &&
    (recentSchedule.projectRoles || []).filter(role => !role.isPrimary);

  const effectiveDate = recentSchedule && recentSchedule.effectiveDate;

  const roleAriaLabel = intl.formatMessage({
    id: 'button.editRole'
  });

  const editButton = useMemo(
    () => (
      <IconButton
        data-qe-id="RoleReadOnlyCard_Edit"
        aria-label={roleAriaLabel}
        onClick={onEdit}
      >
        <EditIcon />
      </IconButton>
    ),
    [onEdit, roleAriaLabel]
  );

  const cardHeaderClasses = useMemo(
    () => ({
      title: classes.title,
      avatar: classes.avatar,
      root: classes.headerRoot,
      action: classes.headerAction
    }),
    [classes.avatar, classes.headerRoot, classes.title, classes.headerAction]
  );

  const cardContentClasses = useMemo(() => ({ root: classes.contentRoot }), [
    classes.contentRoot
  ]);

  const GetRoleContent = () => (
    <div>
      <div className={classes.content}>
        <div className={classes.list}>
          <div className={classes.category}>
            <FormattedMessage id="roleInfoCard.primary" />
          </div>
          {primaryRole ? (
            <List data-qe-id="PrimaryRoleList" className={classes.roleList}>
              <RoleItem
                dataQeId="PrimaryRole"
                key={primaryRole.projectRole.name}
                role={primaryRole.projectRole}
                className={classes.role}
              />
            </List>
          ) : (
            <NoDataItem>
              <FormattedMessage id="roleInfoCard.noRoleAssigned" />
            </NoDataItem>
          )}
        </div>

        {additionalRoles && additionalRoles.length > 0 && (
          <div className={classes.list}>
            <div className={classes.category}>
              <FormattedMessage id="roleInfoCard.additional" />
            </div>
            <List data-qe-id="AdditionalRoleList" className={classes.roleList}>
              {additionalRoles.map((role, index) => (
                <RoleItem
                  dataQeId={`AdditionalRole_${index + 1}`}
                  key={role.projectRole.name}
                  role={role.projectRole}
                  className={classes.role}
                />
              ))}
            </List>
          </div>
        )}
      </div>
      {recentSchedule && recentSchedule.effectiveDate && (
        <div className={classes.asOfInfo}>
          <span className={classes.effectiveDate}>
            <FormattedMessage id="roleInfoCard.asOfDate" />
            &nbsp;
            <Date value={effectiveDate} className={classes.effectiveDate} />
          </span>
          <Button
            size="small"
            className={classes.historyButton}
            onClick={onEdit}
          >
            <FormattedMessage id="roleInfoCard.history" />
          </Button>
        </div>
      )}
    </div>
  );

  return showCardLayout ? (
    <Card className={classes.roles}>
      <CardHeader
        classes={cardHeaderClasses}
        avatar={avatar}
        title={intl.formatMessage({
          id: 'roleInfoCard.role'
        })}
        action={editable && editButton}
        {...(isPsaPrpWcagFixesQ4Enabled && {
          role: 'heading',
          'aria-level': 2
        })}
      />
      <CardContent classes={cardContentClasses}>
        {isResourceDetailsLoading ? (
          <ReadOnlyCardContentLoading />
        ) : (
          GetRoleContent()
        )}
      </CardContent>
    </Card>
  ) : (
    GetRoleContent()
  );
};

RoleScheduleReadOnlyCard.propTypes = {
  userDetails: PropTypes.object,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  isResourceDetailsLoading: PropTypes.bool,
  showCardLayout: PropTypes.bool
};

export default RoleScheduleReadOnlyCard;
