import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  headerLabel: {
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(1)
  },
  estimated: {
    textAlign: 'right'
  },
  container: {
    marginBottom: 0,
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.table.border}`
  }
}));

export const TaskResourceEstimatesHeader = ({
  styleProps: { gridItemStyle, removeColumnStyle }
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid container className={classes.container}>
      <Grid item style={gridItemStyle}>
        <Typography
          className={classes.headerLabel}
          variant="body2"
          color="textSecondary"
          noWrap
        >
          {formatMessage({ id: 'taskResourceAssignments.role' })}
        </Typography>
      </Grid>
      <Grid item style={gridItemStyle}>
        <Typography
          className={classes.headerLabel}
          variant="body2"
          color="textSecondary"
          noWrap
        >
          {formatMessage({ id: 'taskResourceAssignments.resource' })}
        </Typography>
      </Grid>
      <Grid item style={gridItemStyle}>
        <Typography
          className={`${classes.headerLabel} ${classes.estimated}`}
          variant="body2"
          color="textSecondary"
          noWrap
        >
          {formatMessage({ id: 'taskResourceAssignments.estimated' })}
        </Typography>
      </Grid>
      <Grid item style={removeColumnStyle}></Grid>
    </Grid>
  );
};

TaskResourceEstimatesHeader.propTypes = {
  styleProps: PropTypes.object
};

export default TaskResourceEstimatesHeader;
