import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexBasis: 240,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 0, 1),
    borderLeft: `1px solid ${theme.palette.table.border}`
  },
  resourceMatchTextColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(1),
    lineHeight: '0.9rem'
  },
  resourceMatchDisplayText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'bold'
  },
  resourceMatchAlternativeText: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.primary.main
  },
  delaStarIcon: {
    fill: theme.palette.Dela.darkIcon,
    margin: theme.spacing(0, 1)
  },
  checkCircleIcon: {
    fontSize: theme.spacing(2.75),
    fill: theme.palette.grey[400],
    margin: theme.spacing(0, 2),
    '&:hover': {
      fill: theme.palette.success.main
    }
  },
  proposePopper: {
    marginTop: theme.spacing(-1)
  },
  proposeTooltip: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1, 2)
  },
  allocation: {
    flexShrink: 1,
    borderLeft: `1px solid ${theme.palette.table.border}`,
    alignSelf: 'stretch',
    padding: 0,
    margin: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    '&:last-child': {
      borderRight: 'none'
    },
    flexBasis: 240,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
