/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Formik } from 'formik';
import Receipt from '@material-ui/icons/ReceiptSharp';
import { useIsBreakpointDown } from '~/modules/common/hooks';
import ModuleDialogTitle from '~/modules/common/components/ModuleDialogTitle';
import { useMeContext } from '~/modules/me/useMeContext';
import { useFormState } from './hooks';
import EditCertificateDialogContent from './EditCertificateDialogContent';

export const EditCertificateDialog = ({
  open,
  userUri,
  onCancel,
  certificate
}) => {
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const {
    onSubmit,
    initialValues,
    deleteCertificate,
    validationSchema
  } = useFormState({
    userUri,
    onCancel,
    certificate,
    setSubmitError,
    setIsSubmitting
  });

  const isMobile = useIsBreakpointDown('sm');

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      open={open}
      onClose={onCancel}
      {...(isPsaPrpWcagFixesQ4Enabled && {
        'aria-labelledby': 'EditCertificateDialog_Title',
        'aria-describedby': 'EditCertificateDialog_Content'
      })}
    >
      <DialogTitle id="EditCertificateDialog_Title">
        <ModuleDialogTitle
          title={
            <FormattedMessage id="resourceCertificatesAndLicensesCard.editTitle" />
          }
          icon={<Receipt />}
        />
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <EditCertificateDialogContent
          onCancel={onCancel}
          submitError={submitError}
          isSubmitting={isSubmitting}
          deleteCertificate={deleteCertificate}
        />
      </Formik>
    </Dialog>
  );
};

EditCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userUri: PropTypes.string,
  onCancel: PropTypes.func,
  certificate: PropTypes.object
};

export default EditCertificateDialog;
