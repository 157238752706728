import React from 'react';
import { PropTypes } from 'prop-types';
import EstimateItemMetric from './EstimateItemMetric';

export const PlaceholderEstimateContent = ({
  initialEstimatedHours,
  initialEstimatedCost
}) => {
  return (
    <EstimateItemMetric
      formatMessageKey="taskResourceEstimates.estimate"
      hours={initialEstimatedHours}
      cost={initialEstimatedCost}
    />
  );
};

PlaceholderEstimateContent.propTypes = {
  initialEstimatedCost: PropTypes.object,
  initialEstimatedHours: PropTypes.number
};

export default PlaceholderEstimateContent;
