import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import ProgressItemMetric from './ProgressItemMetric';
import ProgressItemVariation from './ProgressItemVariation';
import ProgressItemMetricDetail from './ProgressItemMetricDetail';
import ProgressItemVariationDetail from './ProgressItemVariationDetail';
import ProgressProjectRisk from './ProgressProjectRisk';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  }
}));

export const ProgressChart = ({
  projectId,
  endDate,
  budget,
  actual,
  estimatedAtCompletion,
  estimatedBudgetRemaining,
  budgetRemaining,
  estimatedRemaining,
  percentBudgetBurned,
  percentScopeCompleted,
  burnedAlertMessage,
  burnedId,
  scopeCompletedAlertMessage,
  scopeCompletedId,
  fixedDecimalScale,
  suffix,
  prefix,
  dataQeId,
  resourceKeyOverrides,
  isLoading = false,
  error
}) => {
  const classes = useStyles();
  const {
    featureFlags: { isPsaProjectRiskEnabled }
  } = useMeContext();

  return (
    <>
      <Grid container className={classes.container} spacing={2}>
        <Grid
          item
          className={classes.item}
          lg={isPsaProjectRiskEnabled ? 3 : 4}
          md={isPsaProjectRiskEnabled ? 3 : 4}
          sm={6}
          xs={6}
        >
          <ProgressItemMetric
            title="kpiCharts.budget"
            value={percentBudgetBurned}
            dataQeId={`${dataQeId}_BudgetBurnedPercentage`}
            isLoading={isLoading}
            error={error}
          />
          <ProgressItemMetricDetail
            message={burnedAlertMessage}
            id={burnedId}
            denominator={budget}
            numerator={actual}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            prefix={prefix}
            isLoading={isLoading}
            error={error}
          />
        </Grid>
        <Grid
          item
          className={classes.item}
          lg={isPsaProjectRiskEnabled ? 3 : 4}
          md={isPsaProjectRiskEnabled ? 3 : 4}
          sm={6}
          xs={6}
        >
          <ProgressItemMetric
            title="kpiCharts.scope"
            value={percentScopeCompleted}
            dataQeId={`${dataQeId}_ScopeCompletedPercentage`}
            isLoading={isLoading}
            error={error}
          />
          <ProgressItemMetricDetail
            message={scopeCompletedAlertMessage}
            id={scopeCompletedId}
            denominator={estimatedAtCompletion}
            numerator={actual}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            prefix={prefix}
            isLoading={isLoading}
            error={error}
          />
        </Grid>
        <Grid
          item
          className={classes.item}
          lg={isPsaProjectRiskEnabled ? 3 : 4}
          md={isPsaProjectRiskEnabled ? 3 : 4}
          sm={isPsaProjectRiskEnabled ? 6 : 12}
          xs={isPsaProjectRiskEnabled ? 6 : 12}
        >
          <ProgressItemVariation
            title="kpiCharts.variation"
            value={estimatedBudgetRemaining}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            prefix={prefix}
            dataQeId={`${dataQeId}_BudgetVsScope`}
            isLoading={isLoading}
            error={error}
          />
          <ProgressItemVariationDetail
            budgetRemaining={budgetRemaining}
            estimatedRemaining={estimatedRemaining}
            fixedDecimalScale={fixedDecimalScale}
            suffix={suffix}
            prefix={prefix}
            resourceKeyOverrides={resourceKeyOverrides}
            isLoading={isLoading}
            error={error}
          />
        </Grid>
        {isPsaProjectRiskEnabled && (
          <Grid
            item
            className={classes.item}
            lg={isPsaProjectRiskEnabled ? 3 : 4}
            md={isPsaProjectRiskEnabled ? 3 : 4}
            sm={6}
            xs={6}
          >
            <ProgressProjectRisk projectId={projectId} endDate={endDate} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

ProgressChart.propTypes = {
  projectId: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  budget: PropTypes.number,
  actual: PropTypes.number,
  estimatedAtCompletion: PropTypes.number,
  estimatedBudgetRemaining: PropTypes.number,
  budgetRemaining: PropTypes.number,
  estimatedRemaining: PropTypes.number,
  percentBudgetBurned: PropTypes.number,
  percentScopeCompleted: PropTypes.number,
  burnedAlertMessage: PropTypes.string.isRequired,
  burnedId: PropTypes.string.isRequired,
  scopeCompletedAlertMessage: PropTypes.string.isRequired,
  scopeCompletedId: PropTypes.string.isRequired,
  fixedDecimalScale: PropTypes.bool,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  dataQeId: PropTypes.string,
  resourceKeyOverrides: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

export default ProgressChart;
