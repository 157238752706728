import { useActiveRolesFromCache } from '~/modules/common/hooks/useRoles';

export const getPrimaryRole = ({
  selectedResource,
  getActiveRolesFromCache
}) => {
  const projectRole = selectedResource?.roles?.find(r => r.isPrimary)
    ?.projectRole;

  if (projectRole) {
    const activeRoles = getActiveRolesFromCache();
    const primaryRole = activeRoles?.find(r => r.id === projectRole.uri);

    if (primaryRole) return primaryRole;

    return {
      id: projectRole.uri,
      displayText: projectRole.name
    };
  }

  return null;
};

const useTaskResourceEstimateChangeHandlers = ({
  onResourceEstimateChange,
  resourceEstimate,
  rolesQueryVariablesStorageKey
}) => {
  const { getActiveRolesFromCache } = useActiveRolesFromCache({
    queryVariablesStorageKey: rolesQueryVariablesStorageKey
  });

  return {
    onRemove: () => {
      onResourceEstimateChange({
        assignment: resourceEstimate,
        isDelete: true
      });
    },
    onEstimatedHoursChange: hours =>
      onResourceEstimateChange({
        assignment: {
          ...resourceEstimate,
          estimatedHours: hours
        }
      }),
    onResourceChange: selectedResource =>
      onResourceEstimateChange({
        assignment: {
          ...resourceEstimate,
          resource: selectedResource,
          role:
            resourceEstimate.role ||
            getPrimaryRole({
              selectedResource,
              getActiveRolesFromCache
            })
        }
      }),
    onRoleChange: role =>
      onResourceEstimateChange({
        assignment: {
          ...resourceEstimate,
          role
        }
      }),
    onAllocationDateChange: dateRange =>
      onResourceEstimateChange({
        assignment: {
          ...resourceEstimate,
          dateRange
        }
      })
  };
};

export default useTaskResourceEstimateChangeHandlers;
