import React from 'react';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  field: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
}));

export const ProgressItemDetailLoading = () => {
  const classes = useStyles();

  return (
    <Skeleton
      variant="rect"
      width={128}
      height={32}
      className={classes.field}
    />
  );
};

export default ProgressItemDetailLoading;
