import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  DialogContent,
  DialogActions,
  makeStyles,
  Button
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  errors: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FDECEA'
  },
  error: { color: '#611A15' }
}));

export const AddProjectErrorDialogContent = ({
  onClose,
  projectSlug,
  errors,
  showGoToNewProject = false
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToNewProject = React.useCallback(() => {
    history.push(`/projects/${projectSlug}?edit=true`);
    if (onClose) onClose();
  }, [projectSlug, history, onClose]);

  const errorTitle = showGoToNewProject
    ? 'addProject.projectAddedWithErrors'
    : 'addProject.errorsOccured';

  return (
    <>
      <DialogContent className={classes.errors}>
        <Typography className={classes.error} variant="subtitle1" gutterBottom>
          <FormattedMessage id={errorTitle} />
        </Typography>
        <ul>
          {errors.map(e => (
            <li classes={classes.error} key={e.id || uuidv4()}>
              <Typography variant="body2" gutterBottom>
                {e.displayText}
              </Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="addProject.close" />
        </Button>
        {showGoToNewProject && (
          <Button color="primary" onClick={handleGoToNewProject}>
            <FormattedMessage id="addProject.goToNewProject" />
          </Button>
        )}
      </DialogActions>
    </>
  );
};

AddProjectErrorDialogContent.propTypes = {
  errors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  projectSlug: PropTypes.string,
  showGoToNewProject: PropTypes.bool
};
