import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import QuickAllocationActions2 from '../../../../QuickAllocationActions/QuickAllocationsActions2';

const LeftComponentModel0 = ({
  actualsClasses,
  allocatedProject,
  classes,
  committedResourceAllocations,
  handleRemoveResourceAllocation,
  isShowActualEnabled,
  projectsSlugLoading,
  resourceAllocationReferencesForProject,
  resourceUser,
  rowStyles,
  setSnackBarState,
  showResourceActions
}) => {
  return (
    <>
      {showResourceActions || Boolean(allocatedProject.project.slug) ? (
        <QuickAllocationActions2
          allocatedProject={allocatedProject}
          resourceAllocations={committedResourceAllocations}
          resourceUser={resourceUser}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          setSnackBarState={setSnackBarState}
          showResourceActions={showResourceActions}
        />
      ) : projectsSlugLoading ? (
        <div className={classes.loadingMenuItems}>
          <Skeleton variant="rect" width={10} height={12} />
        </div>
      ) : (
        <div className={classes.actionPlaceHolder} />
      )}
      <div
        className={
          showResourceActions ? classes.rowCell : classes.readOnlyProject
        }
      >
        <Tooltip title={allocatedProject.project.displayText}>
          <div className={classNames(classes.text, classes.projectText)}>
            {allocatedProject.project.displayText}
          </div>
        </Tooltip>
      </div>
      <div className={classes.rolesContainer}>
        {(resourceAllocationReferencesForProject || []).map(
          ({ role, key, allocationId }, index, array) => (
            <div
              className={classNames(
                role?.id !== array[index + 1]?.role?.id
                  ? isShowActualEnabled && allocationId
                    ? actualsClasses.roleRowActualsCell
                    : rowStyles.roleRowCell
                  : null
              )}
              key={key}
            >
              <Tooltip title={role?.displayText || ''}>
                <div className={classes.roleText}>
                  {role?.displayText ? (
                    role.displayText
                  ) : (
                    <FormattedMessage id="quickAllocation.noRole" />
                  )}
                </div>
              </Tooltip>
            </div>
          )
        )}
      </div>
    </>
  );
};

LeftComponentModel0.propTypes = {
  actualsClasses: PropTypes.object,
  allocatedProject: PropTypes.object,
  classes: PropTypes.object,
  committedResourceAllocations: PropTypes.array,
  handleRemoveResourceAllocation: PropTypes.func,
  isShowActualEnabled: PropTypes.bool,
  projectsSlugLoading: PropTypes.bool,
  resourceAllocationReferencesForProject: PropTypes.array,
  resourceUser: PropTypes.object,
  rowStyles: PropTypes.object,
  setSnackBarState: PropTypes.func,
  showResourceActions: PropTypes.bool
};

export default LeftComponentModel0;
