import React, { useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { FieldLabel } from '../../common/components';
import EditorPopoverTooltip from './EditorPopoverTooltip';
import AvailabilityInfoVariance from './AvailabilityInfoVariance';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    cursor: 'help',
    color: theme.palette.text.secondary
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  error: {
    color: theme.palette.error.main
  }
}));

const AvailabilityInfoComponent = ({
  endDate,
  startDate,
  newRemainingProjectAllocationHours,
  otherTaskAllocationHours,
  newProjectAllocationHours,
  taskAllocationHours
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const tooltipTitle = useMemo(
    () => (
      <EditorPopoverTooltip
        endDate={endDate}
        newProjectAllocationHours={newProjectAllocationHours}
        otherTaskAllocationHours={otherTaskAllocationHours}
        taskAllocationHours={taskAllocationHours}
        startDate={startDate}
        allocationRemainingVariance={newRemainingProjectAllocationHours}
      />
    ),
    [
      newRemainingProjectAllocationHours,
      endDate,
      newProjectAllocationHours,
      otherTaskAllocationHours,
      startDate,
      taskAllocationHours
    ]
  );

  return (
    <div className={classes.container}>
      <AvailabilityInfoVariance hours={newRemainingProjectAllocationHours} />
      <FieldLabel
        variant="body2"
        color="textSecondary"
        messageId="taskAllocationEditor.remainingProject"
        className={classes.container}
      />
      <Tooltip tabIndex={0} title={tooltipTitle} placement="right">
        <InfoIcon
          className={classNames(classes.infoIcon, {
            [classes.error]: newRemainingProjectAllocationHours < 0
          })}
          aria-label={formatMessage({ id: 'taskAllocationEditor.details' })}
          fontSize="small"
        />
      </Tooltip>
    </div>
  );
};

AvailabilityInfoComponent.propTypes = {
  endDate: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  newRemainingProjectAllocationHours: PropTypes.number,
  newProjectAllocationHours: PropTypes.number,
  otherTaskAllocationHours: PropTypes.number,
  taskAllocationHours: PropTypes.number
};

export default AvailabilityInfoComponent;
