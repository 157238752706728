import { useCallback } from 'react';
import { mergePeriodRulesIntoScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import {
  buildTaskAllocationPeriodsForUpdatedAllocation,
  getScheduleRulesForOptimisticResponse
} from '~/modules/common/components/TaskDrawer/common/hooks/taskAllocationSaveUtil';
import { getScheduleRulesFromAllocationPeriods } from '~/modules/common/hooks/resourcing';

export const getUpdatedTaskAllocation = ({
  userTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules,
  taskResourceAllocation,
  periodStartDate,
  periodEndDate,
  updatedTaskAllocationPeriodHours,
  effectiveUserScheduleSeriesData,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const periodScheduleRules = isRmpTaskAllocationPhase2Enabled
    ? getScheduleRulesForOptimisticResponse({
        periodStartDate,
        periodEndDate,
        updatedTaskAllocationPeriodHours
      })
    : getScheduleRulesFromAllocationPeriods(
        buildTaskAllocationPeriodsForUpdatedAllocation({
          resourceAllocationScheduleRules,
          effectiveUserScheduleSeriesData,
          userTaskAllocationsSummaryScheduleRules,
          currentTaskAllocationScheduleRules:
            taskResourceAllocation?.scheduleRules,
          periodStartDate,
          periodEndDate,
          updatedTaskAllocationPeriodHours
        })
      );

  const updatedScheduleRules = mergePeriodRulesIntoScheduleRules(
    taskResourceAllocation?.scheduleRules,
    true
  )(periodScheduleRules);

  return {
    ...taskResourceAllocation,
    scheduleRules: updatedScheduleRules
  };
};

export const useOnChangeHandlers = ({
  userTaskAllocationsSummaryScheduleRules,
  resourceAllocationScheduleRules,
  taskResourceUserAllocation,
  periodStartDate,
  periodEndDate,
  onChange,
  effectiveUserScheduleSeriesData,
  periodTaskAllocatedHours,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const onAllocationChange = useCallback(
    updatedTaskAllocationPeriodHours => {
      const updatedAllocation = getUpdatedTaskAllocation({
        userTaskAllocationsSummaryScheduleRules,
        resourceAllocationScheduleRules,
        taskResourceAllocation: taskResourceUserAllocation,
        periodStartDate,
        periodEndDate,
        updatedTaskAllocationPeriodHours,
        effectiveUserScheduleSeriesData,
        isRmpTaskAllocationPhase2Enabled
      });

      onChange({
        allocation: updatedAllocation,
        periodDetails: {
          periodStartDate,
          periodEndDate,
          newPeriodTaskAllocatedHours: updatedTaskAllocationPeriodHours,
          periodTaskAllocatedHours
        }
      });
    },
    [
      userTaskAllocationsSummaryScheduleRules,
      resourceAllocationScheduleRules,
      taskResourceUserAllocation,
      periodStartDate,
      periodEndDate,
      effectiveUserScheduleSeriesData,
      isRmpTaskAllocationPhase2Enabled,
      onChange,
      periodTaskAllocatedHours
    ]
  );

  return {
    onAllocationChange
  };
};
