import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Decimal } from '~/modules/common/components';
import { isNullOrUndefined } from '~/modules/common/util';
import { ResourceEstimateCost } from '../ResourceEstimateCost';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1)
  },
  label: {
    minWidth: theme.spacing(22)
  },
  values: {
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    minWidth: theme.spacing(16),
    padding: theme.spacing(0, 0.5, 0, 0)
  }
}));

const PopoverItemMetric = ({
  formattedMessageKey,
  hours,
  cost,
  canViewCost,
  showNoValue = false
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.label}>
        <FormattedMessage id={formattedMessageKey} />
      </Typography>
      {showNoValue && isNullOrUndefined(hours) ? (
        <Typography className={classes.values}>
          <FormattedMessage id="taskResourceEstimates.notApplicable" />
        </Typography>
      ) : (
        <Decimal
          className={classes.values}
          suffix={` ${formatMessage({
            id: 'taskResourceEstimates.hoursAdornment'
          })}`}
          value={hours}
        />
      )}
      {canViewCost && (
        <ResourceEstimateCost
          className={classes.values}
          hours={hours}
          cost={cost}
        />
      )}
    </div>
  );
};

PopoverItemMetric.propTypes = {
  formattedMessageKey: PropTypes.string,
  hours: PropTypes.number,
  cost: PropTypes.object,
  canViewCost: PropTypes.bool,
  showNoValue: PropTypes.bool
};

export default PopoverItemMetric;
