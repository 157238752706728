import React from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardSharp';
import { useIntl } from 'react-intl';
import User from '~/modules/common/components/User';
import { FieldLabel } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor';
import { isoStringToObject } from '~/modules/common/dates/convert';
import { useHasPermission } from '~/modules/common/permissions';
import ProjectTeamMemberDropdown from '../../ProjectTeamMemberDropdown';
import ResourceUsersDropdown from '../../ResourceUsersDropdown';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary
  },
  container: {
    paddingTop: '0px !important',
    backgroundColor: theme.palette.grey[50]
  },
  newUser: {
    minWidth: '45%',
    maxWidth: '54%'
  },
  arrowForwardIcon: {
    paddingTop: `${theme.spacing(2.5)}px !important`,
    marginLeft: 'auto'
  },
  existingResource: {
    maxWidth: '37%'
  }
}));

const useUserStyles = makeStyles(theme => ({
  name: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium
  },
  container: {
    padding: 0
  }
}));

export const useResourceUserDropdownStyles = makeStyles(theme => ({
  popper: {
    width: 'max-content !important'
  }
}));

const ResourceUserSelectionRow = ({
  existingResourceUser,
  newResourceUser,
  onResourceUserChange,
  projectId,
  role,
  taskId,
  taskResourceUserAllocation,
  startDate,
  endDate
}) => {
  const userClasses = useUserStyles();
  const classes = useStyles();
  const resourceUserDropdownClasses = useResourceUserDropdownStyles();

  const { formatMessage } = useIntl();
  const isResourceManager = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });
  const newResourceLabel = formatMessage({
    id: 'changeTaskResourceDialog.newResource'
  });
  const errorHelperText = formatMessage({
    id: 'changeTaskResourceDialog.noResourceErrorText'
  });

  return (
    <Grid item container className={classes.container}>
      <Grid item className={classes.existingResource}>
        <FieldLabel
          variant="caption"
          messageId="changeTaskResourceDialog.existingResource"
          className={classes.label}
        />
        <User user={existingResourceUser} size="small" classes={userClasses} />
      </Grid>
      <Grid item className={classes.arrowForwardIcon}>
        <ArrowForwardIcon />
      </Grid>
      <Grid item className={classes.newUser}>
        {isResourceManager ? (
          <ResourceUsersDropdown
            autoFocus
            ariaLabel={newResourceLabel}
            assignedRole={role}
            classes={resourceUserDropdownClasses}
            endDate={isoStringToObject(endDate)}
            label={newResourceLabel}
            onResourceChange={onResourceUserChange}
            projectId={projectId}
            startDate={isoStringToObject(startDate)}
            taskId={taskId}
            value={newResourceUser}
            variant="filled"
            hideAvailability={Boolean(!taskResourceUserAllocation)}
            hasError={!newResourceUser}
            helperText={!newResourceUser ? errorHelperText : ''}
            initialEstimatedHours={taskResourceUserAllocation?.totalHours}
            disabledResources={[existingResourceUser]}
          />
        ) : (
          <ProjectTeamMemberDropdown
            autoFocus
            assignedRole={role}
            ariaLabel={newResourceLabel}
            label={newResourceLabel}
            onResourceChange={onResourceUserChange}
            projectId={projectId}
            taskId={taskId}
            value={newResourceUser}
            variant="filled"
            hasError={!newResourceUser}
            helperText={!newResourceUser ? errorHelperText : ''}
          />
        )}
      </Grid>
    </Grid>
  );
};

ResourceUserSelectionRow.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  endDate: PropTypes.string,
  newResourceUser: PropTypes.object,
  onResourceUserChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  taskResourceUserAllocation: PropTypes.object,
  role: PropTypes.object,
  startDate: PropTypes.string
};

export default ResourceUserSelectionRow;
