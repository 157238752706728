import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import { useDecimalPrecision } from '~/modules/common/components/Decimal/usePrecision';
import ProgressItemTooltip from './ProgressItemTooltip';
import ProgressBar from './ProgressBar';
import ProgressItemLoading from './ProgressItemLoading';

const useStyles = makeStyles(theme => ({
  columnItems: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.subtitle1.fontFamily,
    color: theme.palette.text.secondary,
    textAlign: 'center'
  },
  keyValue: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  keyValueExceeded: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.dark
  },
  notAvailable: {
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0)
    }
  }
}));

export const ProgressItemMetric = ({
  dataQeId,
  title,
  value,
  isLoading = false,
  error
}) => {
  const classes = useStyles();
  const displayPercentage = useDecimalPrecision({
    value,
    precision: 2
  });

  return (
    <div className={classes.columnItems}>
      <Typography className={classes.title}>
        <FormattedMessage id={title} />
      </Typography>
      {isLoading || error ? (
        <div
          data-qe-id={`${dataQeId}_Value`}
          className={classNames(classes.keyValue, classes.notAvailable)}
        >
          {isLoading && <ProgressItemLoading />}
        </div>
      ) : value !== null ? (
        <>
          <ProgressItemTooltip
            className={classNames(classes.keyValue, {
              [classes.keyValueExceeded]: displayPercentage > 100
            })}
            value={value}
            suffix="%"
            fixedDecimalScale={false}
            dataQeId={`${dataQeId}_Value`}
          />
          <ProgressBar value={value} />
        </>
      ) : (
        <div
          data-qe-id={`${dataQeId}_Value`}
          className={classNames(classes.keyValue, classes.notAvailable)}
        >
          <FormattedMessage id="dashboardProgressChart.na" />
        </div>
      )}
    </div>
  );
};

ProgressItemMetric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  dataQeId: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

export default ProgressItemMetric;
