import { roundToDecimals } from './scheduleUtil';

export const convertToComparableValue = ({
  hours,
  scheduledHours,
  isHoursMode
}) => {
  const value = isHoursMode ? hours : (hours * 100) / scheduledHours;

  return roundToDecimals(value);
};

export const compareEditorValue = ({
  isHoursMode,
  initialAllocatedHours,
  scheduledHours,
  inputValue
}) => {
  const previousValue = convertToComparableValue({
    isHoursMode,
    hours: initialAllocatedHours,
    scheduledHours
  });
  const currentValue = inputValue;

  return roundToDecimals(previousValue) === roundToDecimals(currentValue);
};

export const calculateProjectAllocationHours = ({
  initialTaskAllocationHours,
  initialProjectAllocationHours,
  initialTotalTaskAllocationHours,
  newTaskAllocationHours
}) => {
  const taskAllocationHoursDelta =
    newTaskAllocationHours - initialTaskAllocationHours;

  let projectAllocationHoursDelta = taskAllocationHoursDelta;

  if (
    taskAllocationHoursDelta > 0 &&
    initialProjectAllocationHours > initialTotalTaskAllocationHours
  ) {
    projectAllocationHoursDelta = Math.max(
      0,
      initialTotalTaskAllocationHours +
        taskAllocationHoursDelta -
        initialProjectAllocationHours
    );
  }

  if (
    taskAllocationHoursDelta < 0 &&
    initialProjectAllocationHours < initialTotalTaskAllocationHours
  ) {
    projectAllocationHoursDelta = Math.min(
      0,
      initialTotalTaskAllocationHours +
        taskAllocationHoursDelta -
        initialProjectAllocationHours
    );
  }

  return Math.max(
    0,
    initialProjectAllocationHours + projectAllocationHoursDelta
  );
};

export const getNewAvailabilityHours = ({
  workHours,
  otherProjectAllocationHours,
  newProjectAllocationHours
}) =>
  workHours -
  Math.max(otherProjectAllocationHours, 0) -
  newProjectAllocationHours;

export const getNewRemainingProjectAllocationHours = ({
  newProjectAllocationHours,
  otherTaskAllocationHours,
  newTaskAllocationHours
}) =>
  newProjectAllocationHours - otherTaskAllocationHours - newTaskAllocationHours;

export const getRemainingAndAvailableHours = ({
  workHours,
  newTaskAllocationHours,
  otherProjectAllocationHours,
  initialTaskAllocationHours,
  initialProjectAllocationHours,
  initialTotalTaskAllocationHours,
  isRmpTaskAllocationPhase2Enabled
}) => {
  const newProjectAllocationHours = calculateProjectAllocationHours({
    initialTaskAllocationHours,
    initialProjectAllocationHours,
    initialTotalTaskAllocationHours,
    newTaskAllocationHours
  });

  const otherTaskAllocationHours =
    initialTotalTaskAllocationHours - initialTaskAllocationHours;

  return {
    newAvailabilityHours: isRmpTaskAllocationPhase2Enabled
      ? getNewAvailabilityHours({
          workHours,
          otherProjectAllocationHours,
          newProjectAllocationHours
        })
      : 0,
    newRemainingProjectAllocationHours: getNewRemainingProjectAllocationHours({
      newProjectAllocationHours,
      otherTaskAllocationHours,
      newTaskAllocationHours
    })
  };
};

export const getRemainingAndAvailableComparableValues = ({
  workHours,
  scheduledHours,
  initialTaskAllocationHours,
  initialTotalTaskAllocationHours,
  initialProjectAllocationHours,
  otherProjectAllocationHours,
  isRmpTaskAllocationPhase2Enabled
}) => ({ newTaskAllocationHours, isHoursMode }) => {
  const {
    newAvailabilityHours,
    newRemainingProjectAllocationHours
  } = getRemainingAndAvailableHours({
    workHours,
    newTaskAllocationHours,
    otherProjectAllocationHours,
    initialTaskAllocationHours,
    initialProjectAllocationHours,
    initialTotalTaskAllocationHours,
    isRmpTaskAllocationPhase2Enabled
  });

  return {
    availableValue: isRmpTaskAllocationPhase2Enabled
      ? convertToComparableValue({
          hours: newAvailabilityHours,
          isHoursMode,
          scheduledHours
        })
      : 0,
    remainingValue: convertToComparableValue({
      hours: newRemainingProjectAllocationHours,
      isHoursMode,
      scheduledHours
    })
  };
};
