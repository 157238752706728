import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { ResourceRequestAccessLevel, ResourceRequestStatus } from '~/types';
import { useMeContext } from '~/modules/me';
import { LegacyResourceRequestItem } from '~/modules/projects/resourcing-plan/ResourceRequestChart/components/ResourceRequestItem';
import { checkIfResourceAllocationIsReadOnly } from '~/modules/resourcing/enhancers/withIsResourceAllocationReadonly';
import {
  useRedirectToAssignResource,
  useResourceAllocationToolbarStateContext
} from '~/modules/resourcing/hooks';
import { useHasPermission } from '~/modules/common/permissions';
import {
  ResourceRequestAllocationActions,
  AssignResourceButton,
  ResourceAllocationMatchItem
} from '~/modules/resourcing/common/components';
import { useMergeDialogState } from '~/modules/resourcing/common/hooks';
import { updateCacheForCompletedAndMergedRequestsOnResourcingPage } from '~/modules/resourcing/common/hooks/useMarkResourceRequestAsCompleteAndMerge';
import { useSessionStorage } from '~/modules/common/hooks';
import AllocatedResourceRequestUserItem from './components/AllocatedResourceRequestUserItem';
import useStyles from './useStyles';

const onResourceMatchesClick = () => {};

export const AllocatedResourceRequestItem = ({
  classes: { openButton, ...classesOverrides },
  qeIdTag,
  resourceRequest,
  displayEntityType,
  maxSkillLevel,
  requestAccessLevel,
  project,
  displayUnit,
  setAnchorEl,
  setPopoverResourceRequest
}) => {
  const history = useHistory();
  const classes = useStyles({ classes: classesOverrides });
  const {
    featureFlags: {
      isPsaPraaResourcingUIEnabled,
      isPsaRmpTaskAllocation1Enabled
    }
  } = useMeContext();

  const { scale } = useResourceAllocationToolbarStateContext();

  const mergeDialogState = useMergeDialogState();
  const { isResourceAllocationReadOnly } = checkIfResourceAllocationIsReadOnly({
    resourceRequest
  });

  const { resourceAllocations } = resourceRequest;

  const isResourceAllocationExists = resourceAllocations.length !== 0;

  const onAssignResourceClick = useRedirectToAssignResource({
    history,
    resourceRequest,
    scale,
    displayUnit
  });

  const reqClasses = useMemo(() => ({ openButton }), [openButton]);

  const isEditAssignmentEnabled = useHasPermission({
    actionUri:
      'urn:replicon:resource-assignment-action:edit-resource-assignments'
  });

  const { storedValue: resourceRequestsQueryVariables } = useSessionStorage(
    'RESOURCE-REQUESTS-QUERY-VARIABLES',
    null
  );

  const cacheUpdateParams = useMemo(() => {
    return {
      updateMergeCache: updateCacheForCompletedAndMergedRequestsOnResourcingPage,
      resourceRequestsQueryVariables
    };
  }, [resourceRequestsQueryVariables]);

  const actions = useMemo(
    () => (
      <ResourceRequestAllocationActions
        project={project}
        resourceRequest={resourceRequest}
        requestAccessLevel={requestAccessLevel}
        isProjectManagerView={false}
        isProjectResourcingTab
        isResourceRequestMergeEnabled={isPsaRmpTaskAllocation1Enabled}
        mergeDialogState={mergeDialogState}
        scale={scale}
        displayUnit={displayUnit}
        cacheUpdateParams={cacheUpdateParams}
      />
    ),
    [
      project,
      resourceRequest,
      requestAccessLevel,
      isPsaRmpTaskAllocation1Enabled,
      mergeDialogState,
      scale,
      displayUnit,
      cacheUpdateParams
    ]
  );

  const resourceRequestItemClasses = useMemo(
    () => ({
      clickableColumn: classes.clickableColumn,
      openButton
    }),
    [classes.clickableColumn, openButton]
  );

  return (
    <>
      <div className={classNames(classes.allocationRoot, classes.roleItem)}>
        <LegacyResourceRequestItem
          qeIdTag={qeIdTag}
          actions={actions}
          displayEntityType={displayEntityType}
          resourceRequest={resourceRequest}
          project={project}
          maxSkillLevel={maxSkillLevel}
          classes={resourceRequestItemClasses}
        />
      </div>
      {isPsaPraaResourcingUIEnabled &&
      resourceRequest.requestStatus === ResourceRequestStatus.Submitted ? (
        <ResourceAllocationMatchItem
          onAssignResourceClick={onAssignResourceClick}
          onResourceMatchesClick={onResourceMatchesClick}
          resourceRequest={resourceRequest}
          setAnchorEl={setAnchorEl}
          setPopoverResourceRequest={setPopoverResourceRequest}
        />
      ) : !isResourceAllocationExists ? (
        <div className={classes.allocation}>
          <AssignResourceButton
            qeIdTag={qeIdTag}
            quantity={resourceRequest.quantity}
            onClick={onAssignResourceClick}
          />
        </div>
      ) : (
        <div className={classes.resourceItems}>
          {resourceAllocations.map((resourceAllocation, index) => {
            const resourceUri = resourceAllocation.user
              ? resourceAllocation.user.userUri
              : `NoUser`;

            return (
              <div key={uuidv4()} className={classes.userItem}>
                <AllocatedResourceRequestUserItem
                  classes={reqClasses}
                  qeIdTag={`${qeIdTag}_${resourceUri}`}
                  isReadonly={
                    isResourceAllocationReadOnly || !isEditAssignmentEnabled
                  }
                  resourceRequest={resourceRequest}
                  resourceAllocation={resourceAllocation}
                  resourceAllocationUserUri={resourceAllocation.user.userUri}
                  resourceAllocationLoad={resourceAllocation.load}
                  requestAccessLevel={requestAccessLevel}
                  isAdjustedLoading={resourceAllocation.isAdjustedLoading}
                  project={project}
                />
              </div>
            );
          })}
          {!isResourceAllocationReadOnly &&
            resourceAllocations.length < resourceRequest.quantity && (
              <div className={classes.allocationAndResource}>
                <AssignResourceButton
                  qeIdTag={qeIdTag}
                  quantity={
                    resourceRequest.quantity - resourceAllocations.length
                  }
                  onClick={onAssignResourceClick}
                />
              </div>
            )}
        </div>
      )}
    </>
  );
};

AllocatedResourceRequestItem.propTypes = {
  qeIdTag: PropTypes.string,
  classes: PropTypes.object,
  resourceRequest: PropTypes.object.isRequired,
  displayEntityType: PropTypes.string,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  project: PropTypes.object,
  maxSkillLevel: PropTypes.number,
  displayUnit: PropTypes.string,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired
};

export default AllocatedResourceRequestItem;
