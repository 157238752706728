import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { compareDateObjects } from '~/modules/common/dates/compare';

export const getProjectAvailableHours = ({
  start,
  end,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules
}) =>
  getTotalHoursForDateRangeFromScheduleRules({
    start,
    end,
    scheduleRules: resourceAllocationScheduleRules
  }) -
  getTotalHoursForDateRangeFromScheduleRules({
    start,
    end,
    scheduleRules: otherTaskAllocationsSummaryScheduleRules || []
  });

export const getInitialEstimatedHoursForTaskAllocation = ({
  initialEstimatedHours,
  projectAvailableHours
}) => {
  if (initialEstimatedHours) {
    return Math.min(initialEstimatedHours, projectAvailableHours);
  }

  return projectAvailableHours;
};

export const startIsAfterEnd = ({ start, end }) =>
  compareDateObjects(start, end) === 1;

export const getAllocatedValuesForTaskAllocation = ({
  start,
  end,
  initialEstimatedHours,
  taskResourceUserAllocation,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules
}) => {
  const newProjectAllocationHours =
    getTotalHoursForDateRangeFromScheduleRules({
      start,
      end,
      scheduleRules: resourceAllocationScheduleRules || []
    }) || 0;

  const otherTaskAllocationHours =
    getTotalHoursForDateRangeFromScheduleRules({
      start,
      end,
      scheduleRules: otherTaskAllocationsSummaryScheduleRules || []
    }) || 0;

  return {
    newProjectAllocationHours,
    otherTaskAllocationHours,
    newTaskAllocationHours: taskResourceUserAllocation
      ? taskResourceUserAllocation.totalHours
      : initialEstimatedHours
  };
};
