import PropTypes from 'prop-types';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { ClickAwayListener } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { useMenuState } from '~/modules/common/hooks';
import LegendSection from './LegendSection';

const useStyles = makeStyles(theme => ({
  legendList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3, 2, 0)
  },
  legendLabel: {
    ...theme.typography.body2,
    textTransform: 'none',
    color: theme.palette.text.secondary
  }
}));

const getGroupedLegends = ({ legends }) => {
  const result = { none: [] };

  for (const legend of legends) {
    if (!legend.groupType) {
      result.none.push(legend);
    } else {
      result[legend.groupType] = result[legend.groupType] || [];
      result[legend.groupType].push(legend);
    }
  }

  return result;
};

const LegendsPopup = ({ legends }) => {
  const { anchorEl, onMenuClose, onMenuClick } = useMenuState();
  const classes = useStyles();

  const legendsBySection = getGroupedLegends({ legends });

  return (
    <>
      <ClickAwayListener onClickAway={onMenuClose}>
        <Button
          onClick={onMenuClick}
          className={classes.legendLabel}
          variant="text"
          startIcon={<InfoIcon />}
        >
          <FormattedMessage id="resourcingLegends.legend" />
        </Button>
      </ClickAwayListener>
      {anchorEl && (
        <ArrowPopup
          id="legends-popup"
          anchorEl={anchorEl}
          placement="bottom"
          preventOverflow
          onClose={onMenuClose}
        >
          <div className={classes.legendList}>
            <LegendSection legendSection={legendsBySection.none} />
            <LegendSection
              legendHeaderId="resourcingLegends.resourceRequests"
              legendSection={legendsBySection.resourceRequests}
            />
            <LegendSection
              legendHeaderId="resourcingLegends.resources"
              legendSection={legendsBySection.resources}
            />
          </div>
        </ArrowPopup>
      )}
    </>
  );
};

LegendsPopup.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      isProgressBarLegend: PropTypes.bool,
      barType: PropTypes.oneOf(['dashedBar', 'roundBar', 'squareBar']),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      className: PropTypes.string,
      color: PropTypes.string,
      groupType: PropTypes.string
    }).isRequired
  ).isRequired
};

export default LegendsPopup;
