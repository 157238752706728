import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ListItemText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import SimpleDropdown from '~/modules/common/components/SimpleDropdown';
import { ConsolidateBillsBy } from '~/types';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  select: {
    fontSize: theme.typography.body2.fontSize
  }
}));

const getOptions = formatMessage => [
  {
    id: ConsolidateBillsBy.Client,
    displayText: formatMessage({
      id: 'billingCard.subTitle.consolidateBillsBy.options.client'
    }),
    component: (
      <ListItemText
        primary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.options.client'
        })}
        secondary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.description.client'
        })}
      />
    )
  },
  {
    id: ConsolidateBillsBy.Project,
    displayText: formatMessage({
      id: 'billingCard.subTitle.consolidateBillsBy.options.project'
    }),
    component: (
      <ListItemText
        primary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.options.project'
        })}
        secondary={formatMessage({
          id: 'billingCard.subTitle.consolidateBillsBy.description.project'
        })}
      />
    )
  }
];

export const ConsolidateBillsByDropdown = ({ value: val, onChange }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const options = useMemo(() => getOptions(formatMessage), [formatMessage]);

  const dropdownClasses = useMemo(
    () => ({
      container: classes.container,
      select: classes.select
    }),
    [classes.container, classes.select]
  );

  const value = useMemo(
    () => ({
      id: val,
      displayText: options.find(({ id }) => id === val).displayText
    }),
    [options, val]
  );

  const selectProps = useMemo(
    () => ({
      renderValue: selectedValue =>
        options.find(({ id }) => id === selectedValue).displayText
    }),
    [options]
  );

  return (
    <SimpleDropdown
      variant="filled"
      options={options}
      onChange={onChange}
      value={value}
      classes={dropdownClasses}
      noneOption={false}
      labelWidth={200}
      disableSort
      label={formatMessage({
        id: 'billingCard.subTitle.consolidateBillsBy.label'
      })}
      selectProps={selectProps}
    />
  );
};

ConsolidateBillsByDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default ConsolidateBillsByDropdown;
