import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateField, Hours } from '~/modules/common/components';
import {
  useKeyValueSettingsMutation,
  updateCache
} from '~/modules/common/hooks';
import {
  ProjectTeamMemberDropdown,
  ResourceUsersDropdown,
  RoleDropdown
} from '~/modules/tasks/components';
import {
  useRemainingProjectAllocationHours,
  useTotalResourceAvailableDurationForDateRange
} from '~/modules/tasks/hooks';
import { getError, hasError } from '~/util';
import { mapRepliconDateToUtcObject } from '~/modules/common/dates/convert';
import { ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING } from './constants';
import AddResourceEstimateDialogContentLoading from './AddResourceEstimateDialogContentLoading';
import useAddResourceEstimateDialogChangeHandlers from './useAddResourceEstimateDialogChangeHandlers';

const useDateFieldInputProps = (ariaLabel, className) =>
  useMemo(
    () => ({
      inputProps: {
        'aria-label': ariaLabel
      },
      endAdornment: (
        <InputAdornment className={className} position="end">
          <ArrowDropDown />
        </InputAdornment>
      )
    }),
    [ariaLabel, className]
  );

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(2)
  },
  checkboxEnabled: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  checkboxDisabled: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
  dateContainer: {
    marginBottom: theme.spacing(1)
  },
  dateIcon: {
    marginRight: theme.spacing(-0.875),
    padding: theme.spacing(0.25),
    color: theme.palette.action.active
  },
  numberInputWithValue: {
    marginBottom: theme.spacing(2),
    left: 0,
    right: 'unset',
    transformOrigin: 'left top'
  },
  numberInputWithoutValue: {
    marginBottom: theme.spacing(2),
    right: theme.spacing(3),
    left: 0,
    transformOrigin: 'left top',
    '&.Mui-focused': {
      right: 'unset',
      left: 0,
      transformOrigin: 'left top'
    }
  },
  warning: {
    marginTop: theme.spacing(2)
  }
}));

const dialogFieldIds = {
  estimatedHours: 'AddResourceEstimateDialogEstimatedHoursField',
  checkbox: 'AddResourceEstimateDialogCheckboxField',
  startDate: 'AddResourceEstimateDialogStartDateField',
  endDate: 'AddResourceEstimateDialogEndDateField'
};

const getFieldLabels = formatMessage => ({
  role: formatMessage({ id: 'addResourceEstimateDialog.role' }),
  resource: formatMessage({
    id: 'addResourceEstimateDialog.resource'
  }),
  estimatedHours: formatMessage({
    id: 'addResourceEstimateDialog.estimatedHours'
  }),
  allocateResourceTimeCheckBox: formatMessage({
    id: 'addResourceEstimateDialog.allocateResourceTimeCheckBox'
  }),
  startDate: formatMessage({ id: 'addResourceEstimateDialog.startDate' }),
  endDate: formatMessage({ id: 'addResourceEstimateDialog.endDate' })
});

export const roundToDecimals = number =>
  Math.round((number + Number.EPSILON) * 100) / 100;

const AddResourceEstimateDialogContent2 = ({
  isFullVersionEnabled,
  loading,
  task
}) => {
  const { formatMessage } = useIntl();
  const fieldLabels = getFieldLabels(formatMessage);
  const classes = useStyles();

  const {
    id: taskId,
    projectReference: { slug: projectSlug, id: projectId }
  } = task;

  const startDateInputProps = useDateFieldInputProps(
    fieldLabels.startDate,
    classes.dateIcon
  );
  const endDateInputProps = useDateFieldInputProps(
    fieldLabels.endDate,
    classes.dateIcon
  );

  const {
    initialValues,
    errors,
    values,
    setValues,
    setFieldValue,
    validateForm
  } = useFormikContext();
  const {
    role,
    resource,
    initialEstimatedHours,
    isCreateTaskAllocationChecked,
    startDate,
    endDate
  } = values;

  const skipAvailableHoursLoading = !(
    isCreateTaskAllocationChecked &&
    resource?.id &&
    startDate &&
    endDate
  );

  const {
    availableDuration,
    loading: availablilityLoading
  } = useTotalResourceAvailableDurationForDateRange({
    resourceUserId: resource?.id,
    startDate,
    endDate,
    skip: skipAvailableHoursLoading
  });

  const {
    remainingProjectAllocationHours,
    loading: isRemainingProjectAllocationHoursLoading
  } = useRemainingProjectAllocationHours({
    userId: resource?.id,
    projectId,
    startDate: startDate && mapRepliconDateToUtcObject(startDate),
    endDate: endDate && mapRepliconDateToUtcObject(endDate),
    skip: skipAvailableHoursLoading
  });

  const showOverAllocationBanner =
    isCreateTaskAllocationChecked &&
    initialEstimatedHours >
      roundToDecimals(availableDuration + remainingProjectAllocationHours);

  const [putKeyValueSettings] = useKeyValueSettingsMutation(
    updateCache(ADD_TASK_RESOURCE_ESTIMATE_DIALOG_SETTING)
  );

  const {
    onRoleChange,
    onAddRoleDialogCancel,
    onInitialEstimatedHoursChange,
    onCreateTaskAllocationChange,
    onStartDateChange,
    onEndDateChange,
    onResourceChange
  } = useAddResourceEstimateDialogChangeHandlers({
    values,
    setValues,
    setFieldValue,
    putKeyValueSettings
  });

  const hasStartDateError = hasError(errors, 'startDate');
  const hasEndDateError = hasError(errors, 'endDate');
  const hasInitialEstimatedHoursError = hasError(
    errors,
    'initialEstimatedHours'
  );

  useEffect(() => {
    validateForm(initialValues);
  }, [validateForm, initialValues]);

  if (loading)
    return (
      <AddResourceEstimateDialogContentLoading
        isFullVersionEnabled={isFullVersionEnabled}
      />
    );

  return (
    <DialogContent>
      <RoleDropdown
        value={role}
        onChange={onRoleChange}
        variant="outlined"
        placeholder={fieldLabels.role}
        projectSlug={projectSlug}
        className={classes.spacing}
        label={fieldLabels.role}
        onAddRoleDialogCancelButtonClick={onAddRoleDialogCancel}
        autoFocus
      />
      <Hours
        className={
          initialEstimatedHours
            ? classes.numberInputWithValue
            : classes.numberInputWithoutValue
        }
        variant="outlined"
        data-qe-id={dialogFieldIds.estimatedHours}
        id={dialogFieldIds.estimatedHours}
        label={fieldLabels.estimatedHours}
        ariaLabel={fieldLabels.estimatedHours}
        margin="none"
        isEditible
        align="left"
        onChange={onInitialEstimatedHoursChange}
        value={initialEstimatedHours}
        precision={2}
        error={hasInitialEstimatedHoursError}
        helperText={getError(errors, 'initialEstimatedHours')}
      />
      {isFullVersionEnabled && (
        <>
          <FormControlLabel
            className={
              isCreateTaskAllocationChecked
                ? classes.checkboxEnabled
                : classes.checkboxDisabled
            }
            control={
              <Checkbox
                data-qe-id={dialogFieldIds.checkbox}
                checked={isCreateTaskAllocationChecked}
                onChange={onCreateTaskAllocationChange}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {fieldLabels.allocateResourceTimeCheckBox}
              </Typography>
            }
            labelPlacement="end"
          />
          {isCreateTaskAllocationChecked && (
            <Grid container spacing={2} className={classes.dateContainer}>
              <Grid item xs={6}>
                <DateField
                  required
                  data-qe-id={dialogFieldIds.startDate}
                  id={dialogFieldIds.startDate}
                  editable
                  label={fieldLabels.startDate}
                  value={startDate}
                  error={hasStartDateError}
                  helperText={getError(errors, 'startDate')}
                  onChange={onStartDateChange}
                  variant="outlined"
                  InputProps={startDateInputProps}
                />
              </Grid>
              <Grid item xs={6}>
                <DateField
                  required
                  data-qe-id={dialogFieldIds.endDate}
                  id={dialogFieldIds.endDate}
                  editable
                  label={fieldLabels.endDate}
                  value={endDate}
                  error={hasEndDateError}
                  helperText={getError(errors, 'endDate')}
                  onChange={onEndDateChange}
                  variant="outlined"
                  InputProps={endDateInputProps}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      {isFullVersionEnabled ? (
        <ResourceUsersDropdown
          disabled={
            hasStartDateError ||
            hasEndDateError ||
            hasInitialEstimatedHoursError
          }
          assignedRole={role}
          onResourceChange={onResourceChange}
          projectId={projectId}
          value={resource}
          placeholder={fieldLabels.resource}
          ariaLabel={fieldLabels.resource}
          variant="outlined"
          label={fieldLabels.resource}
          taskId={taskId}
          startDate={startDate}
          endDate={endDate}
          initialEstimatedHours={initialEstimatedHours}
          hideAvailability={!isCreateTaskAllocationChecked}
        />
      ) : (
        <ProjectTeamMemberDropdown
          assignedRole={role}
          onResourceChange={onResourceChange}
          projectId={projectId}
          value={resource}
          placeholder={fieldLabels.resource}
          ariaLabel={fieldLabels.resource}
          variant="outlined"
          label={fieldLabels.resource}
          taskId={taskId}
        />
      )}

      {availablilityLoading || isRemainingProjectAllocationHoursLoading ? (
        <Skeleton height={70} width="100%" />
      ) : (
        showOverAllocationBanner && (
          <Alert severity="warning" icon={false} className={classes.warning}>
            <FormattedMessage id="addResourceEstimateDialog.messages.noEnoughAvailableHoursWarning" />
          </Alert>
        )
      )}
    </DialogContent>
  );
};

AddResourceEstimateDialogContent2.propTypes = {
  isFullVersionEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
};

export default AddResourceEstimateDialogContent2;
