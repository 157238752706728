import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressbarLegend } from '~/modules/common/charts/dashboard';
import dashBorderSVG from '../DashedBorder.svg';
import ResourcingLegend from './ResourcingLegend';

const useStyles = makeStyles(theme => ({
  legendItem: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    margin: theme.spacing(0.25, 0, 0.25, 2)
  },
  squareBar: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25)
  },
  dashedBar: {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    border: `2px dashed ${theme.palette.grey[700]}`,
    borderImage: `url(${dashBorderSVG}) 2 repeat`,
    borderRadius: theme.spacing(0.25)
  }
}));

const LegendItemV2 = ({
  isProgressBarLegend,
  barType,
  label,
  color,
  legendClassName
}) => {
  const classes = useStyles();

  const legendClasses = useMemo(
    () => ({
      rootLegend: classes[barType]
    }),
    [barType, classes]
  );

  return (
    <div className={classes.legendItem}>
      {isProgressBarLegend ? (
        <ProgressbarLegend label={label} />
      ) : (
        <ResourcingLegend
          classes={legendClasses}
          className={legendClassName}
          label={label}
          color={color}
        />
      )}
    </div>
  );
};

LegendItemV2.propTypes = {
  label: PropTypes.node,
  isProgressBarLegend: PropTypes.bool,
  color: PropTypes.string,
  legendClassName: PropTypes.string,
  barType: PropTypes.oneOf(['dashedBar', 'squareBar'])
};

export default LegendItemV2;
