import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTotalResourceAvailableDurationForDateRangeCallback } from '~/modules/tasks/hooks/useTotalResourceAvailableDurationForDateRange';
import {
  getNewAvailabilityHours,
  getNewRemainingProjectAllocationHours,
  roundToDecimals
} from '~/modules/resourcing/common/util';
import PopoverContentLayout from '../../common/components/PopoverContentLayout';
import {
  FieldLabel,
  AllocationEstimateInfoComponent
} from '../../common/components';
import useFormChangeHandlers from '../hooks/useFormChangeHandlers';
import { UserOverAllocationChip } from '../../../components/UserOverAllocationChip';
import { useTaskAllocationEditorContext } from '../../TaskAllocationEditorContext';
import AvailabilityInfoComponent from './AvailabilityInfoComponent';

const useStyles = makeStyles(() => ({
  label: {
    width: '120%'
  }
}));

const useLayoutStyles = makeStyles(() => ({
  title: {
    paddingTop: 0
  }
}));

export const updateFieldValues = ({
  initialProjectAllocationHours,
  otherTaskAllocationHours,
  newTaskAllocationHours,
  newProjectAllocationHours,
  newTotalResourceAllocatedHours,
  newWorkHours
}) => {
  const newAvailabilityHours = getNewAvailabilityHours({
    workHours: newWorkHours,
    newProjectAllocationHours,
    otherProjectAllocationHours:
      newTotalResourceAllocatedHours - initialProjectAllocationHours
  });

  return {
    newTaskAllocationHours: roundToDecimals(newTaskAllocationHours),
    newAvailabilityHours: roundToDecimals(newAvailabilityHours),
    newRemainingProjectAllocationHours: roundToDecimals(
      getNewRemainingProjectAllocationHours({
        newProjectAllocationHours,
        otherTaskAllocationHours,
        newTaskAllocationHours
      })
    ),
    newResourceAvailabilityHours: roundToDecimals(
      newAvailabilityHours + newTaskAllocationHours
    )
  };
};

const EditorPopoverContent = ({
  resourceAllocation,
  otherTaskAllocationsSummary,
  initialEstimatedHours,
  estimatedAtCompletionHours,
  taskResourceUserAllocation,
  userUri
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const { values, errors, setValues } = useFormikContext();
  const {
    startDate,
    endDate,
    newProjectAllocationHours,
    otherTaskAllocationHours
  } = values;
  const { scheduleRules: resourceAllocationScheduleRules } =
    resourceAllocation || {};
  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;

  const { taskAllocationHoursInputRef } = useTaskAllocationEditorContext();

  const {
    newTaskAllocationHours,
    newResourceAvailabilityHours,
    newAvailabilityHours,
    newRemainingProjectAllocationHours
  } = updateFieldValues(values);
  const {
    getTotalResourceAvailableHoursForDateRange
  } = useTotalResourceAvailableDurationForDateRangeCallback({
    fetchPolicy: 'network-only'
  });

  const {
    onStartDateChange,
    onEndDateChange,
    onTaskAllocationHoursChange
  } = useFormChangeHandlers({
    values,
    setValues,
    startDate,
    endDate,
    taskResourceUserAllocation,
    resourceAllocationScheduleRules,
    otherTaskAllocationsSummaryScheduleRules,
    initialEstimatedHours,
    getTotalResourceAvailableHoursForDateRange,
    userUri
  });

  const initialAvailabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.initialAvailability"
          className={classes.label}
        />
      ),
      hoursValue: newResourceAvailabilityHours,
      infoComponent: startDate && endDate && resourceAllocation && (
        <AvailabilityInfoComponent
          endDate={endDate}
          startDate={startDate}
          newRemainingProjectAllocationHours={
            newRemainingProjectAllocationHours
          }
          otherTaskAllocationHours={otherTaskAllocationHours}
          newProjectAllocationHours={newProjectAllocationHours}
          taskAllocationHours={newTaskAllocationHours}
        />
      )
    }),
    [
      classes.label,
      newResourceAvailabilityHours,
      startDate,
      endDate,
      resourceAllocation,
      newRemainingProjectAllocationHours,
      otherTaskAllocationHours,
      newProjectAllocationHours,
      newTaskAllocationHours
    ]
  );

  const allocationField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.taskAllocation"
        />
      ),
      hoursValue: newTaskAllocationHours,
      infoComponent: (
        <AllocationEstimateInfoComponent
          taskResourceUserAllocationId={taskResourceUserAllocation?.id}
          estimatedAtCompletionHours={estimatedAtCompletionHours}
          initialEstimatedHours={initialEstimatedHours}
          allocatedHours={newTaskAllocationHours}
        />
      )
    }),
    [
      estimatedAtCompletionHours,
      initialEstimatedHours,
      newTaskAllocationHours,
      taskResourceUserAllocation?.id
    ]
  );

  const resourceAvailabilityField = useMemo(
    () => ({
      labelComponent: (
        <FieldLabel
          variant="body2"
          messageId="taskAllocationEditor.resourceAvailability"
        />
      ),
      hoursValue: newAvailabilityHours,
      infoComponent: newAvailabilityHours < 0 && (
        <UserOverAllocationChip startDate={startDate} endDate={endDate} />
      )
    }),
    [endDate, startDate, newAvailabilityHours]
  );

  const dateRangePickerProps = useMemo(
    () => ({
      onStartDateChange,
      onEndDateChange,
      errors
    }),
    [errors, onEndDateChange, onStartDateChange]
  );

  return (
    <PopoverContentLayout
      startDate={startDate}
      endDate={endDate}
      onHoursChange={onTaskAllocationHoursChange}
      dateRangePickerProps={dateRangePickerProps}
      availabilityField={initialAvailabilityField}
      remainingField={resourceAvailabilityField}
      allocationField={allocationField}
      classes={layoutClasses}
      taskAllocationHoursInputRef={taskAllocationHoursInputRef}
    />
  );
};

EditorPopoverContent.propTypes = {
  resourceAllocation: PropTypes.object,
  otherTaskAllocationsSummary: PropTypes.object,
  initialEstimatedHours: PropTypes.number,
  estimatedAtCompletionHours: PropTypes.number,
  taskResourceUserAllocation: PropTypes.object,
  userUri: PropTypes.string
};

export default EditorPopoverContent;
