import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useHasPermission } from '~/modules/common/permissions';
import { ResourceAllocationStatus } from '~/types';
import { useQuickAllocationContext } from '../../../../hooks';
import LeftComponentModel1 from './components/LeftComponentModel1';
import LeftComponentModel0 from './components/LeftComponentModel0';

const useStyles = makeStyles(theme => ({
  rowCell: {
    width: theme.spacing(28.75)
  },
  text: {
    ...theme.typography.caption,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(1)
  },
  projectText: {
    fontWeight: theme.typography.fontWeightBold,
    maxWidth: theme.spacing(25),
    marginTop: theme.spacing(0.5)
  },
  roleText: {
    padding: theme.spacing(1, 1, 0, 1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(15),
    textAlign: 'right',
    fontSize: theme.typography.caption.fontSize
  },
  actionPlaceHolder: { width: theme.spacing(4) },
  rolesContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexBasis: theme.spacing(17.5)
  },
  readOnlyProject: {
    width: theme.spacing(31.75),
    paddingLeft: theme.spacing(1)
  },
  loadingMenuItems: {
    marginTop: theme.spacing(1.75),
    marginLeft: theme.spacing(1)
  }
}));

const useActualsStyles = makeStyles(theme => ({
  roleRowActualsCell: {
    paddingBottom: theme.spacing(4.93),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

const useRowStyles = makeStyles(theme => ({
  roleRowCell: {
    paddingBottom: theme.spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

const QuickAllocationProjectGroupLeftComponent = ({
  allocatedProject,
  projectsSlugLoading,
  handleRemoveResourceAllocation,
  resourceAllocations,
  resourceUser,
  setSnackBarState,
  isPsaRmpTaskAllocation1Enabled
}) => {
  const classes = useStyles();
  const actualsClasses = useActualsStyles();
  const rowStyles = useRowStyles();
  const { isShowActualEnabled } = useQuickAllocationContext();

  const {
    resourceAllocationReferencesForProject
  } = allocatedProject.roles.reduce(
    (acc, { role, resourceAllocationReference }) => {
      if (resourceAllocationReference.length) {
        return {
          resourceAllocationReferencesForProject: [
            ...acc.resourceAllocationReferencesForProject,
            ...resourceAllocationReference.map(({ id }) => ({
              key: id,
              allocationId: id,
              role
            }))
          ],
          hasResourceAllocations: true
        };
      }

      return {
        ...acc,
        resourceAllocationReferencesForProject: [
          ...acc.resourceAllocationReferencesForProject,
          { key: role?.id || `${allocatedProject.project.id}-no-role`, role }
        ]
      };
    },
    {
      resourceAllocationReferencesForProject: [],
      hasResourceAllocations: false
    }
  );

  const isCompleteResourceAllocationPermissionEnabled = useHasPermission({
    actionUri: 'urn:replicon-webui:completed-resource-allocation:edit'
  });

  const committedResourceAllocations = resourceAllocations.filter(
    allocation =>
      allocation.allocationStatus === ResourceAllocationStatus.Committed
  );

  const showResourceActions =
    isCompleteResourceAllocationPermissionEnabled &&
    Boolean(committedResourceAllocations.length);

  return (
    <>
      {isPsaRmpTaskAllocation1Enabled ? (
        <LeftComponentModel1
          allocatedProject={allocatedProject}
          classes={classes}
          committedResourceAllocations={committedResourceAllocations}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          projectsSlugLoading={projectsSlugLoading}
          resourceUser={resourceUser}
          setSnackBarState={setSnackBarState}
          showResourceActions={showResourceActions}
        />
      ) : (
        <LeftComponentModel0
          allocatedProject={allocatedProject}
          actualsClasses={actualsClasses}
          classes={classes}
          committedResourceAllocations={committedResourceAllocations}
          handleRemoveResourceAllocation={handleRemoveResourceAllocation}
          isShowActualEnabled={isShowActualEnabled}
          projectsSlugLoading={projectsSlugLoading}
          resourceAllocationReferencesForProject={
            resourceAllocationReferencesForProject
          }
          resourceUser={resourceUser}
          rowStyles={rowStyles}
          setSnackBarState={setSnackBarState}
          showResourceActions={showResourceActions}
        />
      )}
    </>
  );
};

QuickAllocationProjectGroupLeftComponent.propTypes = {
  allocatedProject: PropTypes.object,
  projectsSlugLoading: PropTypes.bool,
  handleRemoveResourceAllocation: PropTypes.func,
  resourceAllocations: PropTypes.array,
  resourceUser: PropTypes.object,
  setSnackBarState: PropTypes.func,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool
};

export default QuickAllocationProjectGroupLeftComponent;
