import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  ProjectIcon,
  TaskIcon,
  LoadingButton
} from '~/modules/common/components';
import ErrorCard from '~/modules/tasks/modecs/ErrorCard';
import { useTaskCustomFieldDefinitions } from '~/modules/tasks/modecs/useTaskCustomFieldDefinitions';
import FormErrorAlert from '~/modules/common/components/FormErrorAlert/FormErrorAlert';
import { TASK_ESTIMATE_CALCULATION_TYPES } from '~/modules/common/enums';
import { useIsBreakpointUp, useIsBreakpointDown } from '~/modules/common/hooks';
import {
  useValidateTaskName,
  useFormChangeHandlers,
  useTaskState
} from './hooks';
import AddTaskForm from './AddTaskForm';
import AddTaskForm2 from './AddTaskForm/AddTaskForm2';
import AddTaskDialogErrors from './AddTaskDialogErrors';

const GET_PROJECT_DETAILS = gql`
  query GetProjectDetails($projectSlug: String!, ${`$isPsaPrpPsaPpmMergerEnabled: Boolean = false`}) {
    project(projectSlug: $projectSlug) {
      id
      isTaskDateRollupEnabled
      permittedActionUris
      defaultBillingCurrency {
        id
        displayText
      }
      billingType {
        id
        displayText
      }
      projectTemplateSetting @include(if: $isPsaPrpPsaPpmMergerEnabled) {
        key
        value
      },
      isTaskDateRollupEnabled
    }
  }
`;

const addTaskDialogRole = {
  role: 'presentation'
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingBottom: theme.spacing(2)
  },
  dialogTitle: {
    padding: theme.spacing(2, 2, 1, 2)
  },
  dialogAction: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  dialogContentWithFFOn: {
    padding: theme.spacing(1, 2, 2, 2)
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  name: {
    fontWeight: theme.typography.fontWeightRegular
  },
  icon: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0, 0)
  },
  mobileView: {
    paddingLeft: theme.spacing(2)
  }
}));

export const AddTaskDialog = ({
  parentUri,
  parentName,
  projectSlug,
  initStartDate: startDate,
  initEndDate: endDate,
  open,
  onClose,
  fullScreen,
  isSubtask,
  createTask,
  setMessage,
  isMilestone
}) => {
  const classes = useStyles();
  const me = useMeContext();
  const intl = useIntl();
  const isDesktop = useIsBreakpointUp('md');
  const isMobileView = useIsBreakpointDown('xs');

  const {
    loading: customFieldDefsLoading,
    error: customFieldDefsError,
    customFieldDefinitions
  } = useTaskCustomFieldDefinitions();

  const {
    featureFlags: {
      PSAPRPTaskEstimateCalculation,
      isPsaPrpPsaPpmMergerEnabled,
      isPsaRmpTaskAllocation1Enabled,
      isPsaPswatTaskDateRollupEnabled
    }
  } = me;
  const isTaskAssignmentsEnabled =
    PSAPRPTaskEstimateCalculation ===
      TASK_ESTIMATE_CALCULATION_TYPES.TASKASSIGNMENT && isDesktop;

  const {
    data: { project },
    loading: isLoading
  } = useQuery(GET_PROJECT_DETAILS, {
    variables: { projectSlug, isPsaPrpPsaPpmMergerEnabled },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });
  const areStartAndEndDatesRequired =
    isPsaPswatTaskDateRollupEnabled && project.isTaskDateRollupEnabled;
  const [validateTaskNameMutation] = useValidateTaskName();

  const {
    values,
    errors,
    setFieldValue,
    submitForm,
    isSubmitting,
    handleReset
  } = useTaskState({
    intl,
    baseCurrency: me.baseCurrency,
    featureFlags: me.featureFlags,
    startDate,
    endDate,
    createTask,
    setMessage,
    onClose,
    validateTaskNameMutation,
    parentUri,
    customFieldDefinitions,
    project,
    isMilestone,
    areStartAndEndDatesRequired,
    hasViewProjectBillingOptions: me.hasViewProjectBillingOptions
  });

  const hasErrors = useMemo(
    () => Object.keys(errors).filter(key => key !== 'submitError').length > 0,
    [errors]
  );

  const ref = useRef();

  const {
    onNameChange,
    onCodeChange,
    onStartDateChange,
    onEndDateChange,
    onTaskOwnerChange,
    onAssignedRoleChange,
    onDescriptionChange,
    onEstimatedHoursChange,
    onEstimatedCostAmountChange,
    onEstimatedCostCurrencyChange,
    onIsMilestoneChange,
    onTimeAndExpenseEntryTypeChange,
    onTimeAndExpenseEntryTypeChange2,
    onAllowTimeEntryChange,
    onTimesheetAccessChange,
    customFieldDefinitions: customFieldDefinitionsWithHandler,
    onResourceEstimateAdd
  } = useFormChangeHandlers({
    setFieldValue,
    customFieldDefinitions,
    values,
    isPsaRmpTaskAllocation1Enabled
  });

  const handleClose = useCallback(() => {
    onClose();
    handleReset();
  }, [onClose, handleReset]);

  if (customFieldDefsLoading) return <div />;
  if (customFieldDefsError) return <ErrorCard error={customFieldDefsError} />;

  const milestoneCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.mobileView}
          checked={values.isMilestone}
          onChange={onIsMilestoneChange}
        />
      }
      label={intl.formatMessage({
        id: 'projectTasksPage.milestone'
      })}
      labelPlacement="end"
    />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      disableBackdropClick
      disableEscapeKeyDown
      TransitionProps={addTaskDialogRole}
      disableEnforceFocus={!!isTaskAssignmentsEnabled}
      {...(isPsaRmpTaskAllocation1Enabled && {
        fullWidth: true,
        maxWidth: 'lg',
        PaperProps: { ref }
      })}
    >
      {errors?.submitError?.type === 'taskInheritResourcesError' ? (
        <AddTaskDialogErrors
          errorTypes={[errors.submitError.type]}
          onClose={handleClose}
        />
      ) : (
        <>
          <DialogTitle
            data-qe-id="AddTaskDialog_Title"
            className={classNames({
              [classes.dialogTitle]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            {isPsaRmpTaskAllocation1Enabled ? (
              <div className={classes.title}>
                <div>
                  <FormattedMessage
                    id={
                      isSubtask
                        ? 'projectTasksPage.addSubTask'
                        : 'projectTasksPage.addTask'
                    }
                  />
                  {isSubtask ? (
                    <TaskIcon className={classes.icon} />
                  ) : (
                    <ProjectIcon className={classes.icon} />
                  )}
                  <span className={classes.name}>{parentName}</span>
                </div>
                {!isMobileView &&
                  !isLoading &&
                  !isSubmitting &&
                  milestoneCheckbox()}
              </div>
            ) : (
              <>
                <FormattedMessage
                  id={
                    isSubtask
                      ? 'projectTasksPage.addSubTask'
                      : 'projectTasksPage.addTask'
                  }
                />
                <span>{parentName}</span>
              </>
            )}
          </DialogTitle>
          <DialogContent
            className={classNames(classes.dialogContent, {
              [classes.dialogContentWithFFOn]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            {errors.submitError && (
              <FormErrorAlert errorMessage={errors.submitError.reason} />
            )}
            {isPsaRmpTaskAllocation1Enabled ? (
              <AddTaskForm2
                me={me}
                milestoneCheckbox={milestoneCheckbox}
                projectSlug={projectSlug}
                readOnly={isSubmitting}
                dialogWidth={ref?.current?.clientWidth}
                values={values}
                errors={errors}
                onNameChange={onNameChange}
                onCodeChange={onCodeChange}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                onAssignedRoleChange={onAssignedRoleChange}
                onDescriptionChange={onDescriptionChange}
                onEstimatedHoursChange={onEstimatedHoursChange}
                onEstimatedCostAmountChange={onEstimatedCostAmountChange}
                onEstimatedCostCurrencyChange={onEstimatedCostCurrencyChange}
                onTimeAndExpenseEntryTypeChange={
                  onTimeAndExpenseEntryTypeChange
                }
                onTimesheetAccessChange={onTimesheetAccessChange}
                isLoading={isLoading}
                project={project}
                onResourceEstimateAdd={onResourceEstimateAdd}
                setFieldValue={setFieldValue}
                isMobile={isMobileView}
                customFieldDefinitions={customFieldDefinitionsWithHandler}
              />
            ) : (
              <AddTaskForm
                me={me}
                projectSlug={projectSlug}
                fullScreen={fullScreen}
                readOnly={isSubmitting}
                values={values}
                errors={errors}
                onNameChange={onNameChange}
                onCodeChange={onCodeChange}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
                onTaskOwnerChange={onTaskOwnerChange}
                onAssignedRoleChange={onAssignedRoleChange}
                onDescriptionChange={onDescriptionChange}
                onEstimatedHoursChange={onEstimatedHoursChange}
                onEstimatedCostAmountChange={onEstimatedCostAmountChange}
                onEstimatedCostCurrencyChange={onEstimatedCostCurrencyChange}
                onIsMilestoneChange={onIsMilestoneChange}
                onTimeAndExpenseEntryTypeChange={
                  onTimeAndExpenseEntryTypeChange
                }
                onTimeAndExpenseEntryTypeChange2={
                  onTimeAndExpenseEntryTypeChange2
                }
                onAllowTimeEntryChange={onAllowTimeEntryChange}
                customFieldDefinitions={customFieldDefinitionsWithHandler}
                isLoading={isLoading}
                project={project}
                setFieldValue={setFieldValue}
                isSubtask={isSubtask}
                parentUri={parentUri}
              />
            )}
          </DialogContent>
          <DialogActions
            className={classNames({
              [classes.dialogAction]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            <Button
              data-qe-id="AddTaskDialog_CancelButton"
              onClick={handleClose}
              disabled={isSubmitting}
              variant={isPsaRmpTaskAllocation1Enabled ? 'contained' : 'text'}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <LoadingButton
              color="primary"
              onClick={submitForm}
              isLoading={!isPsaRmpTaskAllocation1Enabled && isSubmitting}
              disabled={isSubmitting || hasErrors || !values.name}
              dataQeId="AddTaskDialog"
              variant={isPsaRmpTaskAllocation1Enabled ? 'contained' : 'text'}
            >
              <FormattedMessage id="button.addTask" />
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

AddTaskDialog.propTypes = {
  parentUri: PropTypes.string.isRequired,
  parentName: PropTypes.string,
  projectSlug: PropTypes.string.isRequired,
  initStartDate: PropTypes.string,
  initEndDate: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  isSubtask: PropTypes.bool,
  createTask: PropTypes.func,
  setMessage: PropTypes.func,
  isMilestone: PropTypes.bool
};

export default AddTaskDialog;
