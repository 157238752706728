import React from 'react';
import { DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ResourceUserSelectionRow } from '~/modules/tasks/components/ChangeTaskResourceDialog/components';
import {
  ChangeResourceAllocationRangeOptions,
  ImpactedTaskSummaryAlert,
  MergeErrorAlert
} from './components';
import { useFormOnChangeHandlers, useMessageSummary } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const ChangeResourceDialogContent = ({
  existingResourceUser,
  hasTaskAssignmentConflict,
  isTaskAssignmentConflictLoading,
  projectId
}) => {
  const classes = useStyles();

  const { values, setFieldValue, setValues } = useFormikContext();

  const { asOfDate, changeType, newResourceUser } = values;

  const {
    onAsOfDateChange,
    onChangeTypeSelect,
    onResourceUserChange
  } = useFormOnChangeHandlers({ setFieldValue, setValues, values });

  const { title, messages } = useMessageSummary({
    allocationHours: 100,
    taskAssignmentCount: 15
  });

  return (
    <DialogContent classes={classes}>
      <ChangeResourceAllocationRangeOptions
        asOfDate={asOfDate}
        changeType={changeType}
        onAsOfDateChange={onAsOfDateChange}
        onChangeTypeSelect={onChangeTypeSelect}
      />
      <ResourceUserSelectionRow
        existingResourceUser={existingResourceUser}
        newResourceUser={newResourceUser}
        onResourceUserChange={onResourceUserChange}
        projectId={projectId}
      />
      <ImpactedTaskSummaryAlert
        title={title}
        severity="info"
        messages={messages}
      />
      {newResourceUser && (
        <MergeErrorAlert
          existingResourceUser={existingResourceUser}
          hasError={hasTaskAssignmentConflict}
          isLoading={isTaskAssignmentConflictLoading}
          newResourceUser={newResourceUser}
        />
      )}
    </DialogContent>
  );
};

ChangeResourceDialogContent.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  hasTaskAssignmentConflict: PropTypes.bool.isRequired,
  isTaskAssignmentConflictLoading: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired
};

export default ChangeResourceDialogContent;
