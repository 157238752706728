import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { ResourceRequestStatus } from '~/types';
import {
  ResourceRequestStatusIndicator,
  ResourceRequestMatchItem
} from '~/modules/resourcing/common/components';
import { useSelectedResourceRequestContext } from '~/modules/projects/resourcing-plan/hooks';
import { ResourceRequestRowDetails } from './components';

const useStyles = makeStyles(theme => ({
  column: {
    flexGrow: 1,
    flexBasis: 100,
    alignSelf: 'stretch',
    padding: 0,
    margin: [[0, 0, 0, -1]],
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&:last-child': {
      borderRight: 'none'
    },
    minWidth: 100
  },
  columnWithFF: {
    display: 'flex',
    alignSelf: 'stretch',
    minWidth: theme.spacing(11.75)
  },
  clickableColumn: {
    cursor: 'pointer',
    '&:hover': {
      background: alpha(theme.palette.action.active, 0.15)
    }
  },
  actionsColumn: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: theme.spacing(9),
    flexBasis: theme.spacing(9)
  },
  statusColumn: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: theme.spacing(1),
    flexBasis: theme.spacing(1)
  },
  actions: {
    flexBasis: theme.spacing(8),
    flexGrow: 0,
    overflow: 'visible',
    padding: theme.spacing(1, 0, 0, 0.25)
  },
  actionsWithFF: {
    display: 'flex',
    flexBasis: theme.spacing(8),
    overflow: 'visible',
    padding: theme.spacing(1, 0, 0, 0.25)
  },
  button: {
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  }
}));

const useStatusIndictorStyles = makeStyles(theme => ({
  root: {
    flexBasis: theme.spacing(0.5),
    width: theme.spacing(0.5),
    height: '100%'
  }
}));

const useDelaStyles = makeStyles(theme => ({
  aiColumn: {
    background: theme.palette.Dela.lightBackground,
    '&:hover > div': {
      background: 'transparent'
    }
  }
}));

export const ResourceRequestItem = ({
  actions,
  resourceRequest,
  isEditSwitchEnabled = true,
  isPsaRmpTaskAllocation1Enabled,
  maxSkillLevel,
  isPsaPraaResourcingUIAndRMRole,
  setAnchorEl,
  setPopoverResourceRequest
}) => {
  const classes = useStyles();
  const statusIndictorClasses = useStatusIndictorStyles();
  const delaClasses = useDelaStyles();
  const selectedResourceRequestContext = useSelectedResourceRequestContext();

  const openDrawer = useCallback(() => {
    const { openResourceRequestDrawer } = selectedResourceRequestContext;

    openResourceRequestDrawer({ resourceRequestId: resourceRequest.id });
  }, [selectedResourceRequestContext, resourceRequest.id]);

  return (
    <>
      <div
        className={classnames({
          [classes.column]: !isPsaRmpTaskAllocation1Enabled,
          [classes.columnWithFF]: isPsaRmpTaskAllocation1Enabled,
          [classes.actionsColumn]:
            isEditSwitchEnabled && !isPsaRmpTaskAllocation1Enabled,
          [classes.statusColumn]:
            !isEditSwitchEnabled && !isPsaRmpTaskAllocation1Enabled,
          [delaClasses.aiColumn]: resourceRequest.isDelaDraftResourceRequest
        })}
      >
        <ResourceRequestStatusIndicator
          classes={isPsaRmpTaskAllocation1Enabled && statusIndictorClasses}
          status={resourceRequest.requestStatus}
        />
        {isEditSwitchEnabled && actions && (
          <div
            data-qe-id="RowTotalAction"
            className={classnames({
              [classes.actions]: !isPsaRmpTaskAllocation1Enabled,
              [classes.actionswithFF]: isPsaRmpTaskAllocation1Enabled
            })}
          >
            {actions}
          </div>
        )}
      </div>

      <ButtonBase
        data-qe-id="ResourceRequestRow"
        className={classnames({
          [classes.button]: isPsaRmpTaskAllocation1Enabled,
          [classes.column]: !isPsaRmpTaskAllocation1Enabled,
          [classes.clickableColumn]: !isPsaRmpTaskAllocation1Enabled,
          [delaClasses.aiColumn]: resourceRequest.isDelaDraftResourceRequest
        })}
        onClick={openDrawer}
      >
        <ResourceRequestRowDetails
          maxSkillLevel={maxSkillLevel}
          resourceRequest={resourceRequest}
          allocations={resourceRequest?.resourceAllocations?.filter(ra =>
            Boolean(ra.user && ra.user.userUri !== null)
          )}
          showResourceAllocation
          isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
          isPsaPraaResourcingUIAndRMRole={isPsaPraaResourcingUIAndRMRole}
        />
      </ButtonBase>
      {isPsaPraaResourcingUIAndRMRole &&
        resourceRequest.requestStatus === ResourceRequestStatus.Submitted && (
          <ResourceRequestMatchItem
            resourceRequest={resourceRequest}
            setAnchorEl={setAnchorEl}
            setPopoverResourceRequest={setPopoverResourceRequest}
          />
        )}
    </>
  );
};

ResourceRequestItem.propTypes = {
  actions: PropTypes.node.isRequired,
  resourceRequest: PropTypes.object.isRequired,
  isEditSwitchEnabled: PropTypes.bool,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  maxSkillLevel: PropTypes.number,
  isPsaPraaResourcingUIAndRMRole: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired
};

export default ResourceRequestItem;
