import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateField } from '~/modules/common/components';
import {
  getLuxonJsDateFormatFromMe,
  mapRepliconDateToMidnightUTCString
} from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me/useMeContext';
import {
  BillingPlanFrequencyDropdown,
  DayOfMonthDropdown,
  DayOfWeekDropdown,
  SemiMonthlyDaysDropdown
} from '~/modules/projects/project/common/components';
import { BillingFrequency } from '~/types';
import { propertiesToRelativeDateMap, relativeDateToPropertyMap } from './util';

const useStyles = makeStyles(theme => ({
  date: {
    lineHeight: 'inherit',
    color: 'inherit',
    fontSize: theme.typography.body2.fontSize,
    display: 'inline',
    maxWidth: 170,
    '& .MuiFormControl-root .MuiFilledInput-root': {
      borderTopLeftRadius: 0
    }
  },
  frequency: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    whiteSpace: 'nowrap'
  },
  prefix: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    width: 60,
    whiteSpace: 'break-spaces',
    lineHeight: '1.22em',
    flexGrow: 0,
    flexShrink: 0,
    display: 'inline-flex',
    backgroundColor: '#e9e9e9',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid #00000061`,
    paddingTop: theme.spacing(2.9),
    paddingBottom: theme.spacing(0.8)
  }
}));

export const BillFrequencyDropdown = ({
  value: frequency,
  billFrequencyRelativeDate: relativeDate,
  onFrequencyChange: onFrequencyChangeCallback,
  onBillFrequencyRelativeDateChange
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const me = useMeContext();

  const dateFormat =
    frequency === BillingFrequency.Biweekly
      ? `EEEE (${getLuxonJsDateFormatFromMe(me)})`
      : 'MMM d';

  const onFrequencyChange = useCallback(
    value => {
      onFrequencyChangeCallback(value);
      onBillFrequencyRelativeDateChange(
        propertiesToRelativeDateMap[value.id]()
      );
    },
    [onBillFrequencyRelativeDateChange, onFrequencyChangeCallback]
  );

  const onDayOfWeekChange = useCallback(
    ({ id: dayOfWeek }) => {
      onBillFrequencyRelativeDateChange(
        propertiesToRelativeDateMap[frequency](dayOfWeek)
      );
    },
    [frequency, onBillFrequencyRelativeDateChange]
  );
  const onDayOfMonthChange = useCallback(
    ({ id: dayOfMonth }) => {
      onBillFrequencyRelativeDateChange(
        propertiesToRelativeDateMap[frequency](dayOfMonth)
      );
    },
    [frequency, onBillFrequencyRelativeDateChange]
  );
  const onSemiMonthlyDaysChange = useCallback(
    ({ id: dayOfMonth }) => {
      onBillFrequencyRelativeDateChange(
        propertiesToRelativeDateMap[frequency](dayOfMonth)
      );
    },
    [frequency, onBillFrequencyRelativeDateChange]
  );
  const onRelativeDateChange = useCallback(
    newDate => {
      onBillFrequencyRelativeDateChange(
        mapRepliconDateToMidnightUTCString(newDate)
      );
    },
    [onBillFrequencyRelativeDateChange]
  );

  const frequencyOffset =
    relativeDateToPropertyMap[frequency] &&
    relativeDateToPropertyMap[frequency](relativeDate);

  return (
    <div className={classes.frequency}>
      <BillingPlanFrequencyDropdown
        frequencies={Object.values(BillingFrequency)}
        value={frequency}
        onChange={onFrequencyChange}
        label={formatMessage({ id: 'billingCard.subTitle.billFrequency' })}
        required
      />

      {frequency === BillingFrequency.Weekly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.on" />
          </Typography>
          <DayOfWeekDropdown
            value={frequencyOffset}
            onChange={onDayOfWeekChange}
          />
        </>
      )}
      {frequency === BillingFrequency.Monthly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.onThe" />
          </Typography>
          <DayOfMonthDropdown
            value={frequencyOffset}
            onChange={onDayOfMonthChange}
          />
        </>
      )}
      {frequency === BillingFrequency.Semimonthly && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage id="billingCard.subTitle.onThe" />
          </Typography>
          <SemiMonthlyDaysDropdown
            value={frequencyOffset}
            onChange={onSemiMonthlyDaysChange}
          />
        </>
      )}
      {(frequency === BillingFrequency.Yearly ||
        frequency === BillingFrequency.Quarterly ||
        frequency === BillingFrequency.Biweekly) && (
        <>
          <Typography className={classes.prefix} variant="caption">
            <FormattedMessage
              id={
                frequency === BillingFrequency.Yearly
                  ? 'billingCard.subTitle.on'
                  : 'billingCard.subTitle.from'
              }
            />
          </Typography>
          <div>
            <DateField
              clearable={false}
              value={relativeDate}
              editable
              format={dateFormat}
              onChange={onRelativeDateChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

BillFrequencyDropdown.propTypes = {
  value: PropTypes.string,
  onFrequencyChange: PropTypes.func,
  onBillFrequencyRelativeDateChange: PropTypes.func,
  billFrequencyRelativeDate: PropTypes.string
};

export default BillFrequencyDropdown;
