import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { omitForbiddenUpdateFields } from '~/modules/resourcing/common/util/resourceAllocationUtil';
import { UPDATE_RESOURCE_ALLOCATION } from '~/modules/resourcing/hooks/useUpdateResourceAllocation';
import useQuickAllocationContext from './useQuickAllocationContext';
import { updateResourceUsersSummaryAllocationBar } from './useFulfilledResourceAllocationHandler';

const updateHours = ({ periodHours, existingPeriodHours = 0, summary }) =>
  periodHours > 0
    ? periodHours - existingPeriodHours + summary.allocatedDuration.hours
    : existingPeriodHours > 0
    ? summary.allocatedDuration.hours - existingPeriodHours
    : summary.allocatedDuration.hours;

export const updateCache = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort,
  periodResolution,
  existingResourceAllocation,
  filter
}) => (proxy, mutationResponse) => {
  const {
    data: {
      updateResourceAllocation2: { resourceAllocation }
    }
  } = mutationResponse;

  updateResourceUsersSummaryAllocationBar({
    resourceUsersCurrentPage,
    proxy,
    resourceAllocation,
    sort: userSort,
    periodResolution,
    dateRange,
    existingResourceAllocation,
    filter,
    updateHoursCallback: updateHours
  });
};

export const onMergeAllocationCacheUpdate = ({
  resourceUsersCurrentPage,
  dateRange,
  userSort,
  periodResolution,
  existingResourceAllocation,
  filter,
  onPopupMenuClose,
  setSnackBarState,
  formatMessage,
  handleAddResourceAllocation,
  projectName
}) => (proxy, mutationResponse) => {
  const {
    data: {
      updateResourceAllocation2: { resourceAllocation }
    }
  } = mutationResponse;

  onPopupMenuClose && onPopupMenuClose();

  setSnackBarState &&
    setSnackBarState({
      open: true,
      message: formatMessage(
        { id: 'addallocationSnackBar.mergedAlloctedMessage' },
        {
          username: resourceAllocation.user.user.displayText,
          projectname: projectName
        }
      )
    });

  handleAddResourceAllocation &&
    handleAddResourceAllocation({
      allocation: resourceAllocation,
      isPsaRmpTaskAllocation1Enabled: true,
      projectName
    });

  updateResourceUsersSummaryAllocationBar({
    resourceUsersCurrentPage,
    proxy,
    resourceAllocation,
    sort: userSort,
    periodResolution,
    dateRange,
    existingResourceAllocation,
    filter,
    updateHoursCallback: updateHours
  });
};

const useResourceAllocationUpdateHandler = () => {
  const {
    resourceUsersCurrentPage,
    sort: userSort,
    dateRange,
    periodResolution,
    filter
  } = useQuickAllocationContext();

  const [updateResourceAllocation, { data, loading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION
  );

  const onUpdateResourceAllocation = useCallback(
    ({
      allocation,
      existingResourceAllocation,
      onPopupMenuClose,
      setSnackBarState,
      formatMessage,
      handleAddResourceAllocation,
      projectName
    }) => {
      updateResourceAllocation({
        variables: {
          input: omitForbiddenUpdateFields(allocation)
        },
        update: onMergeAllocationCacheUpdate({
          resourceUsersCurrentPage,
          dateRange,
          userSort: [userSort],
          periodResolution,
          existingResourceAllocation,
          filter,
          onPopupMenuClose,
          setSnackBarState,
          formatMessage,
          handleAddResourceAllocation,
          projectName
        })
      });
    },
    [
      updateResourceAllocation,
      resourceUsersCurrentPage,
      userSort,
      periodResolution,
      dateRange,
      filter
    ]
  );

  return {
    onUpdateResourceAllocation,
    loading,
    data
  };
};

export default useResourceAllocationUpdateHandler;
