import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ResourceRequestStatusChip } from '~/modules/resourcing/common/components';
import { getResourceRequestStatusStyles } from '~/modules/resourcing/common/util/status-color-util';
import { OpenInDrawerIcon } from '~/modules/common/components/Icons';
import { HiringBadge } from '~/modules/projects/resourcing-plan/components';
import { useHasFeatureFlag } from '~/modules/common/hooks';
import { ResourceRequestDetailIndicator } from '../../ResourceRequestDetailIndicator';
import { ResourceRequestRoleCount } from '../../ResourceRequestRoleCount/ResourceRequestRoleCount';
import ResourceRequestAllocations from '../../ResourceRequestAllocations';

const useStatusChipStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    borderRadius: theme.spacing(2),
    padding: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  label: {
    padding: theme.spacing(0, 1.5)
  },
  ...getResourceRequestStatusStyles(theme)
}));

export const useHiringBadgeStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1, 0, 0),
    width: theme.spacing(20)
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'left',
    alignSelf: 'stretch',
    maxWidth: theme.spacing(46.25),
    '&:hover $openIcon': {
      display: 'block',
      background: 'none'
    }
  },
  informationColumn: {
    flexGrow: 1,
    alignContent: 'flex-start',
    overflow: 'hidden',
    padding: theme.spacing(0.25, 0, 0.5, 0.5)
  },
  statusColumn: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: theme.spacing(5),
    margin: theme.spacing(0, 1, 0, 1)
  },
  openIcon: {
    color: theme.palette.text.secondary,
    opacity: 1,
    display: 'none',
    alignSelf: 'flex-start',
    padding: theme.spacing(1),
    margin: theme.spacing(0, -2, 0, -1),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest
    })
  }
}));

export const ResourceRequestRowDetailsContent2 = ({
  resourceRequest,
  allocations,
  maxSkillLevel
}) => {
  const classes = useStyles();
  const statusChipClasses = useStatusChipStyles();
  const hiringBadgeClasses = useHiringBadgeStyles();

  const hasAllocations = allocations?.length > 0;

  const isPsaDtmRequisitionEnabled = useHasFeatureFlag({
    featureFlag: 'isPsaDtmRequisitionEnabled'
  });

  return (
    <div className={classes.root}>
      <div className={classes.informationColumn}>
        {hasAllocations ? (
          <ResourceRequestAllocations
            allocations={allocations}
            showResourceAllocation
            requestStatus={resourceRequest.requestStatus}
            preferredResources={resourceRequest?.preferredResources}
            isPsaRmpTaskAllocation1Enabled
          />
        ) : (
          <>
            <ResourceRequestRoleCount
              role={resourceRequest.role}
              roleCount={resourceRequest.quantity}
            />
            <ResourceRequestDetailIndicator
              maxSkillLevel={maxSkillLevel}
              resourceRequest={resourceRequest}
            />
          </>
        )}
        {isPsaDtmRequisitionEnabled && (
          <HiringBadge classes={hiringBadgeClasses} />
        )}
      </div>
      <div className={classes.statusColumn}>
        <ResourceRequestStatusChip
          status={resourceRequest.requestStatus}
          classes={statusChipClasses}
        />
        <div className={classes.openIcon}>
          <OpenInDrawerIcon />
        </div>
      </div>
    </div>
  );
};

ResourceRequestRowDetailsContent2.propTypes = {
  resourceRequest: PropTypes.object.isRequired,
  allocations: PropTypes.array,
  maxSkillLevel: PropTypes.number
};

export default ResourceRequestRowDetailsContent2;
