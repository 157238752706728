import { useProjectAllocationHours } from './getProjectAllocationHours';
import { useTaskAllocationHours } from './getTaskAllocationHoursForResourceUser';

export const useRemainingProjectAllocationHours = ({
  projectId,
  userId,
  startDate,
  endDate,
  skip
}) => {
  const {
    loading: isProjectAllocationHoursLoading,
    projectAllocationHoursInDateRange
  } = useProjectAllocationHours({
    projectId,
    userId,
    startDate,
    endDate,
    skip
  });

  const {
    loading: isTaskAllocationHoursLoading,
    taskAllocationHoursInDateRange
  } = useTaskAllocationHours({
    projectId,
    userId,
    startDate,
    endDate,
    skip
  });

  return {
    loading: isProjectAllocationHoursLoading || isTaskAllocationHoursLoading,
    remainingProjectAllocationHours: Math.max(
      (projectAllocationHoursInDateRange || 0) -
        (taskAllocationHoursInDateRange || 0),
      0
    )
  };
};

export default useRemainingProjectAllocationHours;
