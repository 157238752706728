import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Skeleton } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { Typography, makeStyles } from '@material-ui/core';
import { getTextInBold } from './ImpactedTaskSummaryAlert';

const useStyles = makeStyles(theme => ({
  skeleton: {
    marginBottom: theme.spacing(-3)
  },
  alert: {
    marginTop: theme.spacing(2)
  }
}));

export const MergeErrorAlert = ({
  existingResourceUser,
  hasError,
  isLoading,
  newResourceUser
}) => {
  const classes = useStyles();

  return (
    <>
      {isLoading ? (
        <Skeleton height={100} width="100%" className={classes.skeleton} />
      ) : (
        <>
          {hasError && (
            <Alert severity="error" icon={false} className={classes.alert}>
              <Typography variant="body2">
                <FormattedMessage
                  id="changeResourceDialog.mergeErrorMessages.alreadyAssignedTask"
                  values={getTextInBold({
                    newResourceUserName: newResourceUser.displayText,
                    existingResourceUserName: existingResourceUser.displayText
                  })}
                />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id="changeResourceDialog.mergeErrorMessages.combiningNotSupported" />
              </Typography>
            </Alert>
          )}
        </>
      )}
    </>
  );
};

MergeErrorAlert.propTypes = {
  existingResourceUser: PropTypes.object.isRequired,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  newResourceUser: PropTypes.object.isRequired
};

export default MergeErrorAlert;
