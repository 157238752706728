import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '~/modules/common/components/LoadingButton';

export const ChangeResourceDialogFooter = ({
  hasTaskAssignmentConflict,
  isTaskAssignmentConflictLoading,
  newResourceUser,
  onClose
}) => {
  return (
    <DialogActions>
      <Button onClick={onClose}>
        <FormattedMessage id="changeResourceDialog.cancel" />
      </Button>
      <LoadingButton
        color="primary"
        disabled={
          !newResourceUser ||
          isTaskAssignmentConflictLoading ||
          hasTaskAssignmentConflict
        }
      >
        <FormattedMessage id="changeResourceDialog.changeResource" />
      </LoadingButton>
    </DialogActions>
  );
};

ChangeResourceDialogFooter.propTypes = {
  hasTaskAssignmentConflict: PropTypes.bool.isRequired,
  isTaskAssignmentConflictLoading: PropTypes.bool.isRequired,
  newResourceUser: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChangeResourceDialogFooter;
