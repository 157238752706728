import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  message: {
    fontSize: theme.typography.caption,
    color: theme.palette.text.secondary
  },
  help: {
    '&:hover': {
      cursor: 'help'
    }
  },
  warning: {
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(2)
  }
}));

export const ProgressItemError = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'dashboardProgressChart.errorLoadingDataTooltip'
      })}
    >
      <span className={classes.container}>
        <WarningSharpIcon className={classes.warning} />
        <span className={classNames(classes.message, classes.help)}>
          <FormattedMessage id="dashboardProgressChart.errorLoadingData" />
        </span>
      </span>
    </Tooltip>
  );
};

ProgressItemError.propTypes = {};

export default ProgressItemError;
