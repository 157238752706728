import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useResourceRequestToolbarContext } from '~/modules/projects/resourcing-plan/hooks';
import { checkIfResourceRequestIsReadOnly } from '~/modules/resourcing/common/util';
import { ResourceRequestAllocationActions } from '~/modules/resourcing/common/components';
import {
  useMergeDialogState,
  useRowDynamicStyle
} from '~/modules/resourcing/common/hooks';
import { ResourceRequestAccessLevel } from '~/types';
import { StyledChartRangeItem2 } from '~/modules/common/charts/timeline/components';
import { STYLED_CHART_RANGE_ITEM_VARIANT } from '~/modules/common/charts/timeline/components/ChartRangeItem2/StyledChartRangeItem2';
import { useProjectContext } from '~/modules/resourcing/common/contexts';
import { updateCacheForCompletedAndMergedRequests } from '~/modules/resourcing/common/hooks/useMarkResourceRequestAsCompleteAndMerge';
import { ResourceRequestItem } from '../ResourceRequestItem';
import { ResourceRequestChartTotal } from './components';
import ResourceRequestChartTimeLineRow from './ResourceRequestChartTimeLineRow';

const useStyles = makeStyles(() => ({
  root: {},
  itemContainer: {
    zIndex: 4
  },
  totalContainer: {
    padding: 'unset',
    alignItems: 'stretch',
    zIndex: 16
  }
}));

export const ResourceRequestChartRow = ({
  scale,
  chartDates,
  chartDisplayDateRange,
  resourceRequest,
  leftComponentWidth,
  resourceAllocations,
  isEditSwitchEnabled,
  requestAccessLevel,
  displayUnit,
  allocationChartRef,
  isPsaRmpTaskAllocation1Enabled,
  maxSkillLevel,
  isPsaPraaResourcingUIAndRMRole,
  setAnchorEl,
  setPopoverResourceRequest
}) => {
  const { project } = useProjectContext();
  const { startDate, endDate } = project;
  const classes = useStyles();
  const theme = useTheme();
  const { isResourceActualModeEnabled } = useResourceRequestToolbarContext();
  const mergeDialogState = useMergeDialogState();

  const numberOfRows = useMemo(
    () => Math.max(resourceRequest.quantity, resourceAllocations?.length || 0),
    [resourceRequest, resourceAllocations]
  );

  const dynamicContainerHeight = useMemo(
    () =>
      numberOfRows > 0
        ? theme.spacing(3) * numberOfRows +
          theme.spacing(0.5) * (numberOfRows - 1) +
          theme.spacing(1) * 2
        : 36,
    [numberOfRows]
  );

  const { dynamicRowStyle } = useRowDynamicStyle({
    dynamicContainerHeight
  });
  const isResourceRequestReadOnly = checkIfResourceRequestIsReadOnly(
    resourceRequest
  );
  const request = useMemo(
    () => ({
      ...resourceRequest,
      resourceAllocations
    }),
    [resourceRequest, resourceAllocations]
  );

  const { permittedActionUris } = project;

  const canViewCostData = permittedActionUris.includes(
    'urn:replicon:project-action:view-cost-data'
  );

  const cacheUpdateParams = {
    refetchQueries: ['getProjectTotalsBySlug'],
    updateMergeCache: updateCacheForCompletedAndMergedRequests
  };

  const actions = useMemo(
    () => (
      <ResourceRequestAllocationActions
        project={project}
        resourceRequest={request}
        isProjectManagerView
        requestAccessLevel={requestAccessLevel}
        chartDisplayDateRange={chartDisplayDateRange}
        allocationChartRef={allocationChartRef}
        isResourceActualModeEnabled={isResourceActualModeEnabled}
        isResourceRequestMergeEnabled={isPsaRmpTaskAllocation1Enabled}
        mergeDialogState={mergeDialogState}
        scale={scale}
        displayUnit={displayUnit}
        cacheUpdateParams={cacheUpdateParams}
      />
    ),
    [
      request,
      resourceAllocations,
      isPsaRmpTaskAllocation1Enabled,
      mergeDialogState,
      scale,
      displayUnit,
      cacheUpdateParams
    ]
  );

  const requestItem = useMemo(
    () => (
      <ResourceRequestItem
        actions={actions}
        resourceRequest={request}
        isEditSwitchEnabled={isEditSwitchEnabled}
        isPsaRmpTaskAllocation1Enabled={isPsaRmpTaskAllocation1Enabled}
        maxSkillLevel={maxSkillLevel}
        isPsaPraaResourcingUIAndRMRole={isPsaPraaResourcingUIAndRMRole}
        setAnchorEl={setAnchorEl}
        setPopoverResourceRequest={setPopoverResourceRequest}
      />
    ),
    [
      actions,
      request,
      isEditSwitchEnabled,
      isPsaRmpTaskAllocation1Enabled,
      maxSkillLevel,
      isPsaPraaResourcingUIAndRMRole
    ]
  );

  const totalItem = useMemo(
    () => (
      <ResourceRequestChartTotal
        canViewCostData={canViewCostData}
        resourceRequest={resourceRequest}
        resourceAllocations={resourceAllocations}
        contextCurrency={resourceRequest.currency}
        displayUnit={displayUnit}
      />
    ),
    [canViewCostData, resourceAllocations, resourceRequest]
  );

  const timeLineItems = useMemo(
    () => (
      <ResourceRequestChartTimeLineRow
        scale={scale}
        startDate={startDate}
        endDate={endDate}
        resourceRequest={resourceRequest}
        chartDates={chartDates}
        isEditSwitchEnabled={isEditSwitchEnabled}
        resourceAllocations={resourceAllocations}
        isResourceRequestReadOnly={isResourceRequestReadOnly}
        chartDisplayDateRange={chartDisplayDateRange}
        canViewCostData={canViewCostData}
        displayUnit={displayUnit}
        dynamicContainerHeight={dynamicContainerHeight}
      />
    ),
    [
      scale,
      startDate,
      endDate,
      resourceRequest,
      chartDates,
      isEditSwitchEnabled,
      resourceAllocations,
      isResourceRequestReadOnly,
      chartDisplayDateRange,
      canViewCostData,
      displayUnit
    ]
  );

  return (
    <>
      <StyledChartRangeItem2
        classes={classes}
        variant={
          resourceRequest.isDelaDraftResourceRequest
            ? STYLED_CHART_RANGE_ITEM_VARIANT.PURPLE
            : undefined
        }
        scale={scale}
        scaleItemCount={chartDates.length}
        chartStartDate={chartDates.length && chartDates[0].start}
        dynamicStyle={dynamicRowStyle}
        leftComponentWidth={leftComponentWidth}
      >
        {requestItem}
        {timeLineItems}
        {totalItem}
      </StyledChartRangeItem2>
    </>
  );
};

ResourceRequestChartRow.propTypes = {
  scale: PropTypes.string.isRequired,
  chartDates: PropTypes.array,
  resourceRequest: PropTypes.object.isRequired,
  resourceAllocations: PropTypes.array,
  isEditSwitchEnabled: PropTypes.bool.isRequired,
  leftComponentWidth: PropTypes.number.isRequired,
  chartDisplayDateRange: PropTypes.object.isRequired,
  requestAccessLevel: PropTypes.oneOf([
    ResourceRequestAccessLevel.All,
    ResourceRequestAccessLevel.ResourceManager
  ]),
  displayUnit: PropTypes.string,
  allocationChartRef: PropTypes.object,
  isPsaRmpTaskAllocation1Enabled: PropTypes.bool,
  maxSkillLevel: PropTypes.number,
  isPsaPraaResourcingUIAndRMRole: PropTypes.bool,
  setAnchorEl: PropTypes.func.isRequired,
  setPopoverResourceRequest: PropTypes.func.isRequired
};

export default ResourceRequestChartRow;
