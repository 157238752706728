import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Skeleton, Alert } from '@material-ui/lab';
import classNames from 'classnames';
import ArrowPopup from '~/modules/common/components/ArrowPopup';
import { RoleDropdownRow } from '~/modules/resourcing/components/AllocateResourcePopup/RoleDropDownRow.js';
import UserAvailabilityDropdown from '~/modules/common/components/UserAvailabilityDropdown/UserAvailabilityDropdown';
import {
  DateField,
  LoadingButton,
  NumberTextField
} from '~/modules/common/components';
import { CreatableProjectDropdown } from '~/modules/common/components/CreatableProjectDropdown';
import { getDefaultCustomPopper } from '~/modules/common/components/CustomPopper';
import { mapIsoStringtoUtcObject } from '~/modules/common/dates/convert';
import { useMeContext } from '~/modules/me';
import { getReactNumberFormatFromMe } from '~/modules/common/numbers';
import { InputFieldAdornment } from '~/modules/resourcing/common/components/ResourceRequestDrawer/ResourceRequestDrawerDetails/ResourceDrawerAdornment';
import { useAllocateResourcePopupFormChange } from './hooks/useAllocateResourcePopupHandler';
import useStyles, {
  useRowStyles,
  usePopperStyles,
  useRoleStyles
} from './useStyles';

const endAdornment = <InputFieldAdornment adornmentSymbol="%" />;
const AllocateResourcePopup = ({
  anchorEl,
  onClose,
  fetchProjectDetails,
  handleAdd,
  showAddProject,
  showAddUser,
  totalScheduledHours,
  setTotalScheduledHours,
  scale,
  isSaving = false,
  showLoading = false,
  defaultUser,
  setExistingResourceAllocation,
  existingResourceAllocation
}) => {
  const classes = useStyles();
  const popperClasses = usePopperStyles();
  const roleClasses = useRoleStyles();
  const rowClasses = useRowStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue, handleSubmit, setValues } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const me = useMeContext();
  const {
    featureFlags: { isPsaRmpTaskAllocation1Enabled }
  } = me;
  const { decimalScale } = getReactNumberFormatFromMe(me);

  const {
    onStartDateUpdate,
    onEndDateUpdate,
    onTotalAllocatedHoursUpdate,
    onLoadingUpdate,
    onUserUpdate: onAllocationUserUpdate,
    onUserUpdateForTaskAllocation,
    onRoleUpdate,
    onProjectUpdate
  } = useAllocateResourcePopupFormChange({
    setFieldValue,
    scale,
    setValues,
    values,
    fetchProjectDetails,
    totalScheduledHours,
    setTotalScheduledHours,
    setExistingResourceAllocation,
    isPsaRmpTaskAllocation1Enabled
  });

  const onUserUpdate = isPsaRmpTaskAllocation1Enabled
    ? onUserUpdateForTaskAllocation
    : onAllocationUserUpdate;

  const onStartDateChange = useCallback(
    async event => {
      setIsLoading(true);
      await onStartDateUpdate(event);
      setIsLoading(false);
    },
    [onStartDateUpdate, setIsLoading]
  );

  const onEndDateChange = useCallback(
    async event => {
      setIsLoading(true);
      await onEndDateUpdate(event);
      setIsLoading(false);
    },
    [onEndDateUpdate, setIsLoading]
  );

  const onUserChange = useCallback(
    async event => {
      setIsLoading(true);
      await onUserUpdate(event);
      setIsLoading(false);
    },
    [onUserUpdate, setIsLoading]
  );

  useEffect(() => {
    if (defaultUser) {
      onUserChange(defaultUser);
    }
  }, [onUserChange, defaultUser]);

  const onProjectChange = useCallback(
    async event => {
      if (event && event.isCreatable) return;

      setIsLoading(true);
      await onProjectUpdate(event);
      setIsLoading(false);
    },
    [onProjectUpdate, setIsLoading]
  );

  const {
    totalAllocatedHours,
    startDate,
    endDate,
    load,
    role,
    project,
    user
  } = values;

  const dateRange = useMemo(
    () => ({
      startDate: mapIsoStringtoUtcObject(startDate),
      endDate: mapIsoStringtoUtcObject(endDate)
    }),
    [endDate, startDate]
  );

  const SaveButton = showLoading ? LoadingButton : Button;

  const loadingButtonProps = useMemo(
    () =>
      showLoading
        ? {
            isLoading: isSaving,
            disabled:
              isSaving ||
              (isPsaRmpTaskAllocation1Enabled && isLoading) ||
              (!user && !!project) ||
              (!project && !!user) ||
              (load && totalAllocatedHours) === 0
          }
        : {},
    [
      isLoading,
      isPsaRmpTaskAllocation1Enabled,
      isSaving,
      load,
      project,
      showLoading,
      totalAllocatedHours,
      user
    ]
  );
  const onSubmit = useCallback(
    event => {
      handleSubmit(event);
      handleAdd(values);

      if (!showLoading) {
        onClose(event, true);
      }
    },
    [handleAdd, handleSubmit, onClose, showLoading, values]
  );

  return (
    <ArrowPopup
      id="allocate-resources-popup"
      anchorEl={anchorEl}
      placement="top"
      onClose={onClose}
      preventOverflow
      data-qe-id="AllocateResourcesArrowPopup"
      classes={popperClasses}
    >
      {showAddProject && (
        <div className={classes.addProjectDropdown}>
          {isLoading && <Skeleton height={36} width={360} />}
          {!isLoading && (
            <CreatableProjectDropdown
              onChange={onProjectChange}
              placeholder={
                isPsaRmpTaskAllocation1Enabled || project
                  ? ''
                  : formatMessage({ id: 'quickAllocation.addProject' })
              }
              label={
                isPsaRmpTaskAllocation1Enabled || project
                  ? formatMessage({ id: 'quickAllocation.project' })
                  : ''
              }
              variant="filled"
              dataQeId="AddProjectDropdown"
              value={project || null}
              name="project"
              customPopperComponent={getDefaultCustomPopper()}
            />
          )}
        </div>
      )}
      {!showAddProject && !isPsaRmpTaskAllocation1Enabled && (
        <RoleDropdownRow
          value={role || null}
          onChange={onRoleUpdate}
          label={
            role
              ? formatMessage({ id: 'resourceRequestTitle.role' })
              : undefined
          }
          placeholder={
            role ? '' : formatMessage({ id: 'resourceRequestTitle.anyRole' })
          }
          isLoading={isLoading}
        />
      )}

      <div
        className={classNames(classes.dateRow, {
          [classes.dateRowOnTop]: isPsaRmpTaskAllocation1Enabled && showAddUser
        })}
      >
        <div className={classes.dateField}>
          {isLoading && <Skeleton height={36} width={180} />}
          {!isLoading && (
            <DateField
              variant="standard"
              data-qe-id="AllocateResourceStartDate"
              label={formatMessage({
                id: 'allocateResourcesContent.startDate'
              })}
              ariaLabel={formatMessage({
                id: 'allocateResourcesContent.startDate'
              })}
              name="startDate"
              value={startDate}
              onChange={onStartDateChange}
              editable
              clearable={false}
            />
          )}
        </div>
        <div className={classes.dateField}>
          {isLoading && <Skeleton height={36} width={180} />}
          {!isLoading && (
            <DateField
              variant="standard"
              data-qe-id="AllocateResourceEndDate"
              label={formatMessage({
                id: 'allocateResourcesContent.endDate'
              })}
              ariaLabel={formatMessage({
                id: 'allocateResourcesContent.endDate'
              })}
              name="endDate"
              value={endDate}
              onChange={onEndDateChange}
              editable
              clearable={false}
            />
          )}
        </div>
      </div>
      <div className={classes.dateRow}>
        <div className={classes.loading}>
          {isLoading && <Skeleton height={36} width={180} />}
          {!isLoading && (
            <NumberTextField
              disabled={!user}
              fullWidth
              align="left"
              label={formatMessage({
                id: 'allocateResourcesContent.loading'
              })}
              onChange={onLoadingUpdate}
              value={parseFloat(load.toFixed(decimalScale))}
              ariaLabel={formatMessage({
                id: 'allocateResourcesContent.loading'
              })}
              min={0}
              max={999}
              endAdornment={endAdornment}
              precision={decimalScale}
              variant="standard"
              step="any"
              helperText={
                <>
                  {load <= 0 && (
                    <div className={classes.underloaded}>
                      {formatMessage(
                        {
                          id: 'resourceRequestDrawerDetails.minValueForLoad'
                        },
                        {
                          value: 0
                        }
                      )}
                    </div>
                  )}
                </>
              }
            />
          )}
        </div>
        <div className={classes.allocated}>
          {isLoading && <Skeleton height={36} width={180} />}
          {!isLoading && (
            <NumberTextField
              disabled={!user}
              hiddenLabel
              fullWidth
              align="center"
              onChange={onTotalAllocatedHoursUpdate}
              value={parseFloat(totalAllocatedHours.toFixed(decimalScale))}
              ariaLabel={formatMessage({
                id: 'allocateResourcesContent.totalAllocatedHours'
              })}
              label={formatMessage({
                id: 'allocateResourcesContent.totalAllocatedHours'
              })}
              min={0}
              max={99999}
              precision={decimalScale}
              variant="standard"
            />
          )}
        </div>
      </div>
      {((showAddProject && !isPsaRmpTaskAllocation1Enabled) ||
        (!showAddProject && isPsaRmpTaskAllocation1Enabled)) && (
        <RoleDropdownRow
          value={role || null}
          classes={isPsaRmpTaskAllocation1Enabled ? roleClasses : rowClasses}
          onChange={onRoleUpdate}
          label={
            role || isPsaRmpTaskAllocation1Enabled
              ? formatMessage({ id: 'resourceRequestTitle.role' })
              : undefined
          }
          placeholder={
            role || isPsaRmpTaskAllocation1Enabled
              ? ''
              : formatMessage({ id: 'resourceRequestTitle.anyRole' })
          }
          isLoading={isLoading}
        />
      )}
      {showAddUser && (
        <div
          className={classNames({
            [classes.resourceDropdown]: !isPsaRmpTaskAllocation1Enabled,
            [classes.resourceDropdownFFOn]: isPsaRmpTaskAllocation1Enabled
          })}
        >
          {isLoading && <Skeleton height={36} width={360} />}
          {!isLoading && (
            <UserAvailabilityDropdown
              dataQeId="UserAvailabilityDropdown"
              dateRange={dateRange}
              onChange={onUserChange}
              value={user || null}
              userRole={role || null}
              placeholder={
                user || isPsaRmpTaskAllocation1Enabled
                  ? ''
                  : formatMessage({
                      id: 'addResource.selectResource'
                    })
              }
              variant="filled"
              optionTypeText={formatMessage({
                id: 'moreOptions.users'
              })}
              hiddenLabel={false}
              disableOnBlur
            />
          )}
        </div>
      )}
      {isPsaRmpTaskAllocation1Enabled && existingResourceAllocation && (
        <div className={classes.alertMessage}>
          <Alert severity="info" icon={false}>
            <FormattedMessage id="addResource.alertMessage" />
          </Alert>
        </div>
      )}
      <div className={classes.row}>
        <SaveButton
          data-qe-id="AllocateResourceAddButton"
          className={classes.actionButton}
          color="primary"
          onClick={onSubmit}
          variant="contained"
          disabled={
            (!user && !!project) ||
            (!project && !!user) ||
            (load && totalAllocatedHours) === 0
          }
          {...loadingButtonProps}
        >
          <FormattedMessage id="allocateResourcesContent.allocate" />
        </SaveButton>

        <Button
          data-qe-id="AllocateResourceCancelButton"
          className={classes.actionButton}
          onClick={onClose}
          variant="contained"
        >
          <FormattedMessage id="allocateResourcesContent.cancel" />
        </Button>
      </div>
    </ArrowPopup>
  );
};

AllocateResourcePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired,
  fetchProjectDetails: PropTypes.func,
  showAddUser: PropTypes.bool,
  showAddProject: PropTypes.bool,
  handleAdd: PropTypes.func,
  totalScheduledHours: PropTypes.number,
  setTotalScheduledHours: PropTypes.func,
  scale: PropTypes.string,
  isSaving: PropTypes.bool,
  showLoading: PropTypes.bool,
  defaultUser: PropTypes.object,
  setExistingResourceAllocation: PropTypes.func,
  existingResourceAllocation: PropTypes.object
};

export default AllocateResourcePopup;
