import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TextField, Grid } from '@material-ui/core';
import { useMeContext } from '~/modules/me/useMeContext';

const getAriaLabel = ariaLabel => ({ 'aria-label': ariaLabel });

export const WebAddressForm = ({
  className,
  name,
  value,
  errors,
  onChange,
  onNameChange,
  showWebAddressNameField
}) => {
  const { formatMessage } = useIntl();
  const {
    featureFlags: { isPsaPrpWcagFixesQ4Enabled }
  } = useMeContext();

  const handleChange = React.useCallback(
    ({ target: { value: identityUri } }) =>
      onChange({
        identityUri,
        keyValues: null,
        linkUri: identityUri
      }),
    [onChange]
  );

  const handleNameChange = React.useCallback(
    ({ target: { value: linkName } }) => onNameChange(linkName),
    [onNameChange]
  );

  const label = {
    name: formatMessage({ id: 'fileWebAddressDialog.name' }),
    url: formatMessage({ id: 'fileWebAddressDialog.pasteAUrlHere' })
  };

  const displayValue = value ? value.linkUri : '';
  const displayNameValue = name || '';
  const hasError = Boolean(errors && errors.link && errors.link.linkUri);

  return (
    <div className={className}>
      <Grid container direction="column" spacing={2}>
        {showWebAddressNameField && (
          <Grid item>
            <TextField
              variant="filled"
              fullWidth
              autoFocus
              label={label.name}
              value={displayNameValue}
              onChange={handleNameChange}
              inputProps={getAriaLabel(label.name)}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            variant="filled"
            fullWidth
            autoFocus={
              isPsaPrpWcagFixesQ4Enabled ? !showWebAddressNameField : true
            }
            label={label.url}
            value={displayValue}
            onChange={handleChange}
            error={hasError}
            helperText={hasError && errors.link && errors.link.linkUri}
            inputProps={getAriaLabel(label.url)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WebAddressForm.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onNameChange: PropTypes.func,
  showWebAddressNameField: PropTypes.bool
};

export default WebAddressForm;
