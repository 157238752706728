import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import ReadOnlyCard from './ReadOnlyCard';
import ReadOnlyExpandableCard from './ReadOnlyCard/ReadOnlyExpandableCard';
import EditCard from './EditCard';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    paddingBottom: theme.spacing(2)
  },
  contentRoot: {},
  title: {}
}));

export const onSave = (baseOnSave, onStopEdit, setSaving) => async () => {
  setSaving(true);
  const result = await baseOnSave();

  setSaving(false);
  // This is hack to avoid breaking EditableCard behaviour in ProjectInfo and ResourcePool etc. baseOnSave promise should return true or false.
  // Once all baseOnSave changed to return true or false. It should be changed to if(result)
  if (result !== false) {
    onStopEdit();
  }
};

const onCancel = (baseOnCancel, onStopEdit) => () => {
  baseOnCancel();
  onStopEdit();
};

const EditableCard = ({
  editable,
  edit = false,
  classes: classesOverride,
  dialogActionClasses,
  dataQeId,
  className,
  editContentClassName,
  fullScreen,
  maxWidth,
  children,
  dialogClasses,
  ariaDialogLabelId,
  ariaLabelKey,
  ariaLabel = null
}) => {
  const classes = useStyles({ classes: classesOverride });

  const [editing, setEditing] = useState(edit);
  const [isSaving, setSaving] = useState(false);
  const onEdit = useCallback(() => setEditing(true), []);
  const onStopEdit = useCallback(() => setEditing(false), []);
  const { formatMessage } = useIntl();

  const dialogAriaLabel =
    ariaLabel ||
    formatMessage({
      id: ariaLabelKey
    });
  const editIconAriaLabel = value =>
    formatMessage(
      {
        id: 'button.editButton'
      },
      {
        editButtonLabel: value
      }
    );

  return (
    <div className={classNames(className, classes.card)} data-qe-id={dataQeId}>
      {React.Children.toArray(children)
        .filter(child => child.type.displayName === 'ReadOnly')
        .map(child => {
          const ReadOnlyVariant = child.props.expandable
            ? ReadOnlyExpandableCard
            : ReadOnlyCard;

          return (
            <ReadOnlyVariant
              className={classNames(
                className,
                classes.contentRoot,
                classes.title
              )}
              key={child.type.displayName}
              editable={editable}
              onEdit={onEdit}
              headerButtons={child.headerButtons}
              ariaLabel={editIconAriaLabel(dialogAriaLabel)}
              {...child.props}
            >
              {child.props.children}
            </ReadOnlyVariant>
          );
        })}
      {React.Children.toArray(children)
        .filter(child => child.type.displayName === 'Edit')
        .map(child => (
          <EditCard
            key={child.type.displayName}
            open={editing}
            saveable={child.props.saveable}
            isSaving={isSaving}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            className={editContentClassName}
            classes={dialogActionClasses}
            onSave={onSave(child.props.onSave, onStopEdit, setSaving)}
            onCancel={onCancel(child.props.onCancel, onStopEdit)}
            validateForm={child.props.validateForm}
            onRemove={child.props.onRemove}
            actionLabel={child.props.actionLabel}
            actionComponentCustomProps={child.props.actionComponentCustomProps}
            ActionComponent={child.props.ActionComponent}
            dialogClasses={dialogClasses}
            ariaLabel={dialogAriaLabel}
            ariaDialogLabelId={ariaDialogLabelId}
          >
            {child.props.children}
          </EditCard>
        ))}
    </div>
  );
};

EditableCard.propTypes = {
  editable: PropTypes.bool,
  edit: PropTypes.bool,
  dataQeId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.any,
  classes: PropTypes.object,
  editContentClassName: PropTypes.string,
  dialogActionClasses: PropTypes.object,
  actionComponentCustomProps: PropTypes.bool,
  dialogClasses: PropTypes.object,
  ariaDialogLabelId: PropTypes.string,
  ariaLabelKey: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default EditableCard;
