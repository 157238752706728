import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

export const ADD_PROJECT_MUTATION = gql`
  mutation AddProject($projectInput: ProjectInput!) {
    addProject2(projectInput: $projectInput) {
      project {
        id
        slug
        name
        code
        displayText
        startDate
        endDate
        keyValues {
          keyUri
          value
        }
        clients {
          client {
            displayText
            id
          }
        }
        projectManagerReference {
          id
          displayText
        }
        budgetHours
        totalEstimatedContract {
          amount
          currency {
            id
            displayText
          }
        }
        budgetedCost {
          amount
          currency {
            id
            displayText
          }
        }
        estimatedCost {
          amount
          currency {
            id
            displayText
          }
        }
        defaultBillingCurrency {
          id
          displayText
        }
        billingType {
          displayText
          uri
        }
        billingContract {
          displayText
          slug
          uri
        }
      }
      errors {
        id
        displayText
        failureUri
        severityUri
      }
    }
  }
`;

export const useAddProject = () => {
  const [addProjectMutation] = useMutation(ADD_PROJECT_MUTATION);

  return addProjectMutation;
};
