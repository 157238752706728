import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  estimateRowContainer: {
    padding: theme.spacing(1, 0, 0)
  },
  estimateCardContainer: {
    margin: theme.spacing(0, 0, 2),
    height: theme.spacing(9)
  },
  allocationCardContainer: {
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(30),
    margin: theme.spacing(0, 0, 2)
  },
  roleContainer: {
    minWidth: theme.spacing(10),
    padding: theme.spacing(0.5, 0, 0.5, 1)
  }
}));

export const TaskResourceEstimatesRowSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.roleContainer}>
        <Skeleton width={175} height={45} variant="rect" />
      </Grid>
      <Grid container className={classes.estimateRowContainer} spacing={2}>
        <Grid item className={classes.estimateCardContainer}>
          <Skeleton width={400} height={65} variant="rect" />
        </Grid>
        <Grid item className={classes.allocationCardContainer}>
          <Skeleton width={240} height={65} variant="rect" />
        </Grid>
      </Grid>
    </>
  );
};

TaskResourceEstimatesRowSkeleton.propTypes = {};

export default TaskResourceEstimatesRowSkeleton;
