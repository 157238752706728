import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TagIcon } from '~/modules/common/components/Icons';
import { TagsCard } from '~/modules/tags';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(-3, -2, -6, -2)
  },
  title: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(0.5)
  },
  readOnlyCard: {
    marginTop: theme.spacing(-2),
    flexDirection: 'column'
  },
  readOnlyCardHeader: {
    borderBottom: 'none',
    paddingBottom: 0
  },
  noTags: {
    marginTop: theme.spacing(1)
  },
  tagItem: {
    width: '100%',
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(-1),
    flexDirection: 'row',
    paddingBottom: theme.spacing(0)
  },
  tagValue: {
    fontSize: theme.typography.body2.fontSize,
    flexBasis: '50%',
    paddingLeft: theme.spacing(1)
  },
  tagLabel: {
    flexBasis: '50%',
    alignSelf: 'center'
  },
  tagSkeletonContainer: {
    flexDirection: 'column'
  },
  tagSkeletonItem: {
    flexDirection: 'row'
  },
  action: {
    marginRight: theme.spacing(0)
  }
}));

export const TagsIcon = <TagIcon />;
export const TagsTitle = <FormattedMessage id="tags.title" />;

const RESOURCE_TAGS_QUERY = gql`
  query resourceTags($userSlug: String!) {
    resource(userSlug: $userSlug) {
      id
      tags {
        id
        tag {
          id
          name
        }
        value {
          id
          value
        }
      }
    }
  }
`;

const DEFAULT_TAGS_RESPONSE = [];

const ResourceTagsCard = ({
  classes: classesOverride,
  editable,
  userDetails: { id, slug },
  ...rest
}) => {
  const { data, loading } = useQuery(RESOURCE_TAGS_QUERY, {
    variables: {
      userSlug: slug
    },
    fetchPolicy: 'cache-and-network'
  });

  const classes = useStyles({ classes: classesOverride });

  const tagsCardClasses = useMemo(
    () => ({
      title: classes.title,
      card: classes.card,
      readOnlyCard: classes.readOnlyCard,
      readOnlyCardHeader: classes.readOnlyCardHeader,
      noTags: classes.noTags,
      tagItem: classes.tagItem,
      tagValue: classes.tagValue,
      tagLabel: classes.tagLabel,
      tagSkeletonContainer: classes.tagSkeletonContainer,
      tagSkeletonItem: classes.tagSkeletonItem,
      action: classes.action
    }),
    [
      classes.card,
      classes.readOnlyCard,
      classes.readOnlyCardHeader,
      classes.noTags,
      classes.tagItem,
      classes.tagLabel,
      classes.tagSkeletonContainer,
      classes.tagSkeletonItem,
      classes.tagValue,
      classes.title,
      classes.action
    ]
  );

  const refetchQuery = useMemo(
    () => ({
      query: RESOURCE_TAGS_QUERY,
      variables: {
        userSlug: slug
      }
    }),
    [slug]
  );

  const ariaAttributes = useMemo(() => ({ 'aria-level': 6 }));

  return (
    <TagsCard
      classes={tagsCardClasses}
      showReadOnlyAvatar={false}
      title={TagsTitle}
      icon={TagsIcon}
      objectId={id}
      refetchQuery={refetchQuery}
      tags={
        data && data.resource && data.resource.tags
          ? data.resource.tags
          : DEFAULT_TAGS_RESPONSE
      }
      loading={loading}
      editable={editable}
      headerDividerVariant="middle"
      ariaAttributes={ariaAttributes}
      {...rest}
    />
  );
};

ResourceTagsCard.propTypes = {
  classes: PropTypes.object,
  userDetails: PropTypes.object,
  editable: PropTypes.bool
};

export default ResourceTagsCard;
