import { object, number } from 'yup';
import { useIntl } from 'react-intl';
import { useFormikContext as useDrawerFormikContext } from 'formik';
import { useMeContext } from '~/modules/me';
import { toRepliconDate } from '~/modules/common/dates/convert';
import { getProjectAvailableHours } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskAllocationEditor/common/taskAllocationEditorUtil';
import { getTotalHoursForDateRangeFromScheduleRules } from '~/modules/resourcing/common/util/scheduleUtil';
import { calculateProjectAllocationHours } from '~/modules/resourcing/common/util';

import useOnSubmit from '../../common/useOnSubmit';

export const getInitialValues = ({
  startDate,
  endDate,
  resourceAllocationScheduleRules,
  otherTaskAllocationsSummaryScheduleRules,
  taskResourceUserAllocation,
  initialEstimatedHours,
  workHours,
  totalResourceAllocatedHours
}) => {
  const initialProjectAllocationHours =
    getTotalHoursForDateRangeFromScheduleRules({
      start: startDate,
      end: endDate,
      scheduleRules: resourceAllocationScheduleRules || []
    }) || 0;

  const otherTaskAllocationHours =
    getTotalHoursForDateRangeFromScheduleRules({
      start: startDate,
      end: endDate,
      scheduleRules: otherTaskAllocationsSummaryScheduleRules || []
    }) || 0;

  const initialTaskAllocationHours =
    taskResourceUserAllocation?.totalHours || 0;

  return {
    startDate: startDate ? toRepliconDate(startDate) : null,
    endDate: endDate ? toRepliconDate(endDate) : null,
    otherTaskAllocationHours,

    initialTaskAllocationHours,
    initialProjectAllocationHours,

    newWorkHours: workHours,
    newTotalResourceAllocatedHours: totalResourceAllocatedHours,
    ...(!taskResourceUserAllocation && initialEstimatedHours
      ? {
          newTaskAllocationHours: initialEstimatedHours,
          newProjectAllocationHours: calculateProjectAllocationHours({
            initialTaskAllocationHours,
            initialProjectAllocationHours,
            initialTotalTaskAllocationHours:
              initialTaskAllocationHours + otherTaskAllocationHours,
            newTaskAllocationHours: initialEstimatedHours
          })
        }
      : {
          newTaskAllocationHours: initialTaskAllocationHours,
          newProjectAllocationHours: initialProjectAllocationHours
        })
  };
};

export const buildValidationSchema = ({ formatMessage }) =>
  object().shape({
    startDate: object()
      .nullable()
      .required(
        formatMessage({
          id: 'taskAllocationEditor.pleaseSelectStartDate'
        })
      ),
    endDate: object()
      .nullable()
      .required(
        formatMessage({
          id: 'taskAllocationEditor.pleaseSelectEndDate'
        })
      ),
    newTaskAllocationHours: number()
      .required()
      .moreThan(0)
  });

const useFormState = ({
  startDate,
  endDate,
  otherTaskAllocationsSummary,
  resourceAllocation,
  initialEstimatedHours,
  taskResourceUserAllocation,
  taskId,
  userId,
  roleId,
  resourceEstimateId,
  projectId,
  workHours,
  totalResourceAllocatedHours,
  onClose
}) => {
  const { formatMessage } = useIntl();
  const {
    tenantSlug,
    featureFlags: { isRmpTaskAllocationPhase2Enabled }
  } = useMeContext();

  const { scheduleRules: resourceAllocationScheduleRules } =
    resourceAllocation || {};

  const {
    scheduleRules: otherTaskAllocationsSummaryScheduleRules
  } = otherTaskAllocationsSummary;

  const {
    setFieldValue,
    values: { resourceEstimates }
  } = useDrawerFormikContext();

  const handleSubmit = useOnSubmit({
    setFieldValue,
    resourceEstimates,
    isRmpTaskAllocationPhase2Enabled
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const projectAvailableHours = getProjectAvailableHours({
      startDate,
      endDate,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules
    });

    await handleSubmit({
      setSubmitting,
      projectId,
      taskId,
      userId,
      resourceEstimateId,
      tenantSlug,
      roleId,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      values: {
        ...values,
        allocatedHours: values.newTaskAllocationHours,
        projectAvailableHours
      },
      taskResourceUserAllocation
    });

    onClose();
  };

  return {
    onSubmit,
    initialValues: getInitialValues({
      startDate,
      endDate,
      resourceAllocationScheduleRules,
      otherTaskAllocationsSummaryScheduleRules,
      initialEstimatedHours,
      taskResourceUserAllocation,
      totalResourceAllocatedHours,
      workHours
    }),
    validationSchema: buildValidationSchema({ formatMessage })
  };
};

export default useFormState;
