import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import eventBus from '~/modules/App/eventBus';
import { useTaskEstimateRowContext } from '~/modules/common/components/TaskDrawer/TaskResourceEstimates/TaskEstimateRowContext';
import { useTaskSummary } from './useTaskSummary';
import usePutTaskResourceEstimate from './usePutTaskResourceEstimate';
import useRemoveTaskResourceEstimate from './useRemoveTaskResourceEstimate';
import { useTaskResourceEstimate } from './useTaskResourceEstimate';

export const useOnRemoveTaskResourceEstimateSubmit = ({
  projectId,
  taskId
}) => {
  const {
    removeTaskResourceEstimate,
    loading
  } = useRemoveTaskResourceEstimate({ projectId, taskId });

  const { handleRefetchTaskSummary } = useTaskSummary({
    taskId
  });

  return {
    loading,
    onRemoveTaskResourceEstimateSubmit: useCallback(
      async (args, events) => {
        const {
          omitRemovedTaskEstimateFunc
        } = await removeTaskResourceEstimate(args);

        eventBus.publishAll(events);

        handleRefetchTaskSummary();

        return { omitRemovedTaskEstimateFunc };
      },
      [removeTaskResourceEstimate, handleRefetchTaskSummary]
    )
  };
};

export const useOnSubmit = ({
  taskId,
  loadTaskResourceUserAllocation,
  canViewCost
}) => {
  const { putTaskResourceEstimate, loading } = usePutTaskResourceEstimate();
  const { getTaskResourceEstimate } = useTaskResourceEstimate();
  const { handleRefetchTaskSummary } = useTaskSummary({
    taskId
  });

  return {
    onResourceEstimateSubmit: useCallback(
      async (
        {
          resourceUserId,
          projectRoleId,
          initialEstimatedHours,
          taskResourceEstimateAllocationInput,
          taskResourceEstimateId
        },
        events
      ) => {
        const { data } = await putTaskResourceEstimate({
          taskId,
          resourceUserId,
          projectRoleId,
          initialEstimatedHours,
          taskResourceEstimateAllocationInput,
          taskResourceEstimateId
        });

        const {
          taskResourceEstimate,
          loading: getEstimateLoading
        } = await getTaskResourceEstimate({
          resourceEstimateUri:
            data.putTaskResourceEstimate.taskResourceEstimateId,
          includeTaskResourceUserHoursProgressSummary: true,
          includeTaskResourceUserCostProgressSummary: canViewCost,
          includeTaskResourceUserAllocation: !!loadTaskResourceUserAllocation
        });

        eventBus.publishAll(events);

        handleRefetchTaskSummary();
        const estimateId = data.putTaskResourceEstimate.taskResourceEstimateId;

        return {
          taskResourceEstimate: taskResourceEstimate || {
            id: estimateId,
            taskId,
            taskResourceEstimateId: estimateId
          },
          loading: loading || getEstimateLoading
        };
      },
      [
        putTaskResourceEstimate,
        taskId,
        getTaskResourceEstimate,
        loadTaskResourceUserAllocation,
        handleRefetchTaskSummary,
        loading
      ]
    )
  };
};

export const useOnRemoveTaskAllocationSuccess = ({
  closeRemoveAllocationDialog
}) => {
  const {
    values: { resourceEstimates },
    setFieldValue
  } = useFormikContext();

  return {
    onRemoveTaskAllocationSuccess: useCallback(
      ({ deleteTaskResourceUserAllocationResult }) => {
        setFieldValue(
          'resourceEstimates',
          resourceEstimates.map(estimate =>
            estimate.taskResourceUserAllocation?.id ===
            deleteTaskResourceUserAllocationResult
              .deleteTaskResourceUserAllocation?.taskAllocationId
              ? {
                  ...estimate,
                  taskResourceUserAllocation: null
                }
              : estimate
          )
        );
        closeRemoveAllocationDialog();
      },
      [resourceEstimates, setFieldValue, closeRemoveAllocationDialog]
    )
  };
};

const mapToPutTaskResourceEstimateInput = ({
  taskResourceEstimateId,
  taskId,
  resourceUser,
  projectRole,
  initialEstimatedHours
}) => ({
  taskId,
  taskResourceEstimateId,
  resourceUserId: resourceUser?.id,
  projectRoleId: projectRole?.id,
  initialEstimatedHours
});

const useOnChangeHandlers = ({ resourceEstimate, rowIndex, setFieldValue }) => {
  const { putTaskResourceEstimate, loading } = usePutTaskResourceEstimate();
  const input = mapToPutTaskResourceEstimateInput(resourceEstimate);
  const { getTaskResourceEstimate } = useTaskResourceEstimate();
  const { handleRefetchTaskSummary } = useTaskSummary({
    taskId: resourceEstimate.taskId
  });
  const { canViewCost } = useTaskEstimateRowContext();

  return {
    loading,
    onRoleChange: useCallback(
      projectRoleId =>
        putTaskResourceEstimate({
          ...input,
          projectRoleId
        }),
      [putTaskResourceEstimate, input]
    ),

    onResourceChange: useCallback(
      async (resourceUserId, events) => {
        setFieldValue(`resourceEstimates[${rowIndex}].loading`, true);
        await putTaskResourceEstimate({
          ...input,
          resourceUserId
        });

        eventBus.publishAll(events);

        const {
          taskResourceEstimate,
          loading: isGetEstimateLoading
        } = await getTaskResourceEstimate({
          resourceEstimateUri: input.taskResourceEstimateId,
          includeTaskResourceUserHoursProgressSummary: true,
          includeTaskResourceUserCostProgressSummary: canViewCost,
          includeTaskResourceUserAllocation: false
        });

        setFieldValue(`resourceEstimates[${rowIndex}]`, {
          ...resourceEstimate,
          ...taskResourceEstimate,
          loading: isGetEstimateLoading
        });

        handleRefetchTaskSummary();
      },
      [
        setFieldValue,
        rowIndex,
        putTaskResourceEstimate,
        input,
        getTaskResourceEstimate,
        resourceEstimate,
        handleRefetchTaskSummary
      ]
    ),

    onEstimateChange: useCallback(
      async value => {
        setFieldValue(`resourceEstimates[${rowIndex}].loading`, true);
        const { data } = await putTaskResourceEstimate({
          ...input,
          initialEstimatedHours: Number(value)
        });

        const {
          taskResourceEstimate,
          loading: isGetEstimateLoading
        } = await getTaskResourceEstimate({
          resourceEstimateUri:
            data.putTaskResourceEstimate.taskResourceEstimateId,
          includeTaskResourceUserHoursProgressSummary: true,
          includeTaskResourceUserCostProgressSummary: canViewCost,
          includeTaskResourceUserAllocation: true
        });

        setFieldValue(`resourceEstimates[${rowIndex}]`, {
          ...resourceEstimate,
          ...taskResourceEstimate,
          loading: isGetEstimateLoading
        });

        handleRefetchTaskSummary();
      },
      [
        input,
        setFieldValue,
        rowIndex,
        putTaskResourceEstimate,
        getTaskResourceEstimate,
        resourceEstimate,
        handleRefetchTaskSummary
      ]
    )
  };
};

export default useOnChangeHandlers;
